import { useState } from "react";

export default function useTable() {
  const [page, setPage] = useState(1);

  const [rowsPerPage, setRowsPerpage] = useState(10);

  function onNextPage() {
    setPage(page + 1);
  }

  function onPrevPage() {
    if (page === 0) return;
    setPage(page - 1);
  }

  function onRowsPerPageChange(pageSize) {
    setRowsPerpage(pageSize);
  }

  return { page, rowsPerPage, onNextPage, onPrevPage, onRowsPerPageChange };
}
