import { useState } from "react";
import { ethers } from "ethers";
import axios from "axios";
import { toast } from "react-toastify";
import { useWallet } from "../../hooks/useWallet";
import { handleTransactionError } from "../../services/util";
import { useAuth } from "../../hooks/useAuth";

const TransferFundCard = (props) => {
  return (
    <svg
      className={props.className}
      width='404'
      height='515'
      viewBox='0 0 404 515'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_b_5295_18)'>
        <path
          d='M362 25.5H30.5V475C30.5 483.008 36.9919 489.5 45 489.5H376.5V40C376.5 31.9919 370.008 25.5 362 25.5Z'
          stroke='#777777'
        />
      </g>
      <path
        d='M286.5 11.5L273 0L404 1V116.5L392.5 106.5L391 27.5L371.5 11.5H286.5Z'
        fill='#FF3503'
      />
      <path
        d='M14.5 406.5L0 395L1.5 515H133L118.5 503H33L14.5 485.5V406.5Z'
        fill='#FF3503'
      />
      <path d='M30 380.5L30 25' stroke='#FF3503' strokeWidth='2' />
      <path d='M376 490L376 135' stroke='#FF3503' strokeWidth='2' />
      <defs>
        <filter
          id='filter0_b_5295_18'
          x='2'
          y='-3'
          width='403'
          height='521'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='14' />
          <feComposite
            in2='SourceAlpha'
            operator='in'
            result='effect1_backgroundBlur_5295_18'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_backgroundBlur_5295_18'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};

function Transfer() {
  const [username, setUsername] = useState("");
  const [amount, setAmount] = useState("");
  const [isSending, setIsSending] = useState(false);

  const wallet = useWallet();
  const auth = useAuth();

  const handleTransfer = async () => {
    if (username === auth.user.username) {
      toast.error(`Transfer failed: Cannot send to your own wallet.`);
      return;
    }
    setIsSending(true); // Set the isSending state to true
    try {
      const data = await axios.get(`/api/user/${username}/address`);

      const signer = await wallet.provider.getSigner();

      const tx = await signer.sendTransaction({
        to: data.loginAddress,
        value: ethers.utils.parseEther(amount),
      });

      await tx.wait();
      wallet.updatePoints({
        amount: +amount,
        chainId: wallet.chainId,
        walletAddress: wallet.address,
        moduleName: "transfer",
        txHash: tx.hash,
      });
      toast.success("Transfer successful!");
      setUsername(""); // Reset the username
      setAmount(""); // Reset the amount
      auth.updateTokens();
    } catch (error) {
      console.error("Transfer failed:", error);
      if (!error.response) {
        handleTransactionError(error);
      } else {
        toast.error(
          `Transfer failed: ${error.response?.data?.message || error.message}`
        );
      }
    } finally {
      setIsSending(false); // Reset the isSending state to false
    }
  };

  const options = ["ETH"];

  return (
    <div
      className='max-md:absolute lg:mt-[-90px]  2xl:-top-52 md:-top-24 2xl:left-0 lg:left-36 md:left-10 left-1/2 transform md:translate-x-0 translate-x-[-50%]
sm:h-[515px] sm:w-[404px] h-[440px] w-[300px]'
    >
      <TransferFundCard className='sm:h-[515px] sm:w-[404px] h-[440px] w-[300px]' />
      <div className='h-full w-full  absolute top-0 flex flex-col items-center justify-start py-12'>
        <div className='flex h-fit sm:gap-x-20 gap-x-8 items-center justify-between sm:mt-2 mt-4'>
          <h1 className='text-lg md:text-2xl'>Transfer ETH to Blazpay Users</h1>
          {/* <BackArrow className="cursor-pointer" /> */}
        </div>
        <p className='sm:text-base text-xs w-[70%] sm:mt-6 mt-3'>
          Transfer your funds
          <span className='text-[#ff3503]'> in just one simple steps</span>
        </p>

        {/* username field */}
        <div className='flex flex-col sm:mt-4 mt-2'>
          <span className='ml-3 sm:text-base text-xs  text-gray-300'>
            Username
          </span>
          <input
            type='text'
            required
            placeholder='user1'
            value={username}
            onChange={(e) =>
              setUsername(e.target.value.replace(/@blazpay$/, ""))
            }
            className='sm:py-[6px] py-[4px] sm:w-[260px] w-[200px] placeholder:text-gray-500 px-4 border border-[#ff5501] outline-none rounded-lg bg-transparent'
          />
        </div>
        {/* select token field */}
        <div className='flex flex-col mt-2'>
          <span className='ml-3 sm:text-base text-xs  text-gray-300'>
            Select Token
          </span>
          <select className='sm:py-[6px] py-[4px] sm:w-[260px] w-[200px] px-4 border border-[#ff5501] outline-none rounded-lg bg-transparent'>
            {options.map((item) => {
              return (
                <option key={item} className='text-black' value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </div>
        {/* enter amount field */}
        <div className='flex flex-col mt-2'>
          <span className='ml-3 sm:text-base text-xs  text-gray-300'>
            Enter Amount
          </span>
          <input
            type='text'
            required
            placeholder='1.00 ETH'
            value={amount}
            onChange={(e) => setAmount(e.target.value.replace(/ ETH$/, ""))}
            className='sm:py-[6px] py-[4px] sm:w-[260px] w-[200px]  placeholder:text-gray-500 px-4 border border-[#ff5501] outline-none rounded-lg bg-transparent'
          />
        </div>
        {/* transfer button */}

        <button
          onClick={handleTransfer}
          disabled={isSending}
          className='sm:py-2 py-1 sm:text-base text-[14px] sm:px-16 px-10 rounded-full bg-gradient-to-r from-[#FF3503] to-[#FF7C03] mt-4 text-base font-semibold'
        >
          {isSending ? "Sending..." : "Submit"}
        </button>
      </div>
    </div>
  );
}

export default Transfer;
