import { Link } from 'react-router-dom';
import Button from './Button';

export default function EntrypassModel() {
  return (
    <div className="relative flex flex-col items-center justify-center w-full max-w-md mx-auto h-auto bg-black border border-orange-700 rounded-t-lg border-t border-l border-r min-w-[30vw]">
      <div className="absolute top-0 flex items-center justify-center w-full p-2 bg-gradient-to-r from-[#FF3503] to-yellow-500 rounded-lg">
        <h1 className="text-lg font-bold">Get Entrypass</h1>
      </div>
      <div className="flex flex-col items-center justify-center w-full gap-4 mx-10 my-16 ">
        <div className="text-xl text-center">
          You need taiko entry pass first to access the dashboard
        </div>
        <Link
          to="https://blazpay.com/entry-pass/taiko"
          className="mt-5"
          target="_blank"
        >
          <Button name=" Claim Now" className="p-4 rounded-lg" />
        </Link>
      </div>
    </div>
  );
}
