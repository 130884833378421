import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import taikoStone from "../../assets/taikoStone.gif";
import { useWallet } from "../../hooks/useWallet";
import { useAuth } from "../../hooks/useAuth";
import { handleTransactionError, toastPromise } from "../../services/util";
import { getStakingInfo, stakeNow } from "../../api/misc";
import { parseEther } from "ethers/lib/utils";
import axios from "axios";
import { apiCall } from "../../services/ApiCall";
import configs from "../../config/configs.json";

const EntrypassCard = (props) => {
  return (
    <svg
      width='292'
      height='409'
      viewBox='0 0 292 409'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M6 37.5V3H41' stroke='#FF5500' strokeWidth='6' />
      <path d='M6 370V404.5H41' stroke='#FF5500' strokeWidth='6' />
      <path d='M289 37.5V3H254' stroke='#FF5500' strokeWidth='6' />
      <path d='M289 371V405.5H254' stroke='#FF5500' strokeWidth='6' />
      <rect
        x='1.5'
        y='1.5'
        width='286'
        height='405'
        stroke='white'
        strokeOpacity='0.1'
        strokeWidth='3'
      />
    </svg>
  );
};

const stakingAddress = "0x1670080000000000000000000000000000000003";

const NFTs = () => {
  const [totalRewards, setTotalRewards] = useState(0);
  const [stakedNFTsCount, setStakedNFTsCount] = useState(null);
  const [boughtNFTCount, setBoughtNFTCount] = useState(0);
  const [isMintLoading, setIsMintLoading] = useState(false);
  const [isStakeLoading, setIsStakeLoading] = useState(false);
  const [isClaimLoading, setIsClaimLoading] = useState(false);
  const [nftCountPerDay, setNftCountPerDay] = useState(0);

  const wallet = useWallet();
  const auth = useAuth();

  const data =
    wallet.chainId === 1890 || wallet.chainId === 1891
      ? [
          { title: "NFT Minted", value: boughtNFTCount, id: 1 },
          { title: "Rewards Earned", value: totalRewards, id: 3 },
        ]
      : [
          { title: "NFT Minted", value: boughtNFTCount, id: 1 },
          { title: "NFT Stake", value: stakedNFTsCount, id: 2 },
          { title: "Rewards Earned", value: totalRewards, id: 3 },
        ];

  const init = async () => {
    const config = wallet.getChainConfig();
    if (!config) return null;

    console.log(wallet.chainId);
    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      getActivityCount();
    }
    const [, balance] = await wallet.safeCallContract({
      name: "NFT",
      method: "balanceOf",
      args: [wallet.address],
    });
    let balance2;
    if (wallet.chainId === 997) {
      balance2 = 0;
    } else {
      [, balance2] = await wallet.safeCallContract({
        name: "STAKE_OLD",
        method: "balanceOf",
        args: [wallet.address],
      });
    }

    if (balance || balance2) {
      setBoughtNFTCount(balance.toNumber() + balance2.toNumber());
    }

    if (config.features.apiStaking) {
      const response = await getStakingInfo();
      setTotalRewards(response.totalRewards);
      setStakedNFTsCount(response.stakeCount);
    } else {
      const [, stakedNfts1] = await wallet.safeCallContract({
        name: "Stake",
        method: "getStakedNFTs",
        args: [wallet.address],
      });

      let stakedNfts2;
      if (wallet.chainId === 997) {
        stakedNfts2 = [];
      } else {
        const [, stakedNfts2] = await wallet.safeCallContract({
          name: "STAKE_OLD",
          method: "getStakedNFTs",
          args: [wallet.address],
        });
      }
      setStakedNFTsCount(
        (stakedNfts1?.length || 0) + (stakedNfts2?.length || 0)
      );

      const [, rewards] = await wallet.safeCallContract({
        name: "Stake",
        method: "calculateTotalRewards",
        args: [wallet.address],
      });

      let rewardsOlder;
      if (wallet.chainId === 997) {
        rewardsOlder = 0;
      } else {
        const [, rewardsOlder] = await wallet.safeCallContract({
          name: "STAKE_OLD",
          method: "calculateTotalRewards",
          args: [wallet.address],
        });
        if (rewards || rewardsOlder) {
          setTotalRewards((Number(rewards) + Number(rewardsOlder)).toString());
        }
      }
    }
  };
  const getActivityCount = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLink/getActivityCount`,
        {},
        {
          gameType: "MINTNFT",
          date: new Date(),
        },
        "GET"
      );
      if (isSuccess) {
        setNftCountPerDay(data.data?.processCount);
        setBoughtNFTCount(data.data?.processCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateActivityCount = async () => {
    const updatedNftCount = nftCountPerDay + 1;

    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLink/updateActivityCount`,
        {},
        {
          gameType: "MINTNFT",
          date: new Date(),
          count: updatedNftCount,
        },
        "POST"
      );
      if (isSuccess) {
        getActivityCount();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const mintNFT = async () => {
    const config = wallet.getChainConfig();
    if (!config) return;

    setIsMintLoading(true);

    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      try {
        const { isSuccess, data } = await apiCall(
          `${configs.POST_LOGIN_API_URL}mint/nft`,
          {},
          {
            userAddress: wallet.address,
          },
          "POST"
        );
        if (isSuccess) {
          setBoughtNFTCount((prev) => prev + 1);
          updateActivityCount();
        }
      } catch (error) {
        console.error(error);
        alert("Minting failed via API");
      }
    } else {
      const transactionOptions = {
        value: parseEther(String(config.features.mintValue)),
        ...(config.features.gasLimit && { gasLimit: "900000" }),
      };

      const [, tx] = await wallet.safeCallContract({
        name: "NFT",
        method: "safeMint",
        args: [wallet.address, transactionOptions],
      });

      if (tx) {
        await toastPromise(tx.wait(), {
          pending: "Minting...",
          success: () => {
            setBoughtNFTCount((prev) => prev + 1);
            return "NFT Minted successfully! It can take 15 seconds to show up in your wallet for staking.";
          },
          error: (err) => {
            console.error(err);
            return "Minting failed";
          },
        });
      }
    }

    setIsMintLoading(false);
  };

  const stakeNFT = async () => {
    const config = wallet.getChainConfig();
    if (!config) return;
    console.log(wallet.chainId);
    if (
      ["167008", "17000"].includes(wallet.chainId.toString()) &&
      +stakedNFTsCount >= 20
    ) {
      return toast.info(
        `You've staked the maximum number of NFTs allowable on this testnet`
      );
    }

    setIsStakeLoading(true);

    const [err1, ownedNFTs] = await wallet.safeCallContract({
      name: "NFT",
      method: "tokensOfOwner",
      args: [wallet.address],
    });
    console.log("ownedNFTs", ownedNFTs);
    if (err1) return setIsStakeLoading(false);

    if (ownedNFTs?.length === 0) {
      toast.info("No NFTs owned to stake.");
      setIsStakeLoading(false);
      return;
    }

    const tokenIdToStake = ownedNFTs[0];

    if (config.features.apiStaking) {
      const [err2, isApproved] = await wallet.safeCallContract({
        name: "NFT",
        method: "isApprovedForAll",
        args: [wallet.address, stakingAddress],
      });

      if (err2) return setIsStakeLoading(false);

      if (isApproved === false) {
        const [err3, approveTx] = await wallet.safeCallContract({
          name: "NFT",
          method: "setApprovalForAll",
          args: [stakingAddress, true],
        });

        if (err3) return setIsStakeLoading(false);

        await approveTx.wait();
      }

      const [, tx] = await wallet.safeCallContract({
        name: "NFT",
        method: "sendToken",
        args: [
          tokenIdToStake,
          config.contracts.Stake.address,
          {
            value: parseEther(String(config.features.stakeValue)),
          },
        ],
      });

      if (tx) {
        try {
          await tx.wait();

          toast.success(
            `Transaction successful. The NFT will be staked in a few minutes in Holesky. Please don't attempt staking again for 5 minutes.`,
            {
              autoClose: 10000,
            }
          );

          stakeNow(tokenIdToStake.toNumber())
            .then(() => {
              setStakedNFTsCount((prev) => prev + 1);
              setBoughtNFTCount((prev) => prev - 1);
              toast.success(
                `NFT (ID: ${tokenIdToStake.toNumber()}) Staked Successfully`
              );
            })
            .catch((err) => {
              console.error(err);
              toast.error("API failed, please contact support");
            });
        } catch (err) {
          handleTransactionError(err);
        }
      }
    } else {
      console.log("HERE IS THE LOG");
      const [err2, isApproved] = await wallet.safeCallContract({
        name: "NFT",
        method: "getApproved",
        args: [tokenIdToStake],
      });
      console.log("🚀 ~ stakeNFT ~ err2:", err2, isApproved);

      if (err2) return setIsStakeLoading(false);

      if (isApproved === false) {
        const [err3, approveTx] = await wallet.safeCallContract({
          name: "NFT",
          method: "approve",
          args: [config.contracts.NFT.address, tokenIdToStake],
        });

        if (err3) return setIsStakeLoading(false);

        await approveTx.wait();
      }
      const transactionOptions = {
        value: parseEther(String(config.features.stakeValue)),
        ...(config.features.gasLimit && { gasLimit: "600000" }),
      };
      const [, tx] = await wallet.safeCallContract({
        name: "NFT",
        method: "stakeNFT",
        args: [tokenIdToStake, transactionOptions],
      });

      if (tx) {
        await toastPromise(tx.wait(), {
          pending: "Staking...",
          success: () => {
            setStakedNFTsCount((prev) => prev + 1);
            setBoughtNFTCount((prev) => prev - 1);
            return "Staked Successfully";
          },
          error: (err) => {
            console.error(err);
            return "Stake Failed";
          },
        });
      }
    }

    setIsStakeLoading(false);
  };

  const claimKatla = async () => {
    setIsClaimLoading(true);
    // if (wallet.chainId && wallet.chainId == "167009") {
    console.log("hekla");
    try {
      const address = auth.user.loginAddress;
      console.log(address);
      const res = await axios.post("/api/nft/claim-katla", { address });
      setIsClaimLoading(false);
      if (res.status === 200) {
        await auth.updateTokens();
        return toast.success("Claim Successful");
      } else {
        return toast.error("Something went wrongg");
      }
    } catch (error) {
      console.log(error);
      setIsClaimLoading(false);
      toast.error(error.response.data.message);
    }
    // } else {
    //   try {
    //     const [, stakedNfts] = await wallet.safeCallContract({
    //       name: "STAKE_OLD",
    //       method: "getStakedNFTs",
    //       args: [wallet.address],
    //     });
    //     if (stakedNfts && stakedNfts.length != 0) {
    //       let nftToBeUnstaked = stakedNfts[0];
    //       const [, tx] = await wallet.safeCallContract({
    //         name: "STAKE_OLD",
    //         method: "unstake",
    //         args: [nftToBeUnstaked],
    //       });
    //       if (tx) {
    //         await toastPromise(tx.wait(), {
    //           pending: "Unstaking...",
    //           success: () => {
    //             setStakedNFTsCount((prev) => prev - 1);
    //             setBoughtNFTCount((prev) => prev + 1);
    //             return "Unstaked Successfully";
    //           },
    //           error: (err) => {
    //             console.error(err);
    //             return "unstake Failed";
    //           },
    //         });
    //         setIsClaimLoading(false);
    //       }
    //     } else {
    //       setIsClaimLoading(false);
    //       toast.error("You don't have any staked NFT");
    //     }
    //   } catch (err) {
    //     console.log(err);
    //     toast.error(err);
    //     setIsClaimLoading(false);
    //   }
    // }
  };

  useEffect(() => {
    if (!wallet.address) return;
    init();
  }, [wallet]);

  useEffect(() => {
    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      getActivityCount();
    }
  }, []);

  return (
    <div className='w-full flex md:flex-row flex-col md:justify-between justify-start md:items-start items-center relative md:h-fit sm:h-[200px] h-[240px] md:mt-0 mt-10'>
      <div className='flex flex-col 2xl:pt-0 lg:pt-16 md:pt-8 2xl:pl-0 lg:pl-16 md:pl-8'>
        <h1
          className=' uppercase leading-[1.15] 
           xl:text-6xl  lg:text-5xl md:text-4xl sm:text-3xl text-2xl sm:px-0 px-8  font-semibold xl:w-[600px] lg:w-[500px] sm:w-[360px]  md:text-left text-center'
        >
          mint and stake to earn rewards
        </h1>
        {/* Mint and Stake Buttons */}
        <div className='flex sm:flex-row flex-col  items-center md:justify-start justify-center h-fit xl:gap-x-10 sm:gap-x-5 sm:gap-y-0 gap-y-4 mt-5 md:w-fit w-full'>
          <button
            onClick={mintNFT}
            disabled={
              isMintLoading ||
              ((wallet.chainId === 1890 || wallet.chainId === 1891) &&
                nftCountPerDay >= 5) ||
              (wallet.chainId === 30732 &&
                boughtNFTCount + stakedNFTsCount >= 5)
            }
            className={`bg-gradient-to-r from-[#ff3503] to-[#fba500] rounded-full lg:h-12 h-9 lg:px-16 px-12 ${
              isMintLoading ||
              ((wallet.chainId === 1890 || wallet.chainId === 1891) &&
                nftCountPerDay >= 5) ||
              (wallet.chainId === 30732 &&
                boughtNFTCount + stakedNFTsCount >= 5)
                ? "cursor-not-allowed opacity-50"
                : "cursor-pointer"
            }`}
          >
            {isMintLoading
              ? "Processing..."
              : (wallet.chainId === 30732 &&
                  boughtNFTCount + stakedNFTsCount >= 5) ||
                ((wallet.chainId === 1890 || wallet.chainId === 1891) &&
                  nftCountPerDay >= 5)
              ? "5 max NFTs"
              : "Mint Now"}
          </button>
          {wallet.chainId === 1890 || wallet.chainId === 1891 ? (
            <></>
          ) : (
            <button
              onClick={stakeNFT}
              disabled={stakedNFTsCount && isStakeLoading}
              className='rounded-full  lg:h-12 h-9 lg:px-16 px-12 border border-[#ff3503]'
            >
              {isStakeLoading ? "Processing..." : "Stake Now"}
            </button>
          )}
          {wallet.chainId === 1890 ||
          wallet.chainId === 1891 ||
          wallet.chainId === 30732
            ? null
            : !auth.user.katlaClaimed && (
                <button
                  className='rounded-full lg:h-12 h-9 lg:px-16 px-12 border border-[#ff3503]'
                  onClick={claimKatla}
                >
                  {isClaimLoading ? "Processing..." : "Claim Katla"}
                </button>
              )}
        </div>
      </div>

      {/* Buttons */}
      <div className='flex 2xl:flex-row  md:justify-end justify-evenly md:flex-col flex-row w-fit md:h-full h-10 2xl:gap-x-5 md:gap-x-0 sm:gap-x-5 gap-x-3 2xl:gap-y-0 gap-y-3 2xl:-left-20  2xl:top-64  lg:right-10 md:right-0 bottom-0 md:top-72 2xl:relative absolute'>
        {data.map((item) => {
          return (
            <button
              key={item.id}
              className={`sm:py-2 cursor-default py-0  md:text-base sm:text-xs text-[9px] active:border-[#ff3503] ${
                item.id == 3 ? "border-[#ff3503]" : "border-[#393637]"
              } md:w-48 w-[24] sm:px-3 px-2 sm:border-[3px] border-[2px]`}
            >
              {item.title} {item.value}
            </button>
          );
        })}
      </div>

      {/* Entrypass Card */}
      <div
        className='lg:h-[408px] lg:w-[289px] h-[260px] w-[200px] md:block hidden relative 2xl:right-12'
        style={{ perspective: "1000px" }}
      >
        <EntrypassCard className='lg:h-[408px] lg:w-[289px] h-[260px] w-[200px]' />
        <img
          style={{ transform: "rotateY(12deg) translate(-50%,-50%)" }}
          src={taikoStone}
          alt=''
          className='absolute top-1/2 left-1/2 transform
        translate-x-[-50%] translate-y-[-50%] lg:h-[350px] lg:w-[450px] h-[220px] w-[150px]'
        />
      </div>
    </div>
  );
};

export default NFTs;
