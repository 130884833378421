import React, { useState, useEffect } from 'react';
import logo from '../assets/logo_blazpay.svg';
import { toast } from 'react-toastify';
import { useAuth } from '../hooks/useAuth';
import Button from './Button';
import { checkUsername, updateProfile } from '../api/user';

const CreateProfile = () => {
  const [username, setUsername] = useState('');
  const [referralCode, setReferral] = useState('');
  const [loadingUsernameCheck, setLoadingUsernameCheck] = useState(false);
  const [usernameAvailable, setUsernameAvailable] = useState(true);
  const [isRegistering, setIsRegistering] = useState(false);

  const auth = useAuth();

  const handleUserNameChange = (e) => setUsername(e.target.value);
  const handleReferralChange = (e) => setReferral(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (username) {
      try {
        setIsRegistering(true);
        const response = await updateProfile({
          username,
          referralCode,
        });

        auth.updateProfile({
          username: response.username,
          isProfileCreated: true,
        });

        toast.success('Profile created successfully');
      } catch (error) {
        console.error(error);
        toast.error(
          error.response?.data?.message || 'Error during creating profile'
        );
      } finally {
        setIsRegistering(false);
      }
    } else {
      toast.info('Please enter a username.');
    }
  };
  useEffect(() => {
    const timer = setTimeout(async () => {
      if (username) {
        try {
          setLoadingUsernameCheck(true);
          const response = await checkUsername(username);
          setUsernameAvailable(response.available);
          setLoadingUsernameCheck(false);
        } catch (error) {
          console.error('Error checking username availability:', error);
          setLoadingUsernameCheck(false);
        }
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [username]);
  return (
    <div className="flex items-center justify-center  h-[70vh] ">
      <form
        onSubmit={handleSubmit}
        style={{ border: '1px solid #FF5500', backgroundColor: '#D9D9D933' }}
        className="md:w-1/3 w-5/6 min-w-[350px] p-4 md:p-5 rounded-lg shadow-md "
      >
        <div className="rounded-lg" style={{ backgroundColor: '#171717' }}>
          <div className="flex justify-center p-4 bg-black rounded-tl-lg rounded-tr-lg ">
            <img src={logo} className="w-40 h-10 rounded-lg" alt="Logo" />
          </div>
          <div className="p-6 ">
            <div className="flex flex-col items-center justify-center mb-4 text-2xl">
              <span className="text-[#FF3503]">Create Profile</span>
            </div>

            <div className="flex flex-col items-center justify-center w-full h-10 p-2 mb-4 bg-white rounded-lg">
              <input
                type="text"
                id="ethereumAddress"
                className="w-full text-sm text-left text-black placeholder-gray-700 border border-none rounded-lg focus:outline-none"
                placeholder="WalletAddress"
                value={auth.user.loginAddress}
                disabled
              />
            </div>

            <div className="flex flex-row items-center justify-center w-full h-10 gap-2 p-2 mb-4 bg-white rounded-lg ">
              <input
                type="text"
                id="text"
                className="w-2/3 md:w-[70%] focus:outline-none  text-sm text-left text-black border-r-2 border-black placeholder-gray-700"
                placeholder="Enter Username"
                value={username}
                required
                onChange={(e) => {
                  setUsername(e.target.value);
                  setUsernameAvailable(true); // Reset to true when user starts typing
                }}
              />
              {username.length >= 3 &&
                usernameAvailable &&
                !loadingUsernameCheck && (
                  <span className="text-green-500">✔</span> // Green checkmark
                )}
              <h1 className="w-1/3 font-extrabold text-black">@Blazpay</h1>
            </div>
            {!usernameAvailable && (
              <p className="text-red-500 pb-2">Username not available</p>
            )}
            {loadingUsernameCheck && <p>Checking username availability...</p>}
            <div className="flex flex-col items-center justify-center w-full h-10 p-2 mb-4 bg-white rounded-lg">
              <input
                type="text"
                id="referralCode"
                className="w-full text-sm text-left text-black placeholder-gray-700 border border-none rounded-lg focus:outline-none"
                placeholder="Referral Code(Optional)"
                value={referralCode}
                onChange={handleReferralChange}
              />
            </div>

            <div className="flex flex-col items-center ">
              <Button
                name="Submit"
                isLoading={isRegistering}
                disabled={isRegistering}
                type="submit"
                className="text-md font-bold  rounded-3xl min-w-[100%] py-1 px-3 hover:bg-gradient uppercase "
                style={{
                  background:
                    'linear-gradient(90deg, #FF3503 0%, #FF6E01 53.15%, #FFA100 100%)',
                  color: 'white',
                }}
              ></Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateProfile;
