import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getLeaderboard } from "../../api/misc";
import Loader from "../Loader/Loader";

const Leaderboard = () => {
  const leaderboard = useQuery({
    queryKey: ["leaderboard", 1],
    queryFn: () => getLeaderboard({ limit: 10 }),
  });

  return (
    <div
      //onClick={fullScreen}
      className="xl:h-[310px] h-[260px] cursor-pointer  xl:w-[330px] w-[280px] border border-[#ff3503] rounded-2xl overflow-hidden"
      style={{
        background: "rgba(0,0,0,0.61)",
        backdropFilter: "blur(28px)",
      }}
    >
      <Link to="/leaderboard">
        <h1 className="text-xl px-6 xl:pt-6 pt-4 pb-4">Leader Board</h1>
        {leaderboard.isLoading ? (
          <div className="flex flex-row w-full h-[70%] align-middle justify-center items-center">
            <Loader />
          </div>
        ) : (
          <table className="text-white text-left font-light lg:text-base md:text-[13px] text-base  w-full ">
            <thead>
              <tr className="border-b  border-[#ff3503]">
                <th className="pl-6">#</th>
                <th className="pl-1">Users</th>
                <th className="pl-6">Points</th>
              </tr>
            </thead>

            <tbody>
            {leaderboard.data?.userRank && leaderboard.data.leaderboard.findIndex(u => u.userId === "user's userId") === -1 && (
                <tr key={0} className="text-orange-500">
                  <td className="pl-6">{leaderboard.data.userRank}</td>
                  <td className="pl-1">You</td>
                  <td className="pl-6">{leaderboard.data.userPoints}</td>
                </tr>
              )}
              {leaderboard.data?.leaderboard.slice(0, 4).map((data, index) => {
                return (
                  <tr key={index} className="2xl:leading-[2.4]  leading-[2]">
                    <td className="pl-6">{index + 1}</td>
                    <td className="pl-1"> {data.username}</td>
                    <td className="pl-6">{data.totalPoints || 0}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </Link>
    </div>
  );
};

export default Leaderboard;
