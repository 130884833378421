import { useState } from "react";
import Modal from "../../components/Modal";
import { GiConfirmed } from "react-icons/gi";
import { HiOutlinePencil } from "react-icons/hi";
import clsx from "clsx";
import { IoCloseCircleOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import axios from "axios";

export default function Address({ allAddress = [], chains = [] }) {
  const [selectedChain, setSelectedChain] = useState(null);

  const [selectedToken, setSelectedToken] = useState(null);

  const [walletAddress, setNewWalletAddress] = useState("");

  const [open, setOpen] = useState(false);

  const [edit, setEdit] = useState(false);

  const [selectedWallet, setSelectedWallet] = useState(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
    setSelectedChain(null);
    setSelectedToken(null);
    setNewWalletAddress("");
    setSelectedChain(null);
  };

  const handleClickChain = (chain) => {
    setSelectedChain(chain);
  };

  const handleEdit = (wallet) => {
    const findChains = chains.find((item) => item.chainId == wallet.chainId);

    setSelectedWallet(wallet);

    if (findChains) {
      setSelectedChain(findChains ?? []);

      const findToken = findChains.tokens.find(
        (token) => token.tokenName == wallet.tokenName
      );

      setSelectedToken(findToken);

      setNewWalletAddress(wallet?.walletAddress);

      setEdit(true);
      setOpen(true);
    }
  };

  const handleAddressUpdate = async () => {
    console.log("log:", walletAddress);
    if (!walletAddress) return toast.error("enter wallet address");

    const data = {
      chainId: selectedChain?.chainId,
      chainName: selectedChain?.chainName,
      tokenAddress: selectedToken?.address,
      tokenName: selectedToken?.tokenName,
      walletAddress,
    };

    if (edit && selectedWallet) {
      data._id = selectedWallet._id;
    }

    try {
      const res = await axios.patch("/api/user/profile", { wallets: data });
      console.log("log: data", res);
      handleClose();
    } catch (error) {
      console.error("Error updating :", err);
      toast.error("Error updating .");
    }
  };

  function getChainImage(chainId) {
    const chain = chains.find((item) => item.chainId == chainId);

    if (!chain) {
      return "";
    }

    return chain.img;
  }

  function getWalletImage(chainId, tokenName) {
    const chain = chains.find((item) => item.chainId == chainId);

    if (chain) {
      const token = chain.tokens.find((item) => item.tokenName === tokenName);

      if (!token) return "";

      return token.img;
    }
  }

  return (
    <div>
      {allAddress.map((wallet) => (
        <div
          key={wallet?.tokenAddress}
          className="flex items-center gap-4 w-full border rounded-xl py-2 px-3"
        >
          <div className="flex relative">
            <img
              src={getChainImage(wallet?.chainId)}
              alt="pol"
              className="h-7 w-7 rounded-full"
            />
            <img
              src={getWalletImage(wallet?.chainId, wallet?.tokenName)}
              alt="taiko"
              className="h-7 w-7 rounded-full absolute left-4"
            />
          </div>

          <input
            className="bg-transparent outline-none"
            placeholder="Wallet Address"
            value={wallet?.walletAddress}
          />

          <div>
            <HiOutlinePencil
              className="h-4 w-4 cursor-pointer transform hover:scale-110"
              onClick={() => handleEdit(wallet)}
            />
          </div>
        </div>
      ))}

      {allAddress.length < 3 && (
        <div>
          <div>
            <button
              onClick={() => handleOpen()}
              className="px-4 py-2 bg-[#ff5500] rounded-full mt-2 text-sm"
            >
              Add Address
            </button>
          </div>
        </div>
      )}

      <Modal open={open} onClose={handleClose}>
        <div>
          <div>
            <div className="relative text-white z-20">
              <IoCloseCircleOutline
                className="h-4 w-4 cursor-pointer absolute right-5"
                onClick={handleClose}
              />
              <div className="mb-8">
                <h4 className="text-center font-semibold text-xl">
                  Token List
                </h4>
              </div>
              <div className="flex gap-3 max-md:flex-col">
                <div className="grid grid-cols-3 chain-list w-[240px]  gap-0 flex-wrap max-h-96 overflow-auto">
                  {chains?.map((chain, index) => (
                    <div
                      key={`${chain.chainId}+${index}`}
                      onClick={() => {
                        handleClickChain(chain);
                      }}
                      className={clsx(
                        "flex flex-col justify-center items-center p-2 rounded-xl cursor-pointer h-16",
                        selectedChain?.chainId == chain.chainId && "bg-gray-500"
                      )}
                    >
                      <img
                        src={chain.img}
                        className="h-8 w-8 rounded-full"
                        alt="bnb"
                      />
                      <p className="text-xs">{chain.chainName}</p>
                    </div>
                  ))}
                </div>
                <div className="token-list min-w-[342px] flex-1 max-h-96 overflow-auto">
                  {selectedChain?.tokens.map((token) => (
                    <div
                      key={token.address}
                      className={clsx(
                        "border-b border-b-primary px-3 py-2  min-w-[340px] flex justify-between cursor-pointer hover:bg-gray-500",
                        selectedToken?.tokenName == token?.tokenName &&
                          "bg-gray-400"
                      )}
                      onClick={() => setSelectedToken(token)}
                    >
                      <div className=" flex gap-3 items-center ">
                        <img
                          src={token.img}
                          className="w-8 h-8 rounded-full"
                          alt="nb"
                        />
                        <div>
                          <p className="text-sm">{token.symbol}</p>
                          <p className="text-xs max-w-[100px]">
                            {token.tokenName}
                          </p>
                        </div>
                      </div>
                      <div>...</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="mt-3 flex gap-2 items-center">
              {" "}
              <input
                value={walletAddress}
                onChange={(e) => setNewWalletAddress(e.target.value)}
                placeholder="Wallet Address"
                type="text"
                className="bg-transparent outline-none border rounded-xl xl:py-3 py-2 pl-6 xl:pr-6 w-full"
              />{" "}
              <div>
                <button
                  onClick={handleAddressUpdate}
                  className="px-4 py-2 bg-[#ff5500] rounded-full mt-2 text-sm"
                >
                  {edit ? "Update" : "Save"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
