import { errors } from "ethers";
import { toast } from "react-toastify";
import axios from "axios";

export const chainIdToHex = (chainId) => {
  const hexString = chainId.toString(16);
  return "0x" + hexString;
};

export const toastPromise = (promise, { pending, success, error }, options) => {
  return new Promise((resolve) => {
    const isStr = (value) => typeof value === "string";

    toast.promise(
      promise,
      {
        pending: {
          render: () => {
            return isStr(pending) ? pending : pending();
          },
        },
        success: {
          render: ({ data }) => {
            return isStr(success) ? success : success(data);
          },
        },
        error: {
          render: ({ data }) => {
            return isStr(error) ? error : error(data);
          },
        },
      },
      options
    );

    promise.finally(resolve);
  });
};

export const handleTransactionError = (error) => {
  console.error(error);
  switch (error.code) {
    case errors.ACTION_REJECTED:
      toast.error("You have rejected the transaction");
      break;
    case errors.INSUFFICIENT_FUNDS:
    case -32603:
      toast.error("Insufficient funds");
      break;
    case errors.NETWORK_ERROR:
      toast.error("Network error, please try again");
      break;
    case errors.UNPREDICTABLE_GAS_LIMIT:
      toast.error("Unpredictable gas price, please try again");
      break;
    default:
      toast.error("Error occurred while processing the transaction");
      break;
  }
};

export const imagetoBase64 = async (file) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);

  const data = new Promise((resolve, reject) => {
    reader.onload = () => resolve(reader.result);
    reader.onerror = (err) => reject(err);
  });

  return data;
};

export const getTokens = async (chainId) => {
  const chain = Number(chainId);

  const dd = await axios.get("/api/misc/chains");

  console.log("log: runn");

  const res = await axios({
    url: "https://li.quest/v1/tokens",
    params: { chains: chain },
  });

  console.log("log: res", res);

  return res.tokens[chain] || [];
};

export const getChains = async () => {
  const chains = await axios.get("/api/misc/chains");

  return chains;
};
