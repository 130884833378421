import React from "react";
import Storefront from "../components/dashboard/Storefront";
import Games from "../components/dashboard/Games";
import Leaderboard from "../components/dashboard/Leaderboard";
import NFTs from "../components/dashboard/NFTs";
import Transfer from "../components/dashboard/Transfer";

const Dashboard = () => {
  return (
    <div className="container mx-auto">
      <section className="reward-container min-h-screen w-full  text-white ">
        <div className="2xl:container mx-auto w-full 2xl:px-0 xl:px-16 md:px-12 min-h-screen">
          {/* Top section */}
          <NFTs />

          {/* Bottom section */}
          <div className="w-full 2xl:h-fit md:h-[860px] sm:h-[1500px] h-[1420px] flex flex-row justify-between relative mt-8">
            {/* Transfer fund */}
            <div>
              <Transfer />
            </div>

            <div
              className="flex md:flex-row flex-col items-center 2xl:justify-end  md:justify-center justify-evenly lg:gap-x-10 md:gap-x-5 md:gap-y-0 gap-y-5 2xl:pb-10 md:pb-20 2xl:w-3/4 w-full
           2xl:relative absolute 2xl:top-0 md:top-[460px] sm:top-[600px] top-[480px] "
            >
              {/* Leaderboard */}
              <Leaderboard />

              {/* Manage Funds,Play Game, Refer and Earn */}
              <Games />

              {/* Store Font */}
              <Storefront />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
