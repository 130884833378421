import { toast } from "react-toastify";

export async function apiCall(url, header = {}, data = {}, method = "GET") {
  let config;
  
  if (localStorage.getItem("token")) {
    config = {
      method: method.toUpperCase(),
      headers: {
        ...header,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
        "Content-Type": "application/json", 
      },
    };
  } else {
    config = {
      method: method.toUpperCase(),
      headers: {
        ...header,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
        "Content-Type": "application/json",  
      },
    };
  }

  try {
    let response;
    
    if (method.toUpperCase() === "GET") {
      const queryParams = Object.entries(data)
        .map(([key, value]) => {
          if (Array.isArray(value)) {
            return `${key}=[${value.join(",")}]`;
          }
          return `${key}=${encodeURIComponent(value)}`;
        })
        .join("&");

      response = await fetch(`${url}?${queryParams}`, config);
    } else {
      config.body = JSON.stringify(data);
      response = await fetch(url, config);
    }

    const responseData = await response.json();

    if (response.status === 200) {
      if (method.toUpperCase() !== "GET" && responseData?.notShowPopUp) {
        toast.success(responseData?.message || "Request successful");
      }
      return { isSuccess: true, data: responseData };
    } else {
      throw new Error(
        `Request failed with status ${response.status}: ${responseData.message}`
      );
    }
  } catch (error) {
    if (error.message.includes("SESSION_EXPIRED")) {
      toast.error("Your Session has expired. Please log in again.");
      localStorage.clear();
      window.location.href = "/";
    } else {
      const errorMessage = error.message || "An error occurred";
      toast.error(errorMessage);
      return { isSuccess: false, data: { error: errorMessage } };
    }
  }
}
