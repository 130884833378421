import { useState, useEffect } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { GiSettingsKnobs } from "react-icons/gi";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { LeaderboardArrow, LeaderboardStars } from "../components/SvgContainer";
import Loader from "../components/Loader/Loader";
import BNBImg from "../assets/bnb.png";
import dragonWarrior from "../assets/dragonwarrior.png";
import blzImg from "../assets/blzicon.png";
import { useQuery } from "@tanstack/react-query";
import { getLeaderboard } from "../api/misc";
import {
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
} from "date-fns";

const Leaderboard = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [timeframe, setTimeframe] = useState("");
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  const handleTimeframe = (selectedTimeframe) => {
    if (timeframe === selectedTimeframe) {
      // Reset to initial state if clicking the same timeframe
      setTimeframe("");
      setFrom(null);
      setTo(null);
    } else {
      setTimeframe(selectedTimeframe);
      const today = new Date();
      let newFrom, newTo;

      if (selectedTimeframe === "daily") {
        newFrom = startOfDay(today).toISOString();
        newTo = endOfDay(today).toISOString();
      } else if (selectedTimeframe === "weekly") {
        newFrom = startOfWeek(today).toISOString();
        newTo = endOfWeek(today).toISOString();
      } else if (selectedTimeframe === "monthly") {
        newFrom = startOfMonth(today).toISOString();
        newTo = endOfMonth(today).toISOString();
      }

      setFrom(newFrom);
      setTo(newTo);
    }
    // Reset to page 1 when changing timeframe
    setCurrentPage(1);
  };

  const chains = [
    {
      id: 30732,
      name: "Movement EVM Testnet",
      tokenImg: "https://i.ibb.co/ZXGvbMx/movement-Logo.jpg",
      mask: "Testnet",
    },
    {
      id: 167008,
      name: "Taiko Katla L2",
      tokenImg: "https://i.ibb.co/y5D8dJv/taiko.png",
      mask: "Testnet",
    },
    {
      id: 169,
      name: "Manta Pacific",
      tokenImg: "https://i.ibb.co/CPzhyTf/manta.png",
      mask: "Mainnet",
    },
    {
      id: 56,
      name: "BNB Smart Chain Mainnet",

      tokenImg: "https://i.ibb.co/VMVS2Hc/binance.png",
      mask: "Mainnet",
    },
    {
      id: 137,
      name: "Polygon Mainnet",

      tokenImg: "https://i.ibb.co/MDKTCF6/polygon.png",
      mask: "Mainnet",
    },
    {
      id: 1891,
      name: "Pegasus Testnet",
      tokenImg:
        "	https://airdrops.io/wp-content/uploads/2023/07/Lightlink-logo.jpg",
      mask: "Testnet",
    },
    {
      id: 17000,
      name: "Holesky",

      tokenImg: "https://i.ibb.co/tHbx9Jw/arbitrum.png",
      mask: "Mainnet",
    },
  ];

  const allChainData = {
    name: "All",
    tokenImg: [
      "https://i.ibb.co/ZXGvbMx/movement-Logo.jpg",
      "https://i.ibb.co/CPzhyTf/manta.png",
      "https://airdrops.io/wp-content/uploads/2023/07/Lightlink-logo.jpg",
      "https://i.ibb.co/y5D8dJv/taiko.png",
    ],
    mask: "",
  };
  const chainData = [allChainData, ...chains];

  const [dropdownData, setDropdownData] = useState({
    imgPath: "",
    chainName: "All",
    chainId: "",
  });

  const leaderboardQuery = useQuery({
    queryKey: ["leaderboard", currentPage, dropdownData, from, to],
    queryFn: () =>
      getLeaderboard({
        page: currentPage,
        limit: 10,
        chainId: dropdownData.chainId,
        from,
        to,
      }),
    keepPreviousData: true,
  });

  const [searchIcon, setSearchIcon] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [searchResult, setSearchResult] = useState(null);

  console.log(from + " " + to + " " + dropdownData.chainId + " " + currentPage);
  console.log(leaderboardQuery);

  const handleSearch = async () => {
    {
      console.log("Searching...");
      await leaderboardQuery.refetch();
      if (searchInput === "") {
        setSearchResult(null);
      } else {
        const foundUser = leaderboardQuery.data?.leaderboard.filter((data) =>
          data.username.toLowerCase().startsWith(searchInput.toLowerCase())
        );

        if (foundUser.length > 0) {
          console.log("User(s) found");
          setSearchResult(foundUser);
        } else {
          console.log("User not found");
          setSearchResult(null);
        }
      }
    }
  };

  const handleNextPage = () => {
    if (currentPage < leaderboardQuery.data?.totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const [initialChains, setInitialChains] = useState(0);
  const [expandItems, setExpandItems] = useState(5);

  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const handlePopupDropdown = () => setIsShowDropdown(!isShowDropdown);

  const handleChainData = (index) => {
    setIsShowDropdown(false);
    const selectedChain = chainData[index];
    if (selectedChain.name === "All") {
      setDropdownData({
        imgPath: selectedChain.tokenImg,
        chainName: selectedChain.name,
        chainId: "",
      });
    } else {
      setDropdownData({
        imgPath: selectedChain.tokenImg,
        chainName: selectedChain.name,
        chainId: selectedChain.id,
      });
    }
  };
  const renderDropdownImage = (imgPath) => {
    if (Array.isArray(imgPath)) {
      return imgPath.map((path, i) => (
        <img
          key={i}
          src={path}
          alt={`Chain ${i}`}
          className='inline h-6 mr-2'
        />
      ));
    } else {
      return <img src={imgPath} alt='Chain' className='inline h-6 mr-2' />;
    }
  };
  const startingSN = (currentPage - 1) * 10;

  return (
    <section className='min-h-screen w-full  text-white'>
      <div
        className='relative xl:container flex flex-col lg:items-center sm:items-start items-center
      mx-auto xl:px-0
       sm:px-8 px-4 2xl:pb-0'
      >
        <h1 className='md:text-6xl text-4xl font-light'>Leaderboard</h1>
        <p
          className='lg:text-2xl sm:text-xl text-base lg:w-[55%] sm:w-[80%] w-full sm:px-0 px-10 lg:text-center 
        sm:text-left text-center sm:mt-5 mt-32'
        >
          Show off your stats and collectibles with your unique profile. Team
          features will be revealed soon!
        </p>
        {leaderboardQuery.data?.userRank && (
          <p className='text-lg mt-4'>
            Your Rank: {leaderboardQuery.data.userRank}
          </p>
        )}
        <div
          className='relative xl:w-[1096px] w-full border border-[#D24804] mt-4 rounded-3xl xl:px-10
        sm:px-4 px-3 sm:py-3 py-6'
        >
          {/* Pop Dropdown */}
          <div
            style={{
              backdropFilter: "blur(28px)",
              boxShadow: "0 4px 92px 24px #000",
            }}
            className={`${
              isShowDropdown === true ? "flex" : "hidden"
            } flex-col absolute gap-y-1 sm:-right-5 right-1/2 sm:translate-x-0 translate-x-[50%] sm:top-20 top-10 
  overflow-y-auto px-6 py-4 border border-[#d24804] rounded-3xl bg-[rgba(66,66,66,.12)] z-50`}
          >
            <label htmlFor='search' className='text-[12px] font-bold'>
              Select your Chain
            </label>
            <input
              id='search'
              type='search'
              placeholder='Search Chain'
              className='placeholder:font-bold placeholder:text-xs bg-transparent outline-none rounded-full 
    border border-[#d24804] px-4 py-1 my-2'
            />
            {chainData.slice(0, expandItems).map((data, i) => (
              <div
                key={i}
                className='flex justify-between items-center cursor-pointer'
                onClick={() => handleChainData(i)}
              >
                <h1 className='text-xs font-bold'>
                  {renderDropdownImage(data.tokenImg)}
                  {data.name}
                </h1>
                <h2 className='text-[13px] text-[rgba(255,255,255,.25)]'>
                  {data.mask}
                </h2>
              </div>
            ))}
            <h1
              className={`text-xs text-center ${
                chains.length > 3 && expandItems <= chains.length
                  ? ""
                  : "hidden"
              } -mt-1 cursor-pointer`}
              onClick={() => setExpandItems(expandItems === 5 ? 10 : 5)}
            >
              {expandItems <= chains.length ? "More" : "Less"}{" "}
              <IoIosArrowDown
                className={`text-3xl inline ${
                  expandItems <= chains.length ? "" : "rotate-180"
                }`}
              />
            </h1>
            <h1
              className={`text-xs ${
                expandItems === 5 && "hidden"
              } text-center -mt-1 cursor-pointer`}
              onClick={() => setExpandItems(5)}
            >
              Less <IoIosArrowDown className='text-3xl inline rotate-180' />
            </h1>
          </div>

          <img
            src='/assets/leaderboard.png'
            className='hover:scale-110 duration-500 ease-in-out absolute xl:-right-20 sm:-right-16 right-1/2 sm:translate-x-0 translate-x-[50%] sm:-top-56 -top-72 xl:scale-100 scale-75'
            alt=''
          />
          <div
            className='relative flex w-full md:h-fit sm:h-24 h-20 md:justify-between 
          justify-around flex-wrap md:items-center items-start '
          >
            <div className='w-fit relative order-1'>
              <input
                type='search'
                className='bg-transparent xl:py-3 sm:py-2 py-1 xl:px-5 px-4 pr-12 xl:w-fit 
                lg:w-48 sm:w-40 w-32 rounded-full 
                outline-none border md:placeholder:text-xl placeholder:text-base
                placeholder:text-white focus:border-[#d24804] 
                transition duration-300 ease-in-out'
                placeholder='Name'
                onChange={(e) => setSearchInput(e.target.value)}
                onKeyUp={() => handleSearch()}
              />
              <IoSearchOutline
                className='xl:text-3xl sm:text-2xl text-xl absolute 
                top-1/2 transform -translate-y-1/2 right-4'
              />
            </div>

            <button
              className={` lg:text-xl sm:text-base text-[15px] xl:py-3 sm:py-2 py-1 xl:px-10 sm:px-6 px-5 border rounded-full cursor-pointer active:border-[#d24804] 
                transition duration-300 ease-in-out order-2 ${
                  timeframe === "daily" && "border-[#d24804]"
                }`}
              onClick={() => {
                handleTimeframe("daily");
                handleSearch();
              }}
            >
              Daily
            </button>
            <button
              className={`lg:text-xl sm:text-base text-[15px] xl:py-3 sm:py-2 py-1 xl:px-10 sm:px-6 px-5 border rounded-full cursor-pointer active:border-[#d24804] 
                transition duration-300 ease-in-out order-3 ${
                  timeframe === "weekly" && "border-[#d24804]"
                }`}
              onClick={() => {
                handleTimeframe("weekly");
                handleSearch();
              }}
            >
              Weekly
            </button>
            <button
              className={`lg:text-xl sm:text-base text-[15px] xl:py-3 sm:py-2 py-1 xl:px-10 sm:px-6 px-5 border rounded-full cursor-pointer active:border-[#d24804] 
                transition duration-300 ease-in-out order-4 ${
                  timeframe === "monthly" && "border-[#d24804]"
                } `}
              onClick={() => {
                handleTimeframe("monthly");
                handleSearch();
              }}
            >
              Monthly
            </button>

            <div
              className='md:relative sm:absolute relative sm:bottom-0 md:right-0 sm:right-16 flex 
             justify-between items-center border lg:py-2 md:py-1 sm:py-2 py-1 lg:px-5 md:px-3 order-5
              sm:px-5 px-4 sm:gap-x-4 gap-x-3 rounded-full cursor-pointer'
              onClick={handlePopupDropdown}
            >
              <div className='flex items-center gap-x-1'>
                <img
                  src={dropdownData.imgPath}
                  className='lg:h-7 h-5'
                  alt={dropdownData.imgPath}
                />
                <p className='lg:text-base text-xs'>{dropdownData.chainName}</p>
              </div>
              <IoIosArrowDown className='lg:text-3xl text-2xl' />
            </div>
            {/* <GiSettingsKnobs
              className="text-3xl block transform order-6
                rotate-90 cursor-pointer md:relative sm:absolute relative sm:bottom-1 sm:right-2"
            /> */}
          </div>
          {leaderboardQuery.isLoading ? (
            <div className='flex justify-center items-center h-40'>
              <div className='loader'>
                <span className='dot'></span>
                <span className='dot'></span>
                <span className='dot'></span>
              </div>
            </div>
          ) : (
            <table
              className='w-full border-separate border-spacing-y-3 '
              cellPadding={0}
              cellSpacing={0}
            >
              <tr className='lg:text-2xl text-xl font-light '>
                <td className='pl-10'>#</td>
                <td className='pl-10'>Users</td>
                <td className='pl-10'>Points</td>
              </tr>
              {leaderboardQuery.data?.userRank && (
                <tr className='text-center bg-gray-700'>
                  {" "}
                  {/* Highlight the user row */}
                  <td className='border border-r-0 border-[rgba(255,255,255,0.16)] sm:text-[15px] text-xs'>
                    <div className='flex items-center sm:gap-x-3 gap-x-1 pl-2'>
                      <LeaderboardArrow className='inline transition duration-250  sm:w-5 sm:h-6 w-3 h-4 hover:scale-75' />
                      <LeaderboardStars className='inline' />
                      {leaderboardQuery.data.userRank}
                    </div>
                  </td>
                  <td className='border text-start flex justify-center items-center border-l-0 border-r-0 border-[rgba(255,255,255,0.16)] uppercase'>
                    <div className='w-[80%]'>
                      <img
                        src={dragonWarrior}
                        alt='User Image'
                        className='inline-block sm:h-10 h-6 sm:mr-4 mr-2'
                      />
                      <h1 className='hover:border-t hover:border-b hover:border-[#d24804] text-nowrap py-4 inline sm:text-base text-xs'>
                        You
                      </h1>
                    </div>
                  </td>
                  <td className='border border-l-0 border-[rgba(255,255,255,0.16)] sm:text-[15px] text-xs'>
                    <img
                      src={blzImg}
                      alt='Blazpay Icon'
                      className='h-5 sm:-mr-6 -mr-3 inline'
                    />
                    <p className='hover:border-t hover:border-b hover:border-[#d24804] py-4 sm:px-8 px-4 inline'>
                      {leaderboardQuery.data.userPoints}
                    </p>
                  </td>
                </tr>
              )}
              {(searchResult || leaderboardQuery.data?.leaderboard || []).map(
                (data, index) => (
                  <tr key={index} className='text-center'>
                    <td className='border border-r-0 border-[rgba(255,255,255,0.16)] sm:text-[15px] text-xs'>
                      <div className='flex items-center sm:gap-x-3 gap-x-1 pl-2'>
                        <LeaderboardArrow className='inline transition duration-250 sm:w-5 sm:h-6 w-3 h-4 hover:scale-75' />
                        <LeaderboardStars className='inline' />
                        {startingSN + index + 1}
                      </div>
                    </td>
                    <td className='border text-start flex justify-center items-center border-l-0 border-r-0 border-[rgba(255,255,255,0.16)] uppercase'>
                      <div className='w-[80%]'>
                        <img
                          src={dragonWarrior}
                          alt={data.imgPath}
                          className='inline-block sm:h-10 h-6 sm:mr-4 mr-2'
                        />
                        <h1 className='hover:border-t hover:border-b hover:border-[#d24804] text-nowrap py-4 inline sm:text-base text-xs'>
                          {data.username}
                        </h1>
                      </div>
                    </td>
                    <td className='border border-l-0 border-[rgba(255,255,255,0.16)] sm:text-[15px] text-xs'>
                      <img
                        src={blzImg}
                        alt='Blazpay Icon'
                        className='h-5 sm:-mr-6 -mr-3 inline'
                      />
                      <p className='hover:border-t hover:border-b hover:border-[#d24804] py-4 sm:px-8 px-4 inline'>
                        {data.totalPoints}
                      </p>
                    </td>
                  </tr>
                )
              )}
            </table>
          )}
          <div className='flex justify-between mt-4'>
            {/* Prev page */}
            <div
              className={`text-xl cursor-pointer ${
                currentPage === 1 && "invisible"
              }`}
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              <IoIosArrowBack className='inline text-3xl' />
              Prev Page
            </div>

            {/* Next page */}
            <div
              className={`text-xl cursor-pointer ${
                leaderboardQuery > leaderboardQuery.length - 10 && "invisible"
              }`}
              onClick={handleNextPage}
              disabled={currentPage >= leaderboardQuery.data?.totalPages}
            >
              Next Page
              <IoIosArrowForward className='inline text-3xl' />
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .loader {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .dot {
          width: 10px;
          height: 10px;
          background-color: #fff;
          border-radius: 50%;
          margin: 0 5px;
          animation: dotMove 1.5s infinite ease-in-out;
        }
        .dot:nth-child(2) {
          animation-delay: 0.2s;
        }
        .dot:nth-child(3) {
          animation-delay: 0.4s;
        }
        @keyframes dotMove {
          0%,
          100% {
            transform: translateX(0);
          }
          50% {
            transform: translateX(15px);
          }
        }
      `}</style>
    </section>
  );
};

export default Leaderboard;
