import React from "react";
import phone1 from "../assets/phone mm.png";
import phone2 from "../assets/phone mmv.png";

const ReferPhones = () => {
  return (
    <div className="w-full h-full flex flex-col md:flex-row items-center justify-center relative md:w-[40rem] md:h-[40rem]">
      <img
        src={phone1}
        className="md:absolute md:bottom-0 md:left-0 md:mt-24 w-3/4 md:w-[26rem] md:h-[32.5rem] mb-4 md:mb-0"
      />
      <img
        src={phone2}
        className="md:absolute md:top-0 md:right-0 w-3/4 md:w-auto"
      />
    </div>
  );
};

export default ReferPhones;
