import React, { useEffect, useState } from "react";
import puzzles from "../../assets/puzzleImages/index.js";
import { useWallet } from "../../hooks/useWallet";
import { useAuth } from "../../hooks/useAuth";
import { ethers } from "ethers";
import { getSignature } from "../../api/games";
import { toastPromise } from "../../services/util";
import { toast } from "react-toastify";
import axios from "axios";
import { apiCall } from "../../services/ApiCall";
import configs from "../../config/configs.json";

const tiles1 = [
  [
    { image: puzzles.puzzle1[1], number: 1 },
    { image: puzzles.puzzle1[2], number: 2 },
    { image: puzzles.puzzle1[3], number: 3 },
  ],
  [
    { image: puzzles.puzzle1[4], number: 4 },
    { image: puzzles.puzzle1[5], number: 5 },
    { image: puzzles.puzzle1[6], number: 6 },
  ],
  [
    { image: puzzles.puzzle1[7], number: 7 },
    { image: puzzles.puzzle1[8], number: 8 },
    { image: puzzles.puzzle1[9], number: 0 },
  ],
];
const tiles2 = [
  [
    { image: puzzles.puzzle2[1], number: 1 },
    { image: puzzles.puzzle2[2], number: 2 },
    { image: puzzles.puzzle2[3], number: 3 },
  ],
  [
    { image: puzzles.puzzle2[4], number: 4 },
    { image: puzzles.puzzle2[5], number: 5 },
    { image: puzzles.puzzle2[6], number: 6 },
  ],
  [
    { image: puzzles.puzzle2[7], number: 7 },
    { image: puzzles.puzzle2[8], number: 8 },
    { image: puzzles.puzzle2[9], number: 0 },
  ],
];
const tiles3 = [
  [
    { image: puzzles.puzzle3[1], number: 1 },
    { image: puzzles.puzzle3[2], number: 2 },
    { image: puzzles.puzzle3[3], number: 3 },
  ],
  [
    { image: puzzles.puzzle3[4], number: 4 },
    { image: puzzles.puzzle3[5], number: 5 },
    { image: puzzles.puzzle3[6], number: 6 },
  ],
  [
    { image: puzzles.puzzle3[7], number: 7 },
    { image: puzzles.puzzle3[8], number: 8 },
    { image: puzzles.puzzle3[9], number: 0 },
  ],
];
const tiles4 = [
  [
    { image: puzzles.puzzle4[1], number: 1 },
    { image: puzzles.puzzle4[2], number: 2 },
    { image: puzzles.puzzle4[3], number: 3 },
  ],
  [
    { image: puzzles.puzzle4[4], number: 4 },
    { image: puzzles.puzzle4[5], number: 5 },
    { image: puzzles.puzzle4[6], number: 6 },
  ],
  [
    { image: puzzles.puzzle4[7], number: 7 },
    { image: puzzles.puzzle4[8], number: 8 },
    { image: puzzles.puzzle4[9], number: 0 },
  ],
];
const tiles5 = [
  [
    { image: puzzles.puzzle5[1], number: 1 },
    { image: puzzles.puzzle5[2], number: 2 },
    { image: puzzles.puzzle5[3], number: 3 },
  ],
  [
    { image: puzzles.puzzle5[4], number: 4 },
    { image: puzzles.puzzle5[5], number: 5 },
    { image: puzzles.puzzle5[6], number: 6 },
  ],
  [
    { image: puzzles.puzzle5[7], number: 7 },
    { image: puzzles.puzzle5[8], number: 8 },
    { image: puzzles.puzzle5[9], number: 0 },
  ],
];

const Tile = ({ data, move, gameStarted }) => {
  return (
    <button
      onClick={move}
      className={`${data.number === 0 ? "opacity-0" : ""} m-px`}
      disabled={!gameStarted}
    >
      <img src={data.image} />
    </button>
  );
};

const PuzzleSlider = () => {
  const allTiles = [tiles1, tiles2, tiles3, tiles4, tiles5];
  const solution = [
    [1, 2, 3],
    [4, 5, 6],
    [7, 8, 0],
  ];
  const [plane, setPlane] = useState([]);
  const [image, setImage] = useState();
  const [showImage, setShowImage] = useState(true);
  const [blankIndex, setBlankIndex] = useState([2, 2]);
  const [gameStarted, setGameStarted] = useState(false);
  const [solved, setSolved] = useState(false);
  const [time, setTime] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [slidingLeft, setSlidingLeft] = useState(0);
  const [totalRewards, setTotalRewards] = useState(0);
  const [currentEarnings, setCurrentEarnings] = useState(0);
  const [played, setPlayed] = useState(0);
  const [originalrollsLeft, setOriginalrollsLeft] = useState(10);

  const wallet = useWallet();
  const auth = useAuth();
  const config = wallet.getChainConfig();
  const getActivityCount = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLink/getActivityCount`,
        {},
        {
          gameType: "SLIDINGPUZZLE",
          date: new Date(),
        },
        "GET"
      );
      if (isSuccess) {
        const count = parseInt(data.data?.processCount.toString(), 10);
        setSlidingLeft(10 - count);
        setOriginalrollsLeft(10 - count);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const mintReward = async () => {

    try {
      setIsSubmitting(true);
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}mint/erc20`,
        {},
        {
          amount: String(amount),
        },
        "POST"
      );
      setIsSubmitting(false);

      if (isSuccess) {
        // updateRewardCount(amount);
        // updateActivityCount();
        wallet.updatePoints({
          amount: amount,
          chainId: wallet.chainId,
          walletAddress: wallet.address,
          moduleName: "slidingpuzzle",
          txHash: "lightlink",
        });

        setTotalRewards(+totalRewards + +ethers.utils.formatEther(amount));
        setCurrentEarnings(0);
        setPlayed(0);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateRewardCount = async (reward) => {
    const res = await axios.get("/api/nft/all-balance");
    let multiplier = 1;
    if (res && res.multiplier > 1) {
      multiplier = res.multiplier;
    }
    const amount = Number(currentEarnings) * multiplier;
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLinkReward/updateRewardCount`,
        {},
        {
          reward: amount,
          gameType: "SLIDINGPUZZLE",
          date: new Date(),
          count: originalrollsLeft - slidingLeft,
        },
        "POST"
      );
      if (isSuccess) {
        await mintReward(amount);
        getRewardCount();
        auth.updateTokens();
      }else{
        getActivityCount();
        setCurrentEarnings(0);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getRewardCount = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLinkReward/getRewardCount`,
        {},
        {gameType: "SLIDINGPUZZLE",},
        "GET"
      );
      if (isSuccess) {
        setTotalRewards(data.data?.rewardCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateActivityCount = async () => {
   const currentCount = await getActivityCount();
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLink/updateActivityCount`,
        {},
        {
          gameType: "SLIDINGPUZZLE",
          date: new Date(),
          count: currentCount+1,
        },
        "POST"
      );
      if (isSuccess) {
      }
    } catch (error) {
      console.error(error);
    }
  };

  const init = async () => {
    setIsLoading(true);

    setCurrentEarnings(0);
    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      getActivityCount();
    } else {
      const [, left] = await wallet.safeCallContract({
        name: "Game",
        method: "getSlidesLeft",
        args: [wallet.address],
      });

      if (left) {
        const count = parseInt(left.toString(), 10);
        setSlidingLeft(count);
      }

      const [, rewards] = await wallet.safeCallContract({
        name: "Game",
        method: "getSlidingRewards",
        args: [wallet.address],
      });

      if (rewards) {
        console.log(rewards);
        setTotalRewards(
          Number(ethers.utils.formatUnits(rewards.toString(), 18)).toFixed(0)
        );
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!wallet.address) return;

    init();
  }, [wallet.address, wallet.chainId]);

  useEffect(() => {
    setImage(puzzles.puzzle1[0]);
    setPlane(allTiles[0]);
    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      getActivityCount();
      getRewardCount();
    }
  }, []);


  useEffect(() => {
    if (solved) {
      setCurrentEarnings(currentEarnings + calculateReward());
      setSlidingLeft(slidingLeft - 1);
      setGameStarted(false);
    }
  }, [solved]);

  useEffect(() => {
    setTimeout(() => {
      if (gameStarted) {
        setTime(time + 1);
      }
    }, 1000);
  });

  const handleBulkSlidingSubmit = async () => {
    if (!played || currentEarnings === 0) {
      return toast.info("You must play at lease once");
    }

    setIsSubmitting(true);
    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      // mintReward();
      updateRewardCount();
    } else {
      try {
        const res = await axios.get("/api/nft/all-balance");
        let multiplier = 1;
        if (res && res.multiplier > 1) {
          multiplier = res.multiplier;
        }
        const amount = Number(currentEarnings) * multiplier;
        const message = "Sliding Puzzle";
        const nonce = 789;

        const signature = await getSignature({
          to: wallet.address,
          amount: ethers.utils.parseEther(amount.toString()),
          message: message,
          nonce: nonce,
          chainId: wallet.chainId,
        });

        const [err, tx] = await wallet.safeCallContract({
          name: "Game",
          method: "bulkSliding",
          args: [
            played,
            ethers.utils.parseEther(amount.toString()),
            1,
            message,
            nonce,
            signature,
            {
              value: ethers.utils.parseEther(
                String((config.features.spinValue * played).toFixed(8))
              ),
            },
          ],
        });

        if (tx) {
          await toastPromise(tx.wait(), {
            pending: "Processing...",
            success: () => {
              wallet.updatePoints({
                amount: amount,
                chainId: wallet.chainId,
                walletAddress: wallet.address,
                moduleName: "slidingpuzzle",
                txHash: tx.hash,
              });
              console.log();
              console.log(totalRewards);
              console.log(+totalRewards + +ethers.utils.formatEther(amount));
              setTotalRewards(
                +totalRewards + +ethers.utils.formatEther(amount)
              );
              setCurrentEarnings(0);
              setPlayed(0);
              auth.updateTokens();
              return "Sliding Puzzle submitted successfully";
            },
            error: () => {
              return "Submit Failed";
            },
          });
        }
      } catch (error) {
        console.log(error);
      }

      setIsSubmitting(false);
    }
  };

  const calculateReward = () => {
    const reward = 100 - time;
    if (reward > 10) {
      return reward;
    } else return 10;
  };

  const isValidMove = (r, c, blank) => {
    const validMoves = getValidMoves(blank);
    let found = 0;

    validMoves.forEach((e) => {
      if (e[0] === r && e[1] === c) {
        found = 1;
      }
    });
    if (found === 1) {
      return true;
    } else {
      return false;
    }
  };

  const moveTile = (r, c, tempPlane, blank) => {
    if (isValidMove(r, c, blank)) {
      let temp = tempPlane[r][c];
      tempPlane[r][c] = tempPlane[blank[0]][blank[1]];
      tempPlane[blank[0]][blank[1]] = temp;
      return tempPlane;
    }
    return tempPlane;
  };

  const getValidMoves = (_blankIndex) => {
    let validMoves = [];
    if (_blankIndex[0] < 2) {
      validMoves.push([_blankIndex[0] + 1, _blankIndex[1]]);
    }
    if (_blankIndex[0] > 0) {
      validMoves.push([_blankIndex[0] - 1, _blankIndex[1]]);
    }
    if (_blankIndex[1] > 0) {
      validMoves.push([_blankIndex[0], _blankIndex[1] - 1]);
    }
    if (_blankIndex[1] < 2) {
      validMoves.push([_blankIndex[0], _blankIndex[1] + 1]);
    }
    return validMoves;
  };

  const shuffle = (tPlane) => {
    let tempPlane = tPlane;
    let blank = blankIndex;
    for (let i = 0; i < 100; i++) {
      let moves = getValidMoves(blank);
      let moveIndex = Math.floor(Math.random() * moves.length);
      tempPlane = moveTile(...moves[moveIndex], tempPlane, blank);
      blank = moves[moveIndex];
    }
    setPlane(tempPlane);
    setBlankIndex(blank);
  };

  const checkWin = (tempPlane) => {
    let match = true;
    solution.map((e, ir) => {
      e.map((e, ic) => {
        if (tempPlane[ir][ic].number !== e) {
          match = false;
        }
      });
    });
    if (match === true) {
      setSolved(true);
    }
  };

  const handleStartGame = () => {
    setPlayed(played + 1);
    let puzzleNumber = Math.floor(Math.random() * 5);
    setPlane(allTiles[puzzleNumber]);
    setImage(puzzles[`puzzle${puzzleNumber + 1}`][0]);
    setSolved(false);
    setTime(0);
    shuffle(allTiles[puzzleNumber]);
    setGameStarted(true);
  };

  const handleMoveTile = (ir, ic) => {
    let tempPlane = moveTile(ir, ic, plane, blankIndex);
    if (isValidMove(ir, ic, blankIndex)) {
      setBlankIndex([ir, ic]);
    }
    setPlane(tempPlane);
    checkWin(tempPlane);
  };
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="text-5xl flex flex-col items-center font-bold">
        <h1>Puzzle Slider</h1>
        <div className="bg-gray-800 text-white p-4 mt-2 rounded-md shadow-md">
          <div className="text-sm text-center">
            Refresh every 24 hrs to claim points
          </div>
        </div>
        <div className="flex justify-center itemps-center my-4 py-2 px-10 tracking-wider relative">
          <div className="w-full h-full absolute top-0 left-0 border-2 border-slate-800 "></div>
          <div className="border-t-2 border-l-2 border-orange-500  w-4 h-4 absolute top-0 left-0"></div>
          <div className="border-t-2 border-r-2 border-orange-500  w-4 h-4 absolute top-0 right-0"></div>
          <div className="border-b-2 border-l-2 border-orange-500  w-4 h-4 absolute bottom-0 left-0"></div>
          <div className="border-b-2 border-r-2 border-orange-500  w-4 h-4 absolute bottom-0 right-0"></div>
          {Math.floor(time / 60)
            .toString()
            .padStart(2, "0")}
          :{(time % 60).toString().padStart(2, "0")}
        </div>
      </div>
      <div className="flex w-full justify-evenly">
        <div className="grid grid-cols-3 place-content-stretch">
          {plane.map((e, ir) => {
            return (
              <>
                {e.map((e, ic) => {
                  return (
                    <>
                      <Tile
                        data={e}
                        move={() => handleMoveTile(ir, ic)}
                        gameStarted={gameStarted}
                      />{" "}
                    </>
                  );
                })}
              </>
            );
          })}
        </div>
        <div>
          <img
            src={image}
            className={` ease-in-out duration-200 ${
              showImage ? "" : "opacity-0"
            }`}
          />
        </div>
      </div>
      <div className="flex justify-evenly w-full my-4">
        <button
          className={`text-3xl  w-72 py-2 relative ${
            gameStarted ? "opacity-50" : ""
          }`}
          onClick={handleStartGame}
          disabled={gameStarted || isSubmitting}
        >
          <div className="w-full h-full absolute border-2 border-slate-800  top-0 left-0 "></div>
          <div className="border-t-2 border-l-2 border-orange-500  w-4 h-4 absolute top-0 left-0"></div>
          <div className="border-t-2 border-r-2 border-orange-500  w-4 h-4 absolute top-0 right-0"></div>
          <div className="border-b-2 border-l-2 border-orange-500  w-4 h-4 absolute bottom-0 left-0"></div>
          <div className="border-b-2 border-r-2 border-orange-500  w-4 h-4 absolute bottom-0 right-0"></div>
          Start
        </button>
        <button
          className="text-3xl  w-72 py-2 relative"
          onClick={() => setShowImage(!showImage)}
        >
          <div className="w-full h-full absolute border-2 border-slate-800  top-0 left-0 "></div>
          <div className="border-t-2 border-l-2 border-orange-500  w-4 h-4 absolute top-0 left-0"></div>
          <div className="border-t-2 border-r-2 border-orange-500  w-4 h-4 absolute top-0 right-0"></div>
          <div className="border-b-2 border-l-2 border-orange-500  w-4 h-4 absolute bottom-0 left-0"></div>
          <div className="border-b-2 border-r-2 border-orange-500  w-4 h-4 absolute bottom-0 right-0"></div>
          {showImage ? "Hide" : "Show"} Image
        </button>
      </div>
      <div className="flex-col text-center items-center justify-center pt-8">
        <div className="flex flex-row gap-10 justify-center">
          <div className="flex flex-col">
            <div className="text-xl text-white mb-2 text-center">
              Current Memory Game Rewards
            </div>
            <div className="text-5xl font-bold bg-black bg-opacity-20 py-2 px-4 text-center mb-4 text-white">
              {currentEarnings}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text-xl text-white mb-2 text-center">
              Total Memory Game Reward
            </div>
            <div className="text-5xl font-bold bg-black bg-opacity-20 py-2 px-4 text-center mb-4 text-white">
              {totalRewards}
            </div>
          </div>
        </div>

        <button
          className={`text-3xl  w-72 py-2 relative my-2 ${
            gameStarted ? "opacity-50" : "opacity-100"
          }`}
          onClick={handleBulkSlidingSubmit}
          disabled={gameStarted || isSubmitting}
        >
          <div className="w-full h-full absolute border-2 border-slate-800  top-0 left-0 "></div>
          <div className="border-t-2 border-l-2 border-orange-500  w-4 h-4 absolute top-0 left-0"></div>
          <div className="border-t-2 border-r-2 border-orange-500  w-4 h-4 absolute top-0 right-0"></div>
          <div className="border-b-2 border-l-2 border-orange-500  w-4 h-4 absolute bottom-0 left-0"></div>
          <div className="border-b-2 border-r-2 border-orange-500  w-4 h-4 absolute bottom-0 right-0"></div>
          {isSubmitting ? "Submitting..." : "SUBMIT"}
        </button>
        <div className="text-sm text-white m-2">
          Submit before leaving the page
        </div>
      </div>
      {/* <div>
        {solved ? (
          <div className="mt-8">
            <h4>
              Congratulations you solved the puzzle in{" "}
              {Math.round((time.finishTime - time.startTime) / 1000)} seconds.
            </h4>
            <button
              onClick={handleClaimReward}
              className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-10 rounded-full transition duration-300 ease-in-out"
            >
              {isSubmitting ? "Claiming..." : "Claim Reward"}
            </button>
          </div>
        ) : (
          ""
        )}
      </div> */}
    </div>
  );
};

export default PuzzleSlider;
