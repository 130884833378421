import React from "react";
window.React = React;
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
import { chains } from "./chains";

const projectId = "cf0545f2b1063ac84b6804ee8995cc74";

const metadata = {
  name: "Blazpay",
  description: "Blazpay",
  url: "https://blazpay.com",
  icons: ["https://blazpay.com/blazpoint.png"],
};

const chainImages = chains.reduce((prev, chain) => {
  prev[chain.id] = chain.tokenImg;

  return prev;
}, {});

const ethersConfig = defaultConfig({
  metadata,
  enableEIP6963: true,
  enableInjected: true,
  enableCoinbase: true,
  rpcUrl: "...", // Provide your RPC URL here
  defaultChainId: 1,
});

const web3Modal = createWeb3Modal({
  ethersConfig,
  chains: chains.map((chain) => ({
    chainId: +chain.id,
    name: chain.name,
    currency: chain.nativeCurrency.symbol,
    explorerUrl: chain.explorerUrl || null,
    rpcUrl: chain.rpcUrls[0],
  })),
  chainImages,
  projectId,
  enableAnalytics: true,
  customWallets: [
    {
      id: "eb58a4bf-61de-47c8-a42a-00922312cd62",
      name: "Blazpay Wallet",
      homepage: "www.blazpay.com", // Optional
      image_url: "https://i.ibb.co/4fXy9yP/BZLogo.jpg", // Optional
      //@ts-ignore
      injected: [
        {
          namespace: "eip155",
          injected_id: "isBlzWallet",
        },
      ],
    },
  ],
});

export default web3Modal;
