import { ethers } from "ethers";
import katlaNFTAbi from "./abi/katla/nft.json";
import katlaGameAbi from "./abi/katla/game.json";
import katlaProductAbi from "./abi/katla/product.json";
import mantaNFTAbi from "./abi/manta/nft.json";
import mantaGameAbi from "./abi/katla/game.json";
import zkfairStakeAbi from "./abi/zkfair/stake.json";
import tokenAbi from "./abi/holesky/BlazPayPointsToken.json";
import productAbi from "./abi/holesky/BlazpayProducts.json";
import stakeAbi from "./abi/holesky/StakeShardeum.json";
import gameAbi from "./abi/holesky/v2Testnet.json";

export const chains = [
  {
    id: "167009",
    name: "Taiko Hekla L2",
    nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
    rpcUrls: ["https://rpc.hekla.taiko.xyz"],
    tokenImg: "https://i.ibb.co/y5D8dJv/taiko.png",
    explorerUrl: "https://explorer.hekla.taiko.xyz/",
    features: {
      spinValue: 0,
      mintValue: 0,
      stakeValue: 0,
      apiStaking: false,
      gasLimit: false,
    },
    contracts: {
      // Entrypass: new ethers.Contract(''),
      NFT: new ethers.Contract(
        "0xdaEeF7cAB7fa7523da6f7481c850cb27406aE49A",
        stakeAbi
      ),
      STAKE_OLD: new ethers.Contract(
        "0xD60dBbdFe2095Fd286998BEAcc0B3ce0D4fFFee3",
        stakeAbi
      ),
      Stake: new ethers.Contract(
        "0xdaEeF7cAB7fa7523da6f7481c850cb27406aE49A",
        stakeAbi
      ),
      Token1: new ethers.Contract(
        "0xB825e3D37691A59C4BDfD9C8549a79bc8d3A3B8b",
        tokenAbi
      ),
      Token2: new ethers.Contract(
        "0x979eBadf1a013d1b6BDAfDE7280B6398b7010C65",
        tokenAbi
      ),
      Game: new ethers.Contract(
        "0x0385B8f207D9e258373210deF12F3189f40897e7",
        gameAbi
      ),
      Product: new ethers.Contract(
        "0xEEb788E8A677E8A9Ffc25040eb3810669110E55C",
        productAbi
      ),
    },
  },
  {
    id: "169",
    name: "Manta Pacific Mainnet",
    nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
    rpcUrls: ["https://pacific-rpc.manta.network/http"],
    explorerUrl: "https://pacific-explorer.manta.network/",
    tokenImg: "https://i.ibb.co/CPzhyTf/manta.png",
    features: {
      spinValue: 0.000003,
      mintValue: 0.00007,
      stakeValue: 0,
      apiStaking: false,
      gasLimit: false,
    },
    contracts: {
      NFT: new ethers.Contract(
        "0xdaEeF7cAB7fa7523da6f7481c850cb27406aE49A",
        stakeAbi
      ),
      STAKE_OLD: new ethers.Contract(
        "0xb951A0a8afa9Fa5B729Ad3ade861a610bCb62Df6",
        stakeAbi
      ),
      Stake: new ethers.Contract(
        "0xdaEeF7cAB7fa7523da6f7481c850cb27406aE49A",
        stakeAbi
      ),
      Token1: new ethers.Contract(
        "0x3FD63640BbC970945C909338eE38371a4d8c13D6",
        tokenAbi
      ),
      Token2: new ethers.Contract(
        "0x979eBadf1a013d1b6BDAfDE7280B6398b7010C65",
        tokenAbi
      ),
      Game: new ethers.Contract(
        "0x0385B8f207D9e258373210deF12F3189f40897e7",
        gameAbi
      ),
      Product: new ethers.Contract(
        "0xEEb788E8A677E8A9Ffc25040eb3810669110E55C",
        productAbi
      ),
    },
  },
  {
    id: "17000",
    name: "Holesky",
    nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
    rpcUrls: ["https://ethereum-holesky-rpc.publicnode.com"],
    explorerUrl: "https://holesky.etherscan.io/",
    tokenImg: "https://i.ibb.co/tHbx9Jw/arbitrum.png",
    features: {
      spinValue: 0,
      mintValue: 0,
      stakeValue: 0,
      unStakeValue: 0,
      apiStaking: false,
      gasLimit: false,
    },
    contracts: {
      // Entrypass: new ethers.Contract(''),
      NFT: new ethers.Contract(
        "0x074D1a5ba06218D4a10020c801436231522EC669",
        stakeAbi
      ),
      STAKE_OLD: new ethers.Contract(
        "0xd3592602FC5A8f4632f4b130618E09F7ed8e0705",
        stakeAbi
      ),
      Stake: new ethers.Contract(
        "0x074D1a5ba06218D4a10020c801436231522EC669",
        stakeAbi
      ),
      Token1: new ethers.Contract(
        "0x23Bd58929Efbe6469C188459D288437737191328",
        tokenAbi
      ),
      Token2: new ethers.Contract(
        "0xe914D607A64c5ac3b2c9db3e1b5D809ec4C2E4BF",
        tokenAbi
      ),
      Game: new ethers.Contract(
        "0x79E88A7a861395E33325796E58dEe1642f486d57",
        gameAbi
      ),
      Product: new ethers.Contract(
        "0x0E1230ffBF651651144231E39EBBe26C238B5d16",
        productAbi
      ),
      //Store: new ethers.Contract(''),
    },
  },
  {
    id: "30732",
    name: "Movement EVM Testnet",
    nativeCurrency: { name: "MOVE", symbol: "MOVE", decimals: 18 },
    rpcUrls: ["https://mevm.devnet.imola.movementlabs.xyz"],
    explorerUrl: "https://explorer.movementlabs.xyz",
    tokenImg: "https://i.ibb.co/ZXGvbMx/movement-Logo.jpg",
    features: {
      spinValue: 0,
      mintValue: 0,
      stakeValue: 0,
      unStakeValue: 0,
      apiStaking: false,
      gasLimit: false,
    },
    contracts: {
      // Entrypass: new ethers.Contract(''),
      NFT: new ethers.Contract(
        "0x27ddF355f35a772602e71E6C72f0a78132de627E",
        stakeAbi
      ),
      STAKE_OLD: new ethers.Contract(
        "0x27ddF355f35a772602e71E6C72f0a78132de627E",
        stakeAbi
      ),
      Stake: new ethers.Contract(
        "0x27ddF355f35a772602e71E6C72f0a78132de627E",
        stakeAbi
      ),
      Token1: new ethers.Contract(
        "0x31B1a5CE8A84f06b0b5C04eE01a04732aE804b0A",
        tokenAbi
      ),
      Token2: new ethers.Contract(
        "0x31B1a5CE8A84f06b0b5C04eE01a04732aE804b0A",
        tokenAbi
      ),
      Game: new ethers.Contract(
        "0xb7C401D897C778D33d92Be878Af013A24CA9D8Ed",
        gameAbi
      ),
      Product: new ethers.Contract(
        "0x44cb090877c2811ef75dBb50cb779a11ce6cFD31",
        productAbi
      ),
      //Store: new ethers.Contract(''),
    },
  },
  {
    id: "137",
    name: "Polygon Mainnet",
    rpcUrls: ["https://polygon-rpc.com/"],
    chainHex: "0x89",
    currency: "MATIC",
    explorerUrl: "https://polygonscan.com/",
    tokenImg: "https://i.ibb.co/MDKTCF6/polygon.png",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    features: {
      spinValue: 0.01,
      mintValue: 0.5,
      stakeValue: 0.5,
      apiStaking: false,
      gasLimit: false,
    },
    contracts: {
      NFT: new ethers.Contract(
        "0xc45F3DEbBecB8156fC8ac564343EA0eDF55B9E81",
        stakeAbi
      ),
      STAKE_OLD: new ethers.Contract(
        "0x74638B17aF67e7A5EEaC0529EFa30b77572594Ae",
        stakeAbi
      ),
      Stake: new ethers.Contract(
        "0xc45F3DEbBecB8156fC8ac564343EA0eDF55B9E81",
        stakeAbi
      ),
      Token1: new ethers.Contract(
        "0x9a03A57265081ACB87a9EE66CC1D5C4F793C99D2",
        tokenAbi
      ),
      Token2: new ethers.Contract(
        "0x79E88A7a861395E33325796E58dEe1642f486d57",
        tokenAbi
      ),
      Game: new ethers.Contract(
        "0xbB3Fc1243Cb6852Ffca289D629C48A031050d3b7",
        gameAbi
      ),
      Product: new ethers.Contract(
        "0x074D1a5ba06218D4a10020c801436231522EC669",
        productAbi
      ),
    },
  },
  {
    id: "56",
    name: "BNB Smart Chain Mainnet",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://bsc-dataseed1.binance.org/"],
    explorerUrl: ["https://bscscan.com/"],
    tokenImg: "https://i.ibb.co/VMVS2Hc/binance.png",
    features: {
      spinValue: 0.00002,
      mintValue: 0.002,
      stakeValue: 0.002,
      apiStaking: false,
      gasLimit: false,
    },
    contracts: {
      NFT: new ethers.Contract(
        "0x0E1230ffBF651651144231E39EBBe26C238B5d16",
        stakeAbi
      ),
      STAKE_OLD: new ethers.Contract(
        "0x10CFd016888788077b071A334a47B171AbcF6252",
        stakeAbi
      ),
      Stake: new ethers.Contract(
        "0x0E1230ffBF651651144231E39EBBe26C238B5d16",
        stakeAbi
      ),
      Token1: new ethers.Contract(
        "0xA5d3f2a7dD90669843009617C15656D14327EB8d",
        tokenAbi
      ),
      Token2: new ethers.Contract(
        "0x075a66C62a96E1f81fe73D9f362599F0bE74bdcD",
        tokenAbi
      ),
      Game: new ethers.Contract(
        "0xe914D607A64c5ac3b2c9db3e1b5D809ec4C2E4BF",
        gameAbi
      ),
      Product: new ethers.Contract(
        "0x1ebbA67f4c78cFa8b58381f3D2474DaB3b133D77",
        productAbi
      ),
    },
  },
  // {
  //   id: "997",
  //   name: "5ireChain Thunder",
  //   nativeCurrency: {
  //     name: "5ire",
  //     symbol: "5ire",
  //     decimals: 18,
  //   },
  //   rpcUrls: ["https://rpc.ga.5ire.network/"],
  //   explorerUrl: ["https://explorer.ga.5ire.network/"],
  //   tokenImg: "https://i.ibb.co/VMVS2Hc/binance.png",
  //   features: {
  //     spinValue: 0,
  //     mintValue: 0,
  //     stakeValue: 0,
  //     apiStaking: false,
  //     gasLimit: false,
  //   },
  //   contracts: {
  //     NFT: new ethers.Contract(
  //       "0xdaEeF7cAB7fa7523da6f7481c850cb27406aE49A",
  //       stakeAbi
  //     ),
  //     Stake: new ethers.Contract(
  //       "0xdaEeF7cAB7fa7523da6f7481c850cb27406aE49A",
  //       stakeAbi
  //     ),
  //     Token2: new ethers.Contract(
  //       "0x979eBadf1a013d1b6BDAfDE7280B6398b7010C65",
  //       tokenAbi
  //     ),
  //     Game: new ethers.Contract(
  //       "0x0385B8f207D9e258373210deF12F3189f40897e7",
  //       gameAbi
  //     ),
  //     Product: new ethers.Contract(
  //       "0xEEb788E8A677E8A9Ffc25040eb3810669110E55C",
  //       productAbi
  //     ),
  //   },
  // },
  // {
  //   id: "42766",
  //   name: "ZKFair Mainnet",
  //   nativeCurrency: { name: "USDC", symbol: "USDC", decimals: 18 },
  //   rpcUrls: ["https://rpc.zkfair.io"],
  //   explorerUrl: "https://scan.zkfair.io/",
  //   tokenImg: "https://i.ibb.co/y5D8dJv/taiko.png",
  //   features: {
  //     spinValue: 0.1,
  //     mintValue: 1.5,
  //     stakeValue: 1.5,
  //     unStakeValue: 1.5,
  //     apiStaking: false,
  //   },
  //   contracts: {
  //     // Entrypass: new ethers.Contract(''),
  //     NFT: new ethers.Contract(
  //       "0x8A2E7143f1b153f454836c2fef5d53Fe6d8bB02B",
  //       zkfairStakeAbi
  //     ),
  //     Stake: new ethers.Contract(
  //       "0x8A2E7143f1b153f454836c2fef5d53Fe6d8bB02B",
  //       zkfairStakeAbi
  //     ),
  //     Token: new ethers.Contract(
  //       "0x8bD6f3bD448FcaD043c7b08FafEb3AB04126d9d4",
  //       tokenAbi
  //     ),
  //     Game: new ethers.Contract(
  //       "0xfcc11fCfbAeD8135C13AA2f7129712787b0618d6",
  //       mantaGameAbi
  //     ),
  //     Product: new ethers.Contract(
  //       "0x85523d5EdA897884432Eec14A71B153FbA6832D1",
  //       katlaProductAbi
  //     ),
  //     //Store: new ethers.Contract(''),
  //   },
  // },
  // {
  //   id: '8082',
  //   name: '	Shardeum Sphinx 1.X',
  //   nativeCurrency: {
  //     name: 'SHM',
  //     symbol: 'SHM',
  //     decimals: 18,
  //   },
  //   rpcUrls: ['https://sphinx.shardeum.org/'],
  //   explorerUrl: ['https://explorer-sphinx.shardeum.org/'],
  //   tokenImg: 'https://i.ibb.co/y5D8dJv/taiko.png',
  //   features: {
  //     spinValue: 0,
  //     mintValue: 0,
  //     stakeValue: 0,
  //     apiStaking: false,
  //     gasLimit:true,
  //   },
  //   contracts: {
  //     // Entrypass: new ethers.Contract(''),
  //     NFT: new ethers.Contract(
  //       '0x97E3aB56ec2C13ACA2993076523F5b3D10D4A138',
  //       zkfairStakeAbi
  //     ),
  //     Stake: new ethers.Contract(
  //       '0x97E3aB56ec2C13ACA2993076523F5b3D10D4A138',
  //       zkfairStakeAbi
  //     ),
  //     Token: new ethers.Contract(
  //       '0x427539B9f81cd890b554e4f3F36eBa6D6d96e61a',
  //       tokenAbi
  //     ),
  //     Game: new ethers.Contract(
  //       '0x60d078234bCDd1c86C5020D4a64A2213568765a4',
  //       mantaGameAbi
  //     ),
  //     Product: new ethers.Contract(
  //       '0x56345f409b17CE3943F521f9Ef9f0150435c43b4',
  //       katlaProductAbi
  //     ),
  //   },
  // },
  // {
  //   id: "167008",
  //   name: "Katla L2",
  //   nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
  //   rpcUrls: ["https://rpc.katla.taiko.xyz"],
  //   tokenImg: "https://i.ibb.co/y5D8dJv/taiko.png",
  //   explorerUrl: "https://explorer.katla.taiko.xyz/",
  //   id: "167008",
  //   name: "Taiko Katla L2",
  //   nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
  //   rpcUrls: ["https://rpc.katla.taiko.xyz"],
  //   tokenImg: "https://i.ibb.co/y5D8dJv/taiko.png",
  //   explorerUrl: "https://explorer.katla.taiko.xyz/",
  //   features: {
  //     spinValue: 0,
  //     mintValue: 0,
  //     stakeValue: 0,
  //     apiStaking: true,
  //     gasLimit: false,
  //   },
  //   contracts: {
  //     // Entrypass: new ethers.Contract(''),
  //     NFT: new ethers.Contract(
  //       "0xedd0dDaEdbc3FBf67aC4ff2ee14Ace669821eac1",
  //       katlaNFTAbi
  //     ),
  //     Stake: new ethers.Contract(
  //       "0x1670080000000000000000000000000000000003",
  //       []
  //     ),
  //     Token: new ethers.Contract(
  //       "0x14027051bB9fEDbb8D8559416b056C2CBf3aA238",
  //       tokenAbi
  //     ),
  //     Game: new ethers.Contract(
  //       "0x1A0ECe8a973Be50bd44842960D842dc35e3E83A4",
  //       katlaGameAbi
  //     ),
  //     Product: new ethers.Contract(
  //       "0xa3B919018c23f3F19A9CAb00C243245598666F87",
  //       katlaProductAbi
  //     ),
  //   },
  // },
  {
    id: "1891",
    name: "Pegasus Testnet",
    nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
    rpcUrls: ["https://replicator.pegasus.lightlink.io/rpc/v1"],
    tokenImg: "https://i.ibb.co/7J0BZGf/lightlink-Logo.png",
    explorerUrl: "https://pegasus.lightlink.io/",
    features: {
      spinValue: 5,
      mintValue: 10,
      stakeValue: 100,
      apiStaking: true,
      gasLimit: true,
    },
    // contracts: {
    //   NFT: new ethers.Contract(
    //     "0xLightLinkNFTContractAddress",
    //     stakeAbi // Replace with actual ABI for LightLink NFT
    //   ),
    //   Stake: new ethers.Contract(
    //     "0xLightLinkStakeContractAddress",
    //     stakeAbi // Replace with actual ABI for staking
    //   ),
    //   Token1: new ethers.Contract(
    //     "0xLightLinkToken1Address",
    //     tokenAbi // Replace with actual ABI for Token1
    //   ),
    //   Token2: new ethers.Contract(
    //     "0xLightLinkToken2Address",
    //     tokenAbi // Replace with actual ABI for Token2
    //   ),
    //   Game: new ethers.Contract(
    //     "0xLightLinkGameContractAddress",
    //     gameAbi // Replace with actual ABI for Game contract
    //   ),
    //   Product: new ethers.Contract(
    //     "0xLightLinkProductContractAddress",
    //     productAbi // Replace with actual ABI for Product contract
    //   ),
    // },
  },
];
