import { useEffect, useState } from "react";
import { useWallet } from "../../hooks/useWallet";
import {
  NextItemStoreFrontArrow,
  StoreFrontCard,
  StoreFrontItemCard,
} from "../../components/SvgContainer";
import storeCat from "../../assets/storefrontcat.png";
import FullScreenLoader from "../../components/Loader/FullScreenLoader";
import noItems from "../../assets/icon 4-01.png";
import { apiCall } from "../../services/ApiCall";
import configs from "../../config/configs.json";

const knownURIs = {
  "ipfs://QmWHPHdxn7d8jufatMoyyh71WW49vwArjWQNAQemZ5NEUg/10.json": {
    imageUrl: "https://i.ibb.co/nDp849P/NFT9.png",
    price: 10000,
  },
  "ipfs://QmWHPHdxn7d8jufatMoyyh71WW49vwArjWQNAQemZ5NEUg/9.json": {
    imageUrl: "https://i.ibb.co/xfb3Xyd/NFT8.png",
    price: 100,
  },

  "ipfs://QmWHPHdxn7d8jufatMoyyh71WW49vwArjWQNAQemZ5NEUg/8.json": {
    imageUrl: "https://i.ibb.co/B4XBGyZ/NFT7.png",
    price: 500,
  },

  "ipfs://QmWHPHdxn7d8jufatMoyyh71WW49vwArjWQNAQemZ5NEUg/7.json": {
    imageUrl: "https://i.ibb.co/SKZqr5K/NFT6.png",
    price: 1500,
  },

  "ipfs://QmWHPHdxn7d8jufatMoyyh71WW49vwArjWQNAQemZ5NEUg/6.json": {
    imageUrl: "https://i.ibb.co/Ptm20dh/NFT5.png",
    price: 500,
  },
  "ipfs://QmWHPHdxn7d8jufatMoyyh71WW49vwArjWQNAQemZ5NEUg/5.json": {
    imageUrl: "https://i.ibb.co/qWDB0Pj/NFT4.png",
    price: 1500,
  },
  "ipfs://QmWHPHdxn7d8jufatMoyyh71WW49vwArjWQNAQemZ5NEUg/4.json": {
    imageUrl: "https://i.ibb.co/rfwTL1D/NFT3.png",
    price: 1500,
  },
  "ipfs://QmWHPHdxn7d8jufatMoyyh71WW49vwArjWQNAQemZ5NEUg/3.json": {
    imageUrl: "https://i.ibb.co/MnbpH1V/NFT2.png",
    price: 150,
  },
  "ipfs://QmWHPHdxn7d8jufatMoyyh71WW49vwArjWQNAQemZ5NEUg/2.json": {
    imageUrl: "https://i.ibb.co/hR4Wvtm/NFT1.png",
    price: 50,
  },
  "ipfs://QmWHPHdxn7d8jufatMoyyh71WW49vwArjWQNAQemZ5NEUg/1.json": {
    imageUrl: "https://i.ibb.co/F7rf7Hh/NFT.png",
    price: 100,
  },
  "ipfs://QmaFTpjwu5YTY5kmzbUXDcQCeknKrJZJpNptqpdZwKNn6R/2.json": {
    imageUrl: "https://i.ibb.co/r4dYDm4/ZETAkey.png",
    price: 5000,
  },
  "ipfs://QmaFTpjwu5YTY5kmzbUXDcQCeknKrJZJpNptqpdZwKNn6R/3.json": {
    imageUrl: "https://i.ibb.co/QYMJyfv/Tech-Tec-Of-Zeta.png",
    price: 50,
  },
  "ipfs://QmaFTpjwu5YTY5kmzbUXDcQCeknKrJZJpNptqpdZwKNn6R/1.json": {
    imageUrl: "https://i.ibb.co/J37sqPM/Cross-chain-agnostic.png",
    price: 1000,
  },
};

export default function MyItems() {
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const wallet = useWallet();

  const nextPageHandler = () => {
    if ((currentPage + 1) * 6 < items.length) {
      setCurrentPage(currentPage + 1);
    }
  };
  const prevPageHandler = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getBuyNft = async () => {
    setIsLoading(true);
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLinkReward/getBuyNft`,
        {},
        {},
        "GET"
      );
      setIsLoading(false);
      if (isSuccess) {
        setItems(data?.data.reverse());
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!wallet.address) return;
    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      getBuyNft();
    } else {
      const init = async () => {
        const [, items] = await wallet.safeCallContract({
          name: "Product",
          method: "tokensOfOwner",
          args: [wallet.address],
        });

        if (!items) return;

        const promises = [];

        const fn = async (i) => {
          const [, tokenId] = await wallet.safeCallContract({
            name: "Product",
            method: "tokenOfOwnerByIndex",
            args: [wallet.address, i],
          });
          if (tokenId) {
            const [, tokenURI] = await wallet.safeCallContract({
              name: "Product",
              method: "tokenURI",
              args: [tokenId],
            });

            if (tokenURI && knownURIs[tokenURI]) {
              return {
                id: tokenId.toString(),
                name: tokenId.toString(),
                ...knownURIs[tokenURI],
              };
            }
          }
        };

        for (let i = 0; i < items.length; i++) {
          promises.push(fn(i));
        }

        const data = await Promise.all(promises);
        setItems(data.filter(Boolean));
      };

      setIsLoading(true);
      init().finally(() => setIsLoading(false));
    }
  }, [wallet, wallet.address, wallet.chainId]);

  if (isLoading) return <FullScreenLoader />;

  return (
    <section className="min-h-screen w-full storefront-container text-white">
      <div className="xl:container  w-full mx-auto h-full pt-4  relative">
        <h1 className="text-5xl font-bold pl-16">My Items</h1>
        {items.length === 0 ? (
          <div className="flex flex-col mt-[10%] justify-center items-center t text-center text-2xl h-90">
            <img src={noItems} alt="noItems" className="h-60 mb-6" />
            <section>No Items</section>
          </div>
        ) : (
          <div className="w-full h-full flex md:flex-row flex-col items-center justify-center md:gap-y-0 gap-y-12 2xl:gap-x-16 lg:gap-x-10 gap-x-6 py-8">
            {/* Selected StoreFront */}
            {/* <section className="2xl:w-[500px] 2xl:h-[640px] md:w-[418px] w-[320px] h-[420px] md:h-[547px] relative">
              <img
                src={items[0].imageUrl}
                alt=""
                className="absolute 2xl:top-7 top-4 left-1/2 transform translate-x-[-50%] 2xl:h-fit 2xl:w-fit md:w-96 w-72"
              />
              <div className="flex flex-col md:px-5 px-2 w-full absolute 2xl:bottom-10 md:bottom-7 bottom-4">
                <h1 className="md:text-3xl text-2xl">{items[0].name}</h1>
                <p className="md:text-2xl text-xl 2xl:mt-9 md:mt-3 mt-2">
                  Blazpoints
                </p>
                <div className="flex justify-between w-full xl:pr-5 pr-3 pb-2">
                  <p className="md:text-[20px] md:mt-2 text-[#ff3503]">
                    {items[0].price || 0} BLZP
                  </p>
                </div>
              </div>
              <StoreFrontCard className="h-full w-full" />
            </section> */}
            {/* List of storefronts */}
            <div className="grid xl:grid-cols-3  grid-cols-2 lg:gap-6 md:gap-4  gap-y-3 gap-x-9 ">
              {items.slice(currentPage * 6, currentPage * 6 + 6).map((item) => {
                return (
                  <div
                    key={item?.id}
                    className="2xl:w-[280px] 2xl:h-[316px] xl:w-[230px] xl:h-[261px] w-[140px] h-[176px] relative"
                  >
                    <img
                      src={item.imageUrl || storeCat}
                      alt=""
                      className="absolute 2xl:h-52 2xl:w-60 xl:h-40 xl:w-52 h-[90px] w-28 top-4  left-1/2 transform translate-x-[-50%]"
                    />
                    <div className="flex flex-col xl:px-5 px-3 w-full absolute xl:bottom-3 bottom-5">
                      <h1 className="text-base">{item.name}</h1>
                      <p className="xl:text-base text-xs">
                        Quantity: {item?.quantity}
                      </p>
                      {/* <p className="xl:text-base text-xs">Blazpoints</p> */}
                      <p className="xl:text-[13px] text-[10px] text-[#ff3503]">
                        {item.price || 0} BLZP
                      </p>
                    </div>
                    <StoreFrontItemCard className="w-full h-full" />
                  </div>
                );
              })}
              {items.length > 7 ? (
                <>
                  <NextItemStoreFrontArrow
                    className="absolute md:py-0 py-1 bottom-0 md:left-10 left-3/2 transform md:translate-x-0 translate-x-[400%] rotate-180"
                    onClick={prevPageHandler}
                  />
                  <NextItemStoreFrontArrow
                    className="absolute md:py-0 py-1 bottom-0 md:right-16 right-1/2 transform md:translate-x-0 translate-x-[400%]"
                    onClick={nextPageHandler}
                  />
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
