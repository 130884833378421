import React from 'react';
window.React = React;
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import 'react-toastify/dist/ReactToastify.css';
import router from './router.jsx';
import { WalletProvider } from './contexts/WalletContext.jsx';
import { AuthProvider } from './contexts/AuthContext.jsx';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (count, error) => {
        if (count >= 2) return false;
        if (error.response?.status <= 500) return false;

        return true;
      },
      retryDelay: 0,
    },
  },
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <WalletProvider>
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </WalletProvider>
      <ToastContainer position="top-right" theme="dark" />
    </QueryClientProvider>
  );
};

export default App;
