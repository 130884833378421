import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://9d70aab4527117de62243a6139f3d632@o4507049436971008.ingest.us.sentry.io/4507049438412800',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/blazpay\.com\/*/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// ['log', 'error'].forEach(function (method) {
//   var oldMethod = console[method];
//   console[method] = function () {
//     Sentry.captureMessage(arguments[0], method === 'log' ? 'info' : 'error');
//     oldMethod.apply(console, arguments);
//   };
// });
