import React from 'react';
window.React = React;
import './services/sentry.js';
import './services/walletConnect.js';
import './index.css';
import ReactDOM from 'react-dom/client';
import App from './App.jsx';
import './services/axios.js';

ReactDOM.createRoot(document.getElementById('root')).render(<App />);
