import axios from "axios";

const PROD_URL = "https://api-v2.blazpay.com";
const DEV_URL = "http://localhost:5000";

axios.defaults.baseURL =
  // eslint-disable-next-line no-undef
  process.env.NODE_ENV === "development" ? DEV_URL : PROD_URL;
console.log(process.env.NODE_ENV,axios.defaults.baseURL,"gfuoiurert")
axios.defaults.headers.post["Content-Type"] = "application/json";

axios.setToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

axios.interceptors.response.use(
  (response) => response.data?.data || response.data
);

export default axios;
