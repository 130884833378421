import React, { useState, useEffect } from "react";
import { useWallet } from "../../hooks/useWallet";
import { useAuth } from "../../hooks/useAuth";
import { ethers } from "ethers";
import { getSignature } from "../../api/games";
import { toastPromise } from "../../services/util";
import { toast } from "react-toastify";
import axios from "axios";

import vechain from "../../assets/Frame 56.png";
import bnb from "../../assets/Frame 57.png";
import eos from "../../assets/Frame 60.png";
import neo from "../../assets/Frame 61.png";
import eth from "../../assets/Frame 48.png";
import backside from "../../assets/memory-back.png";
import "../../assets/css/memory.css";
import { apiCall } from "../../services/ApiCall";
import configs from "../../config/configs.json";

const Tile = ({ flipped, image, id, handleFlip }) => {
  return (
    <div className={` ${flipped ? "flipped" : ""}  m-2`} id={id}>
      <img
        width={"150px"}
        height={"150px"}
        src={image}
        alt="Memory Game Tile"
        className={`front`}
      />
      <img
        width={"150px"}
        height={"150px"}
        src={backside}
        alt="Memory Game Back"
        className="back"
        onClick={handleFlip}
      />
    </div>
  );
};

const Memory = () => {
  const [tiles, setTiles] = useState([
    { image: vechain, matched: false },
    { image: vechain, matched: false },
    { image: bnb, matched: false },
    { image: bnb, matched: false },
    { image: eos, matched: false },
    { image: eos, matched: false },
    { image: neo, matched: false },
    { image: neo, matched: false },
    { image: eth, matched: false },
    { image: eth, matched: false },
  ]);
  const [choiceOne, setChoiceOne] = useState(-1);
  const [choiceTwo, setChoiceTwo] = useState(-1);
  const [solved, setSolved] = useState(0);
  const [gameStarted, setGameStarted] = useState(false);
  const [time, setTime] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [memoryGamesLeft, setMemoryGamesLeft] = useState(0);
  const [totalRewards, setTotalRewards] = useState(0);
  const [currentEarnings, setCurrentEarnings] = useState(0);
  const [played, setPlayed] = useState(0);
  const [originalrollsLeft, setOriginalrollsLeft] = useState(10);


  const wallet = useWallet();
  const auth = useAuth();
  const config = wallet.getChainConfig();
  const getActivityCount = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLink/getActivityCount`,
        {},
        {
          gameType: "MEMORYMATCHING",
          date: new Date(),
        },
        "GET"
      );
      if (isSuccess) {
        const count = parseInt(data.data?.processCount.toString(), 10);
        setOriginalrollsLeft(10 - count);
        setMemoryGamesLeft(10 - count);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const mintReward = async (amount) => {
    try {
      setIsSubmitting(true);
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}mint/erc20`,
        {},
        {
          amount: String(amount),
        },
        "POST"
      );
      setIsSubmitting(false);

      if (isSuccess) {
        handleStartGame();
        wallet.updatePoints({
          amount: amount,
          chainId: wallet.chainId,
          walletAddress: wallet.address,
          moduleName: "memorygame",
          txHash: "lightlink",
        });
        setTotalRewards(+totalRewards + amount);
        setCurrentEarnings(0);
        setPlayed(0);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateRewardCount = async () => {
    const res = await axios.get("/api/nft/all-balance");
    let multiplier = 1;
    if (res && res.multiplier > 1) {
      multiplier = res.multiplier;
    }
    const amount = Number(currentEarnings) * multiplier;
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLinkReward/updateRewardCount`,
        {},
        {
          reward: amount,
          gameType: "MEMORYMATCHING",
          date: new Date(),
          count: originalrollsLeft - memoryGamesLeft,
        },
        "POST"
      );
      setIsSubmitting(false);
      if (isSuccess) {
        await mintReward(amount);
        getRewardCount();
        auth.updateTokens();
      } else {
        getActivityCount();
        setCurrentEarnings(0);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getRewardCount = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLinkReward/getRewardCount`,
        {},
        { gameType: "MEMORYMATCHING" },
        "GET"
      );
      if (isSuccess) {
        setTotalRewards(data.data?.rewardCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateActivityCount = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLink/updateActivityCount`,
        {},
        {
          gameType: "MEMORYMATCHING",
          date: new Date(),
          count: 10 - memoryGamesLeft,
        },
        "POST"
      );
      if (isSuccess) {
      }
    } catch (error) {
      console.error(error);
    }
  };

  const init = async () => {
    setIsLoading(true);

    setCurrentEarnings(0);
    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      getActivityCount();
    } else {
      const [, left] = await wallet.safeCallContract({
        name: "Game",
        method: "getMemoryLeft",
        args: [wallet.address],
      });

      if (left) {
        const count = parseInt(left.toString(), 10);
        setMemoryGamesLeft(count);
      }

      const [, rewards] = await wallet.safeCallContract({
        name: "Game",
        method: "getMemoryRewards",
        args: [wallet.address],
      });

      if (rewards) {
        setTotalRewards(
          Number(ethers.utils.formatUnits(rewards.toString())).toFixed(0)
        );
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!wallet.address) return;

    init();
  }, [wallet.address, wallet.chainId]);

  useEffect(() => {
    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      getActivityCount();
      getRewardCount();
    }
  }, []);

  useEffect(() => {
    if (gameStarted) {
      setTimeout(() => {
        setTime(time + 1);
      }, 1000);
    }
  });

  useEffect(() => {
    if (choiceOne !== -1 && choiceTwo !== -1) {
      if (tiles[choiceOne].image === tiles[choiceTwo].image) {
        const tempTiles = tiles;
        tempTiles[choiceOne].matched = true;
        tempTiles[choiceTwo].matched = true;
        if (solved + 2 === 10 && played) {
          setCurrentEarnings(currentEarnings + calculateReward());
          setMemoryGamesLeft(memoryGamesLeft - 1);
          setGameStarted(false);
          // handleStartGame();
        }
        setTiles(tempTiles);
        setSolved(solved + 2);
      }
      setTimeout(() => {
        setChoiceOne(-1);
        setChoiceTwo(-1);
      }, 500);
    }
  }, [choiceOne, choiceTwo]);

  const calculateReward = () => {
    const reward = 60 - time;
    if (reward > 10) {
      return reward;
    } else return 10;
  };

  const handleBulkSearchSubmit = async () => {
    if (!played || currentEarnings === 0) {
      return toast.info("You must play at lease once");
    }

    setIsSubmitting(true);
    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      updateRewardCount();
    } else {
      try {
        const res = await axios.get("/api/nft/all-balance");
        let multiplier = 1;
        if (res && res.multiplier > 1) {
          multiplier = res.multiplier;
        }
        const amount = Number(currentEarnings) * multiplier;

        const message = "Memory Game";
        const nonce = 456;

        const signature = await getSignature({
          to: wallet.address,
          amount: ethers.utils.parseEther(amount.toString()),
          message: message,
          nonce: nonce,
          chainId: wallet.chainId,
        });

        const [err, tx] = await wallet.safeCallContract({
          name: "Game",
          method: "bulkMemoryGame",
          args: [
            played,
            ethers.utils.parseEther(amount.toString()),
            1,
            message,
            nonce,
            signature,
            {
              // gasLimit: 21000,
              value: ethers.utils.parseEther(
                String((config.features.spinValue * played).toFixed(8))
              ),
            },
          ],
        });

        if (tx) {
          await toastPromise(tx.wait(), {
            pending: "Processing...",
            success: () => {
              wallet.updatePoints({
                amount: amount,
                chainId: wallet.chainId,
                walletAddress: wallet.address,
                moduleName: "memorygame",
                txHash: tx.hash,
              });
              setTotalRewards(+totalRewards + amount);
              setCurrentEarnings(0);
              setPlayed(0);
              auth.updateTokens();
              return "Memory Game submitted successfully";
            },
            error: () => {
              return "Submit Failed";
            },
          });
        }
      } catch (error) {
        console.log(error);
      }

      setIsSubmitting(false);
    }
  };

  const handleFlip = (i) => {
    if (choiceOne === -1) {
      setChoiceOne(i);
    } else {
      if (choiceTwo === -1 && choiceOne !== i) {
        setChoiceTwo(i);
      }
    }
  };
  const handleStartGame = () => {
    setTime(0);
    setPlayed(played + 1);
    setSolved(0);
    let tilesArr = tiles;
    for (let i = 0; i < tilesArr.length; i++) {
      let randomIndex = Math.floor(Math.random() * tilesArr.length);
      let temp = tilesArr[i];
      tilesArr[i] = tilesArr[randomIndex];
      tilesArr[randomIndex] = temp;
    }
    for (let i = 0; i < tilesArr.length; i++) {
      tilesArr[i].matched = false;
    }
    setTiles(tilesArr);
    if (!gameStarted) {
      setTime(0);
    }
    setGameStarted(true);
  };
  const handleClaimReward = async () => {
    setIsSubmitting(true);

    let reward = time;

    setGameStarted(false);
    setIsSubmitting(false);
  };

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="text-5xl flex flex-col items-center font-bold">
        <h1>Memory Matching</h1>
        <div className="bg-gray-800 text-white p-4 mt-2 rounded-md shadow-md">
          <div className="text-sm text-center">
            Refresh every 24 hrs to claim points
          </div>
        </div>
        <div className="flex justify-center itemps-center my-2 py-2 px-10 tracking-wider relative">
          <div className="w-full h-full absolute top-0 left-0 border-2 border-slate-800 "></div>
          <div className="border-t-2 border-l-2 border-orange-500  w-4 h-4 absolute top-0 left-0"></div>
          <div className="border-t-2 border-r-2 border-orange-500  w-4 h-4 absolute top-0 right-0"></div>
          <div className="border-b-2 border-l-2 border-orange-500  w-4 h-4 absolute bottom-0 left-0"></div>
          <div className="border-b-2 border-r-2 border-orange-500  w-4 h-4 absolute bottom-0 right-0"></div>
          {Math.floor(time / 60)
            .toString()
            .padStart(2, "0")}
          :{(time % 60).toString().padStart(2, "0")}
        </div>
      </div>
      <div className={`grid grid-cols-5 w-2/3`}>
        {tiles.map((e, i) => (
          <Tile
            key={i}
            image={e.image}
            id={i}
            handleFlip={() => handleFlip(i)}
            flipped={
              choiceOne === i || choiceTwo === i || e.matched || !gameStarted
            }
          />
        ))}
      </div>
      <div className="flex justify-evenly w-4/5 pt-8">
        <div className="text-3xl px-10 py-2 relative">
          <div className="w-full h-full absolute border-2 border-slate-800  top-0 left-0  "></div>
          <div className="border-t-2 border-l-2 border-orange-500  w-4 h-4 absolute top-0 left-0"></div>
          <div className="border-t-2 border-r-2 border-orange-500  w-4 h-4 absolute top-0 right-0"></div>
          <div className="border-b-2 border-l-2 border-orange-500  w-4 h-4 absolute bottom-0 left-0"></div>
          <div className="border-b-2 border-r-2 border-orange-500  w-4 h-4 absolute bottom-0 right-0"></div>
          Attempts: {memoryGamesLeft}
        </div>
        <button
          onClick={handleStartGame}
          className={`bg-orange-500 hover:bg-orange-600 text-white font-bold py-4 px-16 rounded-full transition duration-300 ease-in-out ${
            gameStarted ? "opacity-50" : ""
          }`}
          disabled={gameStarted}
        >
          Play Again
        </button>
        <div className="text-3xl  px-10 py-2 relative">
          <div className="w-full h-full absolute border-2 border-slate-800  top-0 left-0 "></div>
          <div className="border-t-2 border-l-2 border-orange-500  w-4 h-4 absolute top-0 left-0"></div>
          <div className="border-t-2 border-r-2 border-orange-500  w-4 h-4 absolute top-0 right-0"></div>
          <div className="border-b-2 border-l-2 border-orange-500  w-4 h-4 absolute bottom-0 left-0"></div>
          <div className="border-b-2 border-r-2 border-orange-500  w-4 h-4 absolute bottom-0 right-0"></div>
          Score: {solved / 2}/5
        </div>
      </div>
      <div className="flex-col text-center items-center justify-center pt-8">
        <div className="flex flex-row gap-10 justify-center">
          <div className="flex flex-col">
            <div className="text-xl text-white mb-2 text-center">
              Current Memory Game Rewards
            </div>
            <div className="text-5xl font-bold bg-black bg-opacity-20 py-2 px-4 text-center mb-4 text-white">
              {currentEarnings}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text-xl text-white mb-2 text-center">
              Total Memory Game Reward
            </div>
            <div className="text-5xl font-bold bg-black bg-opacity-20 py-2 px-4 text-center mb-4 text-white">
              {totalRewards}
            </div>
          </div>
        </div>

        <button
          className={`text-3xl  w-72 py-2 relative my-2 ${
            gameStarted ? "opacity-50" : "opacity-100"
          }`}
          onClick={handleBulkSearchSubmit}
          disabled={gameStarted || isSubmitting}
        >
          <div className="w-full h-full absolute border-2 border-slate-800  top-0 left-0 "></div>
          <div className="border-t-2 border-l-2 border-orange-500  w-4 h-4 absolute top-0 left-0"></div>
          <div className="border-t-2 border-r-2 border-orange-500  w-4 h-4 absolute top-0 right-0"></div>
          <div className="border-b-2 border-l-2 border-orange-500  w-4 h-4 absolute bottom-0 left-0"></div>
          <div className="border-b-2 border-r-2 border-orange-500  w-4 h-4 absolute bottom-0 right-0"></div>
          {isSubmitting ? "Submitting..." : "SUBMIT"}
        </button>
        <div className="text-sm text-white m-2">
          Submit before leaving the page
        </div>
      </div>
      {/* <div>
        {solved === 10 ? (
          <div className="mt-8">
            <h4>
              Congratulations you solved the puzzle in{" "}
              {Math.round((time.finishTime - time.startTime) / 1000)} seconds.
            </h4>
            <button
              onClick={handleClaimReward}
              className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-10 rounded-full transition duration-300 ease-in-out"
            >
              {isSubmitting ? "Claiming..." : "Claim Reward"}
            </button>
          </div>
        ) : (
          ""
        )}
      </div> */}
    </div>
  );
};

export default Memory;
