import { useEffect, useState } from "react";
import { Wheel } from "react-custom-roulette";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import { useWallet } from "../../hooks/useWallet";
import { useAuth } from "../../hooks/useAuth";
import { toastPromise } from "../../services/util";
import FullScreenLoader from "../../components/Loader/FullScreenLoader";
import { parseEther } from "ethers/lib/utils";
import { getSignature } from "../../api/games";
import { apiCall } from "../../services/ApiCall";
import configs from "../../config/configs.json";
import axios from "axios";

const segColors = [
  "#892b00",
  "#f9b728",
  "#892b00",
  "#f9b728",
  "#892b00",
  "#f9b728",
  "#892b00",
  "#f9b728",
];

const segments = [5, 10, 25, 35, 50, 100];

const data = segments.map((segment, index) => ({
  option: segment,
  style: {
    backgroundColor: segColors[index % segColors.length],
    textColor: "black",
  },
}));

const Spin = () => {
  const [mustSpin, setMustSpin] = useState(false);
  const [spinButton, setSpinButton] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [balance, setBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [currentEarnings, setCurrentEarnings] = useState(0);
  const [totalSpins, setTotalSpins] = useState(0);
  const [spinsLeft, setSpinsLeft] = useState(0);
  const [totalRewards, setTotalRewards] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [originalrollsLeft, setOriginalrollsLeft] = useState(10);
  const [wheelSize, setWheelSize] = useState(
    Math.min(window.innerHeight, window.innerWidth)
  );

  const wallet = useWallet();
  const auth = useAuth();
  const config = wallet.getChainConfig();

  const handleSpinClick = () => {
    if (spinsLeft <= 0) {
      toast.error("No spins left");
      return;
    }

    const newPrizeNumber = Math.floor(Math.random() * segments.length);

    setPrizeNumber(newPrizeNumber);
    setSpinButton(true);
    setMustSpin(true);
  };

  const handleSpinFinished = () => {
    const prizeValue = segments[prizeNumber];
    setCurrentEarnings(currentEarnings + prizeValue);
    setTotalSpins(totalSpins + 1);
    setSpinsLeft(spinsLeft - 1);
    setSpinButton(false);
    setMustSpin(false);
  };

  const getActivityCount = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLink/getActivityCount`,
        {},
        {
          gameType: "SPINTHEWHEEL",
          date: new Date(),
        },
        "GET"
      );
      if (isSuccess) {
        const count = parseInt(data.data?.processCount.toString(), 10);
        setOriginalrollsLeft(10 - count);
        setBalance(10 - count);
        setSpinsLeft(10 - count);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const mintReward = async (amount) => {
    setIsSubmitting(true);
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}mint/erc20`,
        {},
        {
          amount: String(amount),
        },
        "POST"
      );
      setIsSubmitting(false);

      if (isSuccess) {
        getActivityCount();
        wallet.updatePoints({
          amount: amount,
          chainId: wallet.chainId,
          walletAddress: wallet.address,
          moduleName: "spin",
          txHash: "lightlink",
        });
        setTotalRewards(+totalRewards + amount);
        setCurrentEarnings(0);
        setTotalSpins(0);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateRewardCount = async () => {
    const res = await axios.get("/api/nft/all-balance");
    let multiplier = 1;
    if (res && res.multiplier > 1) {
      multiplier = res.multiplier;
    }
    const amount = Number(currentEarnings) * multiplier;

    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLinkReward/updateRewardCount`,
        {},
        {
          reward: amount,
          gameType: "SPINTHEWHEEL",
          date: new Date(),
          count: originalrollsLeft - spinsLeft,
        },
        "POST"
      );
      setIsSubmitting(false);
      if (isSuccess) {
        await mintReward(amount);
        getRewardCount();
        auth.updateTokens();
      }else{
        getActivityCount();
        setCurrentEarnings(0);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getRewardCount = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLinkReward/getRewardCount`,
        {},
        { gameType: "SPINTHEWHEEL" },
        "GET"
      );
      if (isSuccess) {
        setTotalRewards(data.data?.rewardCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateActivityCount = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLink/updateActivityCount`,
        {},
        {
          gameType: "SPINTHEWHEEL",
          date: new Date(),
          count: 10 - spinsLeft,
        },
        "POST"
      );
      if (isSuccess) {
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleBulkSpinSubmit = async () => {
    if (totalSpins === 0) {
      return toast.info("You must spin at lease once");
    }
    console.log("current Earnings", currentEarnings);
    console.log("total rewards", totalRewards);

    setIsSubmitting(true);
    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      updateRewardCount();
    } else {
      const transactionOptions = {
        value: parseEther(
          String((config.features.spinValue * totalSpins).toFixed(8))
        ),
        ...(config.features.gasLimit && { gasLimit: "900000" }),
      };

      const message = "Spin the wheel";
      const nonce = 141;

      const signature = await getSignature({
        to: wallet.address,
        amount: ethers.utils.parseEther(currentEarnings.toString()),
        message: message,
        nonce: nonce,
        chainId: wallet.chainId,
      });

      const [err, tx] = await wallet.safeCallContract({
        name: "Game",
        method: "bulkSpin",
        args: [
          totalSpins,
          ethers.utils.parseEther(currentEarnings.toString()),
          1,
          message,
          nonce,
          signature,
          transactionOptions,
        ],
      });

      if (tx) {
        await toastPromise(tx.wait(), {
          pending: "Processing...",
          success: () => {
            wallet.updatePoints({
              amount: currentEarnings,
              chainId: wallet.chainId,
              walletAddress: wallet.address,
              moduleName: "spin",
              txHash: tx.hash,
            });
            setTotalRewards(+totalRewards + currentEarnings);
            setCurrentEarnings(0);
            setTotalSpins(0);
            auth.updateTokens();
            return "Spins submitted successfully";
          },
          error: () => {
            return "Spin Failed";
          },
        });
      }

      setIsSubmitting(false);
    }
  };

  const init = async () => {
    setIsLoading(true);
    setCurrentEarnings(0);
    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      getActivityCount();
    } else {
      const [, left] = await wallet.safeCallContract({
        name: "Game",
        method: "getSpinsLeft",
        args: [wallet.address],
      });

      if (left) {
        const count = parseInt(left.toString(), 10);
        setBalance(count);
        setSpinsLeft(count);
      }

      const [, rewards] = await wallet.safeCallContract({
        name: "Game",
        method: "getSpinnerRewards",
        args: [wallet.address],
      });

      if (rewards) {
        setTotalRewards(
          Number(ethers.utils.formatUnits(rewards.toString())).toFixed(0)
        );
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!wallet.address) return;

    init();
  }, [wallet.address, wallet.chainId]);

  useEffect(() => {
    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      getActivityCount();
      getRewardCount();
    }

    function updateSize() {
      setWheelSize(Math.min(window.innerHeight, window.innerWidth));
    }

    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  if (isLoading) return <FullScreenLoader />;

  return (
    <div className='flex flex-col lg:flex-row items-center justify-center'>
      {balance ? (
        <>
          <div className='flex flex-col items-center w-full lg:w-1/2'>
            <h1 className='text-4xl font-bold mb-2 text-center text-white'>
              SPIN & WIN
            </h1>
            <div className='bg-gray-800 text-white p-4 rounded-md shadow-md'>
              <div className='text-sm text-center'>
                Refresh every 24 hrs to claim points
              </div>
            </div>

            <Wheel
              mustStartSpinning={mustSpin}
              prizeNumber={prizeNumber}
              data={data}
              onStopSpinning={handleSpinFinished}
              backgroundColors={segColors}
              textColors={["#fff"]}
              outerBorderColor={"#000"}
              outerBorderWidth={10}
              innerRadius={20}
              innerBorderColor={"#000"}
              innerBorderWidth={10}
              radiusLineWidth={3}
              radiusLineColor={"#000"}
              fontSize={30}
              perpendicularText={true}
              textDistance={75}
              width={wheelSize}
              height={wheelSize}
            />
            <div className='mt-4 flex flex-col items-center'>
              <button
                disabled={spinsLeft == 0 || spinButton}
                onClick={handleSpinClick}
                className='text-lg font-bold bg-orange-500 hover:bg-orange-600 disabled:bg-orange-300 disabled:cursor-not-allowed py-2 px-10 rounded-full transition duration-300 ease-in-out mb-2'
              >
                {spinButton ? "Spinning..." : "Spin"}
              </button>
              <div className='text-sm text-white'>Spin Left {spinsLeft}</div>
            </div>
          </div>
          <div className='flex flex-col items-center w-full lg:w-1/2 mt-8 lg:mt-0'>
            <div className='flex flex-row gap-10'>
              <div className='flex flex-col'>
                <div className='text-xl text-white mb-2 text-center'>
                  Current Spin Rewards
                </div>
                <div className='text-5xl font-bold bg-black bg-opacity-20 py-2 px-4 text-center mb-4 text-white'>
                  {currentEarnings}
                </div>
              </div>
              <div className='flex flex-col'>
                <div className='text-xl text-white mb-2 text-center'>
                  Total Spin Reward
                </div>
                <div className='text-5xl font-bold bg-black bg-opacity-20 py-2 px-4 text-center mb-4 text-white'>
                  {totalRewards}
                </div>
              </div>
            </div>

            <button
              disabled={isSubmitting}
              className='bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-10 rounded-full transition duration-300 ease-in-out'
              onClick={handleBulkSpinSubmit}
            >
              {isSubmitting ? "Submitting..." : "SUBMIT"}
            </button>
            <div className='text-sm text-white m-2'>
              Submit before leaving the page
            </div>
          </div>
        </>
      ) : (
        <h1 className='text-5xl font-extrabold text-center text-white'>
          You have reached the max spin limit for today. Come back tomorrow!
        </h1>
      )}
    </div>
  );
};

export default Spin;
