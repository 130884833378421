import { useEffect, useMemo, useState } from "react";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import FullScreenLoader from "../components/Loader/FullScreenLoader";
import { useQuery } from "@tanstack/react-query";
import { MdArrowForwardIos } from "react-icons/md";
import Button from "../components/Button";
import { getQuestions } from "../api/misc";
import { useWallet } from "../hooks/useWallet";
import { handleTransactionError, toastPromise } from "../services/util";
import { useAuth } from "../hooks/useAuth";
import { parseEther } from "ethers/lib/utils";
import { getSignature } from "../api/games";
import { apiCall } from "../services/ApiCall";
import configs from "../config/configs.json";

const Questions = () => {
  const [answers, setAnswers] = useState([]);
  const [isCompleted, setIsCompleted] = useState(false);
  const [tokens, setTokens] = useState(0);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [optionIndex, setOptionIndex] = useState(null);
  const [isFetchingContract, setIsFetchingContract] = useState(false);
  const [isSubmittng, setIsSubmitting] = useState(false);
  const [count, setCount] = useState(false);
  const wallet = useWallet();
  const auth = useAuth();

  const questions = useQuery({
    queryKey: ["questions", wallet.chainId],
    queryFn: () => getQuestions(wallet.chainId),
    enabled: !!wallet.chainId,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    setAnswers([]);
    setQuestionIndex(0);
    setOptionIndex(null);
  }, [wallet.chainId]);

  const question = useMemo(() => {
    return questions.data?.[questionIndex];
  }, [questionIndex, questions.data]);

  const submitAnswers = async (answers) => {
    const config = wallet.getChainConfig();
    if (!config) return;

    const message = "Quiz";
    const nonce = 147;

    const signature = await getSignature({
      to: wallet.address,
      amount: ethers.utils.parseEther("0"),
      message: message,
      nonce: nonce,
      chainId: wallet.chainId,
    });

    const rightAnswerCount = answers.filter((item) => item.isRight).length;
    const multiplier = localStorage.getItem("multiplier") || 1;

    const transactionOptions = {
      value: parseEther(String(config.features.spinValue * 10)),
      ...(config.features.gasLimit && { gasLimit: "900000" }),
    };

    const [err, tx] = await wallet.safeCallContract({
      name: "Game",
      method: "bulkAnswer",
      args: [
        rightAnswerCount,
        multiplier,
        message,
        nonce,
        signature,
        transactionOptions,
      ],
    });

    if (err) return;

    await toastPromise(tx.wait(), {
      pending: "Submitting...",
      success: () => {
        const tokens = rightAnswerCount * 50 * multiplier;
        setIsCompleted(true);
        setTokens(tokens);
        wallet.updatePoints({
          amount: tokens,
          chainId: wallet.chainId,
          walletAddress: wallet.address,
          moduleName: "questions",
          txHash: tx.hash,
        });
        auth.updateTokens();
        return `Successfully added ${tokens} tokens`;
      },
      error: (err) => {
        handleTransactionError(err);
      },
    });
  };

  const handleNextQuestion = async () => {
    if (optionIndex == null) {
      return toast.error("Please choose an option");
    }
    const option = question.options[optionIndex];

    const finalAnswers = answers
      .filter((item) => item.questionId !== question._id)
      .concat([
        {
          questionId: question._id,
          optionId: option._id,
          isRight: !!option.blockchainNumber,
        },
      ]);

    setAnswers(finalAnswers);

    if (isLastQuestion) {
      setIsSubmitting(true);
      try {
        if (wallet.chainId === 1890 || wallet.chainId === 1891) {
          updateRewardCount(finalAnswers);
        } else {
          await submitAnswers(finalAnswers);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setQuestionIndex((index) => index + 1);
      setOptionIndex(null);
    }
  };

  const isLastQuestion = questions.data?.length === questionIndex + 1;
  const getActivityCount = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLink/getActivityCount`,
        {},
        {
          gameType: "QUIZQUESTION",
          date: new Date(),
        },
        "GET"
      );
      if (isSuccess) {
        const count = parseInt(data.data?.processCount.toString(), 10);
        setCount(count)
        setIsCompleted(count === 1 ? true : false);

      }
    } catch (error) {
      console.error(error);
    }
  };

  const mintReward = async (tokens) => {
    // const rightAnswerCount = answers.filter((item) => item.isRight).length;
    // const multiplier = localStorage.getItem("multiplier") || 1;
    // const tokens = rightAnswerCount * 50 * multiplier;
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}mint/erc20`,
        {},
        {
          amount: String(tokens),
        },
        "POST"
      );
      setIsSubmitting(false);

      if (isSuccess) {
        // updateRewardCount(tokens);
        // updateActivityCount();
        setIsCompleted(true);
        setTokens(tokens);
        wallet.updatePoints({
          amount: tokens,
          chainId: wallet.chainId,
          walletAddress: wallet.address,
          moduleName: "questions",
          txHash: "mintReward",
        });
        getActivityCount();
        auth.updateTokens();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateRewardCount = async (answers) => {
    const rightAnswerCount = answers.filter((item) => item.isRight).length;
    const multiplier = localStorage.getItem("multiplier") || 1;
    const tokens = rightAnswerCount * 50 * multiplier;
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLinkReward/updateRewardCount`,
        {},
        {
          reward: tokens,
          gameType: "QUIZQUESTION",
          date: new Date(),
          count: 1,
        },
        "POST"
      );
      setIsSubmitting(false);
      if (isSuccess) {
        await mintReward(tokens)
        getRewardCount();
        auth.updateTokens();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getRewardCount = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLinkReward/getRewardCount`,
        {},
        { gameType: "QUIZQUESTION" },
        "GET"
      );
      if (isSuccess) {
        setTokens(data.data?.rewardCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateActivityCount = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLink/updateActivityCount`,
        {},
        {
          gameType: "QUIZQUESTION",
          date: new Date(),
          count: 1,
        },
        "POST"
      );
      if (isSuccess) {
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (!wallet.address) return;

    const init = async () => {
      if (wallet.chainId === 1890 || wallet.chainId === 1891) {
        getActivityCount();
      } else {
        setIsFetchingContract(true);

        const [, isCompleted] = await wallet.safeCallContract({
          name: "Game",
          method: "hasUserAnsweredToday",
          args: [wallet.address],
        });

        const [, tokens] = await wallet.safeCallContract({
          name: "Game",
          method: "getAnswerRewards",
          args: [wallet.address],
        });

        if (isCompleted) {
          setIsCompleted(true);
        }

        if (tokens) {
          setTokens(
            Number(
              ethers.utils.formatUnits(tokens.toString()).toString()
            ).toFixed(0)
          );
        }
      }
    };
    init()
      .catch(console.error)
      .finally(() => {
        setIsFetchingContract(false);
      });
  }, [wallet.address, wallet.chainId]);

  useEffect(() => {
    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      getActivityCount();
      getRewardCount();
    }
  }, [wallet.address, wallet.chainId]);


  const QuestionComponent = () => (
    <div className=" h-[500px] w-full mt-16 px-10">
      <div className="my-8  relative flex justify-between">
        {/* <hr className="w-full border border-[#ff3503] absolute lg:top-3 sm:top-2 top-[6px] left-0 z-10" /> */}
        {/* {[...Array(questionQuery.data.length)].map((_, i) => {
          return (
            <span
              key={i}
              className={`lg:border-2 border-black block lg:h-6 lg:w-6 sm:h-4 sm:w-4 h-3 w-3 rounded-full ${
                null ? 'bg-[#3df700]' : 'bg-[#ff3503]'
              } z-50`}
            ></span>
          );
        })} */}
      </div>

      <div className="flex flex-row justify-between items-center">
        <div className="text-xl  uppercase">
          Question No. {questionIndex + 1}/{questions.data.length}
        </div>
        {isSubmittng ? (
          <Button className="w-[100px]" isLoading={true}></Button>
        ) : (
          <div
            onClick={handleNextQuestion}
            className="text-xl  uppercase border-[#FF3503] border-2 rounded-full px-3 py-1 cursor-pointer flex flex-row justify-center items-center gap-2"
          >
            <div>{isLastQuestion ? "Submit" : "Next Question"}</div>
            {!isLastQuestion && <MdArrowForwardIos />}
          </div>
        )}
      </div>
      <div className="mt-4 border-[#FF3503] border-2 rounded-3xl p-6">
        <div className="text-2xl font-bold">{question.questionText}</div>
        <div className="mt-8 grid grid-cols-2 gap-5 ">
          {question.options.map((option, index) => (
            <div
              onClick={() => setOptionIndex(index)}
              key={index}
              className={`relative sm:ml-10 ml-5 mb-5 flex items-start text-left min-h-[50px] border border-[#474444] w-[90%] p-4 cursor-pointer ${
                optionIndex === index ? "bg-[#ff3503]" : ""
              }`}
            >
              <span
                className={`absolute top-1/2 transform translate-y-[-50%] md:-ml-16 -ml-12 flex-shrink-0 bg-[#FF3503] text-white rounded-full w-6 h-6 flex items-center justify-center mr-2 ${
                  optionIndex === index ? "bg-[#26ff03]" : "bg-[#ff3503]"
                }`}
              ></span>
              <span className="flex-grow">{option.text}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-6 flex justify-center items-center text-lg">
        Do not refresh or go back on the page, as your saved answers will be
        lost.
      </div>
    </div>
  );

  return (
    <div>
      {questions.isLoading ? (
        <FullScreenLoader />
      ) : (
        <div className="">
          <div className="flex flex-row justify-between">
            <h1 className=" uppercase font-bold" style={{ fontSize: "2rem" }}>
              Quiz Questions
            </h1>
            <div className="text-lg">Rewards Earned: {tokens}</div>
          </div>
          {isFetchingContract ? (
            <FullScreenLoader />
          ) : isCompleted ? (
            <div className="flex justify-center items-center text-lg h-[60vh]">
              Today&apos;s questions have already been answered. Please come
              back tomorrow.
            </div>
          ) : question ? (
            <QuestionComponent />
          ) : (
            <div className="flex justify-center items-center text-lg h-[60vh]">
              No questions found
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Questions;
