import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

import sun from "../assets/3Dsun.png";
import memory from "../assets/memoryGameCarousel.png";
import s from "../assets/s.png";
import poly from "../assets/poly (2).png";
import shib from "../assets/shib.png";
import btc from "../assets/btc.png";
import uni from "../assets/uni.png";
import spin from "../assets/spin.jpg";
import jackpot from "../assets/jackpot.jpg";
import slidingPuzzle from "../assets/slidingPuzzle.jpg";
import wordSearch from "../assets/wordSearch.jpg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/navigation";

import "../assets/css/gamingmarketplace.css";

// import required modules
import { EffectCards, Navigation } from "swiper/modules";

const CarouselCard = ({ text, subtext, image, to }) => {
  return (
    <div className='w-96 h-96 border-2 border-orange-500 rounded-3xl flex-col justify-center items-center bg-black/50 p-4 relative'>
      <div className='text-3xl font-semibold  z-20'>{text}</div>
      <div className='text-sm mb-4  z-20'>{subtext}</div>
      <div className='flex justify-center  z-20'>
        <img src={image} className='h-48' />
      </div>
      <div className='text-center mt-8  z-20'>
        <Link
          className={`bg-orange-500 hover:bg-orange-600 text-white font-bold py-4 px-16 rounded-full transition duration-300 ease-in-out`}
          to={to}
        >
          Play Now
        </Link>
      </div>
    </div>
  );
};

const GamingMarketplace = () => {
  const swiper = useSwiper();
  const games = [
    {
      image: memory,
      text: "Memory Matching",
      subtext: "Sharpens you memory with the matching game.",
      link: "/games/memory",
    },
    {
      image: spin,
      text: "Spin The Wheel",
      subtext: "Sharpens you memory with the matching game.",
      link: "/games/spin",
    },
    {
      image: wordSearch,
      text: "Word Search",
      subtext: "Sharpens you memory with the matching game.",
      link: "/games/word-search",
    },
    {
      image: slidingPuzzle,
      text: "Sliding Puzzle",
      subtext: "Sharpens you memory with the matching game.",
      link: "/games/sliding-puzzle",
    },
    {
      image: jackpot,
      text: "Jackpot",
      subtext: "Sharpens you memory with the matching game.",
      link: "/games/slot-machine",
    },
  ];
  return (
    <div className='w-full h-full top-0 left-0 absolute overflow-hidden z-10'>
      <div className='w-full h-full absolte top-0 left-0'>
        <img src={sun} className='w-full h-auto  opacity-20' />
        <img src={s} className='absolute top-4 right-0' />
        <img src={uni} className='absolute top-4 left-8' />
        <img src={btc} className='absolute top-80 left-4' />
        <img src={poly} className='absolute top-40 left-60' />
        <img src={shib} className='absolute top-60 right-8' />
      </div>
      <div className='w-full h-full absolute top-0 left-0 flex-col items-center justify-center -z-1'>
        <div className='text-5xl font-semibold mt-32 text-center'>
          Gaming Marketplace
        </div>
        <div className='flex justify-center items-center w-full absolute top-1/2'>
          <Swiper
            effect={"cards"}
            grabCursor={true}
            initialSlide={2}
            navigation={true}
            cardsEffect={{
              perSlideOffset: 20,
              slideShadows: false,
              perSlideRotate: 5,
            }}
            modules={[EffectCards, Navigation]}
          >
            {games.map((e, i) => (
              <SwiperSlide key={i}>
                <CarouselCard
                  text={e.text}
                  subtext={e.subtext}
                  image={e.image}
                  to={e.link}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default GamingMarketplace;
