import axios from "axios";
import { toast } from "react-toastify";

export const updateProfile = (data) => {
  return axios.post("/api/user/profile", data);
};

export const checkUsername = async (username) => {
  return axios.get(`/api/auth/check-username?username=${username}`);
};

export const getBalance = async (address) => {
  return axios
    .post("/api/user/all-balance", {
      address: address,
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.error(err);

      // toast.error("Error occurred while fetching user balance");
    });
};
