import axios from "axios";

export const getSignature = async (payload) => {
  return axios
    .post("/api/game/getsig", {
      to: payload.to,
      amount: payload.amount,
      message: payload.message,
      nonce: payload.nonce,
      chainId: payload.chainId,
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.error(err);
      if (payload.showToast) {
        toast.error("Error occurred while fetching signature");
      }
    });
};
