import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from '../hooks/useAuth';
import EntrypassModel from '../components/EntrypassModel';
import Overlay from '../components/Overlay';
import { useWeb3Modal } from '@web3modal/ethers5/react';
import { useWallet } from '../hooks/useWallet';
import { NFTRequiredError } from '../services/error';
import maskImg from '../assets/mask.png';
import blazTaikoImg from '../assets/blaztaikomask.png';
import leaderboardImg from '../assets/leaderboardmask.png';
import {
  LoginBtn,
  UnlockRewardCardContainer,
  UnlockRewardArrow,
  SquareCard,
  LeaderBoardRewardCard,
  EntrypasssSystemRewardCard,
  PlayandEarnRewardCard,
  BlazpointsRewardCard,
  StakingPlatformRewardCard,
  QuizRewardCard,
} from '../components/SvgContainer';

const chainId = 1;

const Home = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showEntrypass, setShowEntrypass] = useState(false);
  const [effectTriggeredBy, setEffectTriggeredBy] = useState(null);
  const auth = useAuth();
  const { isConnected, address } = useWallet();

  const { open } = useWeb3Modal();

  const signMessage = async () => {
    try {
      setIsLoading(true);

      await auth.login({ address, chainId });
    } catch (err) {
      if (err instanceof NFTRequiredError) {
        setShowEntrypass(true);
      } else if (err.code === 4001) {
        toast.error('You have rejected the transaction');
      } else if (err.request || err.response) {
        console.error(err);
        toast.error('API server is down, please try again later');
      } else {
        console.error(err);
        toast.error('An unexpected error occurred, please contact admin');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = () => {
    if (isLoading) return;
    if (isConnected) {
      signMessage();
    } else {
      setEffectTriggeredBy('connect');
      open({ view: 'Connect' });
    }
  };

  useEffect(() => {
    if (effectTriggeredBy === null || !isConnected) return;
    signMessage();
  }, [isConnected, effectTriggeredBy]);

  return (
    <section className="min-h-screen w-full  unlockreward-container text-white">
      {/* Top-section */}
      <div className="md:h-[550px] sm:h-[360px] h-[640px] 2xl:w-[1536px] xl:w-[1240px] lg:w-[980px] md:w-[740px] sm:w-[560px] w-full mx-auto relative lg:top-6 md:-top-12 -top-20">
        <UnlockRewardCardContainer className="md:block hidden w-full transform left-1/2 translate-x-[-50%] 2xl:scale-y-[0.90] scale-y-[1.04] bottom-5 absolute" />
        {/* Text Section */}
        <div className="flex flex-col sm:items-start items-center">
          <h1
            className="xl:text-7xl lg:text-5xl text-3xl  font-extrabold 
              sm:absolute relative   lg:top-1/2 top-48  2xl:left-32 lg:left-16 md:left-10 transform leading-[1.25]
              lg:translate-y-[-70%] lg:w-[680px] md:w-72 w-64 sm:text-left text-center"
          >
            Unlock Rewards with Blazpay
            <UnlockRewardArrow className="xl:h-fit xl:w-fit lg:h-12 lg:w-12 md:h-6 md:w-6 h-4 w-4 inline lg:ml-6 ml-2 cursor-pointer" />
            <SquareCard className="inline xl:h-20 xl:w-fit lg:h-12 lg:w-12 md:h-6 md:w-6 h-4 w-4 lg:ml-4 ml-2 md:mb-2 cursor-pointer" />
          </h1>
          <p
            className="sm:absolute relative 2xl:top-96 lg:top-[350px] sm:top-72 top-56 2xl:w-[660px] md:w-[400px] w-72 xl:text-2xl
         md:text-xl text-base 2xl:left-32 lg:left-16 md:left-10 sm:text-left text-center"
          >
            Participate in Weekly USD Contests and win Blazpoints too on Our
            Dashboard
          </p>
        </div>
        <div className="md:block hidden absolute xl:bottom-[104px] lg:bottom-32 bottom-[155px] 2xl:right-[376px] xl:right-[283px] lg:right-56 right-[186px] z-10 xl:h-[440px] lg:h-[362px] h-72 xl:w-[186px] lg:w-[150px] w-24 rounded-xl bg-gradient-to-b from-[#FF7C03] to-[#FF3503]"></div>
        <img
          src={maskImg}
          alt="Mask"
          className="absolute sm:h-full h-[360px] sm:w-fit w-[300px] 2xl:right-[196px] xl:right-[100px] lg:right-[30px] md:-right-10 sm:-right-5 right-1/2 xl:bottom-4 lg:bottom-[20px] md:bottom-4 sm:-bottom-20 -bottom-5  sm:translate-x-0  translate-x-[30%] transform xl:scale-[.88] lg:scale-[.71] scale-[.52] z-40"
        />
        <section
          className="z-50 absolute xl:bottom-11 
      lg:bottom-[84px] md:bottom-[124px] sm:-bottom-6 bottom-9 transform md:scale-y-[.92] 2xl:scale-x-[1] 
      xl:scale-x-[.84]   2xl:right-12 xl:-right-6 sm:right-0 right-1/2 sm:translate-x-0 translate-x-[75%]"
        >
          <button disabled={isLoading} onClick={handleSubmit}>
            <h1
              className="pointer-events-none select-none absolute lg:text-1xl md:text-xl sm:text-base text-[10px]
         sm:font-semibold font-medium top-1/2 left-1/2 transform translate-x-[-50%]
          translate-y-[-50%]"
            >
              {isLoading
                ? 'Loading...'
                : isConnected
                ? 'Sign In'
                : 'Connect Wallet'}
            </h1>
            <LoginBtn className="xl:w-fit lg:w-56 md:w-40 w-28 " />
          </button>
        </section>
      </div>

      {/* Bottom section */}
      <div
        className="flex lg:justify-between justify-center lg:flex-nowrap flex-wrap 2xl:gap-x-6 
    lg:gap-x-0 gap-x-6 lg:items-center 2xl:w-[1536px] xl:w-[1240px] lg:w-[980px] sm:w-[600px] w-[360px] mx-auto lg:h-[350px] sm:h-[560px] h-[400px]
      xl:-mt-0 lg:-mt-12 md:-mt-36 sm:-mt-10 -mt-24 2xl:px-10"
      >
        <section className="relative  xl:-top-0  sm:-top-5 -top-32 xl:w-[306px] xl:h-[330px] sm:w-[250px] w-[160px] h-[260px] lg:order-1 order-3">
          <img
            src={leaderboardImg}
            alt="Leaderboardmask"
            className="transition sm:scale-100 scale-50 duration-300 ease-linear sm:hover:scale-110 hover:scale-[.66] absolute left-1/2 transform translate-x-[-50%] xl:top-10 top-5"
          />
          <h1 className="absolute xl:bottom-[34px] sm:bottom-7 bottom-[60px] sm:left-[70px] left-10 uppercase xl:text-2xl sm:text-base text-xs transition duration-500 ease-in-out active:scale-[.80] select-none">
            Leaderboard
          </h1>
          <LeaderBoardRewardCard className="xl:w-[306px] xl:h-[330px] sm:w-[250px] w-[160px] h-[260px]" />
        </section>

        <section
          className="xl:h-full h-64 flex flex-col 2xl:pl-8 2xl:gap-y-3 xl:gap-y-0 lg:order-2 order-1
       gap-y-3 justify-start xl:mt-5 lg:-mt-5 items-center 2xl:w-full  xl:w-[280px] lg:w-56 sm:w-[250px] w-[160px]"
        >
          <div className="relative">
            <h1 className="absolute xl:top-12 sm:top-9 top-5 left-1/2 transform translate-x-[-55%] 2xl:text-xl xl:text-base sm:text-xs text-[10px] uppercase transition duration-500 ease-in-out active:scale-[.80] select-none">
              play & Earn
            </h1>
            <PlayandEarnRewardCard className="w-full h-fit" />
          </div>

          <div className="relative">
            <h1 className="absolute xl:top-[68px] sm:top-12 top-8  sm:left-9 left-4 2xl:text-xl xl:text-base sm:text-xs text-[10px] uppercase transition duration-500 ease-in-out active:scale-[.80] select-none">
              blazpoints as rewards
            </h1>
            <BlazpointsRewardCard className="w-full h-fit" />
          </div>
        </section>

        <section
          className="xl:h-full h-64 flex flex-col 2xl:pl-8 2xl:gap-y-3  lg:order-3 order-2 
      xl:gap-y-0 gap-y-3 justify-start xl:mt-5 lg:-mt-5 items-center 2xl:w-full  xl:w-[280px] lg:w-56 sm:w-[250px] w-[160px]"
        >
          <div className="relative">
            <h1 className="absolute  xl:top-12 sm:top-9 top-6 xl:left-[68px] sm:left-[58px] left-8 transform 2xl:text-xl xl:text-base sm:text-xs text-[10px] uppercase transition duration-500 ease-in-out active:scale-[.80] select-none">
              Staking Platform
            </h1>
            <StakingPlatformRewardCard className="w-full h-fit" />
          </div>

          <div className="relative">
            <h1 className="absolute xl:top-16 sm:top-12 top-8 left-1/2 transform translate-x-[-35%] 2xl:text-xl xl:text-base sm:text-xs text-[10px] uppercase transition duration-500 ease-in-out active:scale-[.80] select-none">
              Quiz
            </h1>
            <QuizRewardCard className="w-full h-fit" />
          </div>
        </section>

        <section className="relative xl:-top-0 sm:-top-5 -top-32 xl:w-[306px] xl:h-[330px] sm:w-[250px] w-[160px] h-[260px]  lg:order-4 order-4">
          <img
            src={blazTaikoImg}
            alt="Blaztaikomask"
            className="transition sm:scale-100 scale-50 duration-300 ease-linear sm:hover:scale-110 hover:scale-[.66] absolute left-1/2 transform translate-x-[-50%] xl:top-10 sm:top-5"
          />
          <h1 className="absolute bottom-[54px] left-[56px] uppercase xl:text-xl text-xs transition duration-500 ease-in-out active:scale-[.80] select-none">
            Entrypass System
          </h1>
          <EntrypasssSystemRewardCard className=" xl:w-[306px] xl:h-[330px] sm:w-[250px] w-[160px] h-[260px]" />
        </section>
      </div>
      {showEntrypass && (
        <Overlay onClose={() => setShowEntrypass(false)}>
          <EntrypassModel />
        </Overlay>
      )}
    </section>

    // <div
    //   style={{
    //     backgroundImage: `url(${bgImage})`,
    //     backgroundSize: 'cover',
    //     backgroundPosition: 'center',
    //     color: 'black',
    //   }}
    //   className="flex items-center justify-center h-screen bg-gray-100"
    // >
    //   <div
    //     style={{ border: '1px solid #FF5500', backgroundColor: '#D9D9D933' }}
    //     className="md:w-1/3 w-5/6 min-w-[350px] p-4 md:p-5 rounded-lg shadow-md"
    //   >
    //     <div className="rounded-lg" style={{ backgroundColor: '#171717' }}>
    //       <div className="flex justify-center p-4 bg-black rounded-tl-lg rounded-tr-lg">
    //         <img src={logo} className="w-40 h-10 rounded-lg " alt="Logo" />
    //       </div>
    //       <div className="p-4">
    //         <div className="flex flex-col items-center">
    //           <Button
    //             name={isConnected ? 'Sign' : 'Connect Wallet'}
    //             onClick={handleSubmit}
    //             isLoading={isLoading}
    //             className="text-md font-bold  rounded-3xl min-w-[40%] py-1 px-5 hover:bg-gradient uppercase "
    //             style={{
    //               background:
    //                 'linear-gradient(90deg, #FF3503 0%, #FF6E01 53.15%, #FFA100 100%)',
    //               color: 'white',
    //             }}
    //           />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   {showEntrypass && (
    //     <Overlay onClose={() => setShowEntrypass(false)}>
    //       <EntrypassModel />
    //     </Overlay>
    //   )}
    // </div>
  );
};

export default Home;
