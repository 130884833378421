import React, { useEffect, useMemo, useState } from "react";
import { HiOutlinePencil } from "react-icons/hi";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import axios from "axios";
import { IoCloseCircleOutline } from "react-icons/io5";
import clsx from "clsx";

import { useQuery } from "@tanstack/react-query";

function getSliceToken(token) {
  return token.slice(6) + "......." + token.slice(-6);
}

import {
  getDownloadURL,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";

import { storage } from "../../firebase";
import Modal from "../../components/Modal";
import { getChains, getTokens } from "../../services/util";
import Address from "./Address";

const ProfileSettings = () => {
  const auth = useAuth();

  const [profileInfo, setProfileInfo] = useState({
    bio: auth?.user?.bio ?? "",
    email: auth?.user?.email ?? "",
  });

  const [profileImage, setProfileImage] = useState(auth?.user?.img_url ?? "");

  const [defaultWallet, setDefaultWallet] = useState(
    auth?.user?.wallets?.find((item) => item.isPrimary)
  );

  const [allAddress, setAllAddress] = useState(
    auth?.user?.wallets?.filter((item) => !item.isPrimary)
  );

  const [editImage, setEditImage] = useState(false);

  const [open, setOpen] = useState(false);

  const [isEditProfile, setIsEditProfile] = useState(false);

  const [selectedChain, setSelectedChain] = useState({});

  const [selectedToken, setSelectedToken] = useState("");

  const [walletAddress, setWalletAddress] = useState("");

  const profileData = auth.user;

  const handleProfileInfo = async () => {
    let data = {};

    if (profileInfo.bio && profileInfo.bio.length < 4) {
      toast.error("Please enter min 4 characters!");
      return;
    }

    if (profileInfo.bio) {
      data.bio = profileInfo.bio;
    }

    if (profileInfo.email) {
      profileInfo.email;
    }

    try {
      await axios.patch("/api/user/profile", data);

      setIsEditProfile(false);
      toast.success("Successfully updated ");
    } catch (err) {
      console.error("Error updating :", err);
      toast.error("Error updating .");
    }
  };

  const handleProfileUpdate = async (e) => {
    const file = e.target?.files[0];

    const imageRef = storageRef(storage, `profile/${auth?.user?.username}`);

    uploadBytes(imageRef, file)
      .then((snapshot) => {
        getDownloadURL(snapshot.ref)
          .then(async (url) => {
            setProfileImage(url);
            await uploadImage(url);
            setEditImage(false);
          })
          .catch((error) => {
            toast.error(error?.message);
          });
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  const handleOpen = () => {
    if (defaultWallet?.chainId) {
      const findChain = allChains.data?.find(
        (chain) => chain.chainId == defaultWallet.chainId
      );

      if (findChain) {
        setSelectedChain(findChain);

        const token = findChain?.tokens.filter(
          (token) => token.tokenName == defaultWallet?.tokenName
        );

        setSelectedToken(token);

        setWalletAddress(defaultWallet?.walletAddress ?? "");
      }
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const uploadImage = async (img_url = "") => {
    try {
      await axios.patch("/api/user/profile", { img_url });

      setIsEditProfile(false);
      toast.success("Successfully updated");
    } catch (err) {
      console.error("Error updating :", err);
      toast.error("Error updating .");
    }
  };

  const allChains = useQuery({
    queryKey: ["chains"],
    queryFn: () => getChains(),
  });

  const handleClickChain = (chain) => {
    setSelectedChain(chain);
  };

  const handleAddressUpdate = async () => {
    if (!walletAddress) return toast.error("enter wallet address");
    let values = {
      chainId: selectedChain?.chainId,
      chainName: selectedChain?.chainName,
      tokenAddress: selectedToken?.address,
      tokenName: selectedToken?.tokenName,
      walletAddress,
      isPrimary: true,
    };

    try {
      await axios.patch("/api/user/profile", { wallets: [values] });

      setDefaultWallet({ ...defaultWallet, ...values });
      handleClose();
      toast.success("Successfully updated");
    } catch (err) {
      console.error("Error updating :", err);
      toast.error("Error updating .");
    }
  };

  function getChainImage(chainId) {
    const chain = allChains.data?.find((item) => item.chainId == chainId);

    if (!chain) {
      return "";
    }

    return chain.img;
  }

  function getWalletImage(chainId, tokenName) {
    const chain = allChains.data?.find((item) => item.chainId == chainId);

    if (chain) {
      const token = chain.tokens.find((item) => item.tokenName === tokenName);

      if (!token) return "";

      return token.img;
    }
  }

  return (
    <div
      className="min-h-screen w-full flex justify-center profile-container text-white
      sm:px-10 px-5  pt-20  pb-10"
    >
      <div
        className="relative xl:w-[1100px] md:w-[640px] sm:w-[520px] w-full  xl:p-20 sm:px-10 px-5  sm:pt-10 pt-6 border border-[#d24804] 
        rounded-3xl bg-[rgba(0,0,0,.61)] h-fit p-5"
        style={{ backdropFilter: "blur(28px)" }}
      >
        {/* Edit Image Popup */}

        <div
          className={`${
            !editImage && "hidden"
          } absolute xl:top-56 top:top-36 top-32 xl:left-12 sm:left-5 left-1/2 transform sm:translate-x-0 translate-x-[-50%]  flex flex-col w-64 h-40
         rounded-3xl bg-[rgba(66,66,66,1)] z-50 px-8 pt-16 gap-y-2`}
        >
          <RxCross2
            className="text-2xl font-extrabold absolute right-7 top-5  cursor-pointer"
            onClick={() => setEditImage(false)}
          />
          <div
            className="flex justify-between w-full"
            onClick={() => {
              /handle update image/;
            }}
          >
            <span className="text-md font-medium cursor-pointer ml-2">
              Choose a file
            </span>
            <label
              htmlFor="fileInput"
              className="block text-2xl cursor-pointer hover:text-xl duration-300 ease-in-out"
            >
              <HiOutlinePencil />
            </label>
            <input
              id="fileInput"
              type="file"
              className="hidden"
              onChange={handleProfileUpdate}
            />
          </div>

          <div
            className="flex justify-between w-full"
            onClick={() => {
              /handle update image/;
            }}
          >
            <h1 className="text-md font-medium cursor-pointer">Remove Image</h1>
            <HiOutlinePencil className="block text-2xl cursor-pointer hover:text-xl  duration-300 ease-in-out" />
          </div>
        </div>

        {/* User Info*/}
        <div className="flex md:flex-row flex-col md:justify-start justify-center xl:gap-x-10 gap-x-5 ">
          {/* top-left */}
          <div className="flex flex-col items-center">
            <div className="relative">
              <img
                src={profileImage}
                className="xl:h-36 h-24 xl:w-36 w-24  rounded-full border"
              />

              {/* update icon container */}
              <div
                onClick={() => setEditImage(true)}
                className="absolute bottom-2 right-1 h-7 w-7 rounded-full
                 bg-[#ff5500] flex justify-center items-center cursor-pointer"
              >
                <HiOutlinePencil className="inline" />
              </div>
            </div>
          </div>
          {/* top-right */}
          <div className="flex flex-col  gap-y-5 py-5 xl:w-[560px] xl:w-92  w-full relative">
            {/* Field-1 */}

            <button
              onClick={() => {
                isEditProfile ? handleProfileInfo() : setIsEditProfile(true);
              }}
              className="absolute right-0 top-[-5px] px-2 py-1 border flex justify-center items-center gap-x-3  text-nowrap sm:text-[12px] text-xs
               border-[#ff5500] font-bold rounded-full"
            >
              {isEditProfile ? "Save" : "Edit Profile"}{" "}
              {!isEditProfile && (
                <HiOutlinePencil className="inline xl:text-xl text-base" />
              )}
            </button>
            <div className="flex justify-between  gap-x-10">
              <label
                htmlFor="user-name"
                className="xl:text-2xl sm:text-xl text-base text-nowrap"
              >
                User Name
              </label>
              <input
                disabled
                id="user-name"
                type="text"
                value={profileData?.username}
                className="xl:w-96 md:w-64 sm:w-72 w-full bg-transparent outline-none border-b border-[#ff5501] pl-0 pr-6"
              />
            </div>
            {/* Field-2 */}
            <div className="flex justify-between  gap-x-10 ">
              <label
                htmlFor="user-email"
                className="xl:text-2xl sm:text-xl text-base"
              >
                Email
              </label>
              <input
                disabled={!isEditProfile}
                id="user-email"
                type="email"
                value={profileInfo?.email}
                onChange={(e) =>
                  setProfileInfo({ ...profileInfo, email: e.target.value })
                }
                className="xl:w-96 md:w-64 sm:w-72 w-full bg-transparent outline-none border-b border-[#ff5501] pl-0 pr-6"
              />
            </div>
            <div className="relative">
              <textarea
                disabled={!isEditProfile}
                onChange={(e) =>
                  setProfileInfo({ ...profileInfo, bio: e.target.value })
                }
                placeholder="Add Bio..."
                value={profileInfo.bio}
                className="border rounded-full  w-full xl:h-[56px] h-10  px-5 xl:pt-4 pt-2 xl:pb-3 bg-transparent outline-none 
             resize-none"
              ></textarea>
            </div>
          </div>
        </div>

        {/* Chain token and address */}
        <div className="xl:mt-8 sm:mt-3 mt-6">
          {/* Default chain and token */}
          <label
            htmlFor="default-chain-token"
            className="xl:m-2 m-3 xl:mb-2 mb-0"
          >
            Default Chain & Token
          </label>

          <div>
            <div
              key={defaultWallet?.tokenAddress}
              className="flex items-center gap-4 border rounded-xl py-2 px-3 max-sm:px-1 relative w-fit"
            >
              <div className="flex relative">
                <img
                  src={getChainImage(defaultWallet?.chainId)}
                  alt="pol"
                  className="h-7 w-7 rounded-full"
                />
                <img
                  src={getWalletImage(
                    defaultWallet?.chainId,
                    defaultWallet?.tokenName
                  )}
                  alt="taiko"
                  className="h-7 w-7 rounded-full absolute left-4"
                />
              </div>

              <input
                onChange={(e) =>
                  setDefaultWallet({
                    ...defaultWallet,
                    walletAddress: e.target.value,
                  })
                }
                className="bg-transparent outline-none max-sm:text-md "
                placeholder="Wallet Address"
                value={defaultWallet?.walletAddress}
              />

              <div className="absolute right-2">
                <HiOutlinePencil
                  className="h-4 w-4 cursor-pointer transform hover:scale-110"
                  onClick={handleOpen}
                />
              </div>
            </div>
          </div>

          {/* Addresses */}

          <div className="w-fit">
            <label htmlFor="address" className="xl:ml-2 ml-3 xl:my-3 mt-3 mb-0">
              Your Addresses
            </label>
            <Address allAddress={allAddress} chains={allChains.data ?? []} />
          </div>
        </div>
      </div>

      <Modal open={open} onClose={handleClose}>
        <div>
          <div>
            <div className="relative text-white ">
              <IoCloseCircleOutline
                className="h-4 w-4 cursor-pointer absolute right-5"
                onClick={handleClose}
              />
              <div className="mb-8">
                <h4 className="text-center font-semibold text-xl">
                  Token List
                </h4>
              </div>
              <div className="flex gap-3 max-sm:flex-col">
                <div className="grid grid-cols-3 chain-list w-[240px]  gap-0 flex-wrap max-h-96 overflow-auto">
                  {allChains.data?.map((chain, index) => (
                    <div
                      key={`${chain.chainId}+${index}`}
                      onClick={() => {
                        handleClickChain(chain);
                      }}
                      className={clsx(
                        "flex flex-col justify-center items-center p-2 rounded-xl cursor-pointer h-16",
                        selectedChain?.chainId == chain.chainId && "bg-gray-500"
                      )}
                    >
                      <img
                        src={chain.img}
                        className="h-8 w-8 rounded-full"
                        alt="bnb"
                      />
                      <p className="text-xs">{chain.chainName}</p>
                    </div>
                  ))}
                </div>
                <div className="token-list min-w-[342px] flex-1 max-h-96 overflow-auto">
                  {selectedChain?.tokens ? (
                    selectedChain?.tokens.map((token) => (
                      <div
                        key={token.address}
                        onClick={() => setSelectedToken(token)}
                        className={clsx(
                          "border-b border-b-primary px-3 py-2  min-w-[340px] flex justify-between cursor-pointer hover:bg-gray-500",
                          selectedToken?.tokenName == token?.tokenName &&
                            "bg-gray-500"
                        )}
                      >
                        <div className=" flex gap-3 items-center ">
                          <img
                            src={token.img}
                            className="w-8 h-8 rounded-full"
                            alt="nb"
                          />
                          <div>
                            <p className="text-sm">{token.symbol}</p>
                            <p className="text-xs max-w-[100px]">
                              {token.tokenName}
                            </p>
                          </div>
                        </div>
                        <div>...</div>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-3 flex gap-2 items-center">
              {" "}
              <input
                value={walletAddress}
                onChange={(e) => setWalletAddress(e.target.value)}
                placeholder="Wallet Address"
                type="text"
                className="bg-transparent outline-none border rounded-xl xl:py-3 py-2 pl-6 xl:pr-6 w-full"
              />{" "}
              <div>
                <button
                  onClick={handleAddressUpdate}
                  className="px-4 py-2 bg-[#ff5500] rounded-full mt-2 text-sm"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ProfileSettings;
