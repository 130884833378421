import { useEffect, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import axios from "axios";
import useTable from "../../hooks/useTable";

import { chains } from "../../services/chains";
import FullScreenLoader from "../../components/Loader/FullScreenLoader";

import noTxn from "../../assets/icon 3-01.png";

const MyTransactions = () => {
  const auth = useAuth();
  const { page, rowsPerPage, onNextPage, onPrevPage } = useTable();
  const [isLoading, setIsloading] = useState(false);
  const [isClaimLoading, setIsClaimLoading] = useState(false);

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const getTransactions = async () => {
      try {
        setIsloading(true);
        const params = {
          page,
          limit: rowsPerPage,
        };
        const res = await axios.get("/api/transaction", { params });

        const data = res.map((transaction) => {
          const chain = chains.find(
            (chain) => Number(chain.id) === Number(transaction.chainId)
          );
          return {
            ...transaction,
            chainName: chain?.name?.split(" ")[0] || "Blazpay",
            chainImage:
              chain?.tokenImg || "https://i.ibb.co/2kwFgSM/blazLogo.png",
          };
        });
        setIsloading(false);
        setTableData(data);
      } catch (error) {
        setIsloading(false);
        console.log("error", error);
      }
    };

    getTransactions();
  }, [page, rowsPerPage]);

  const claimKatle = async () => {
    setIsClaimLoading(true);
    try {
      const address = auth.user.loginAddress;
      console.log(address);
      const res = await axios.post("/api/user/claim-katla-balance");
      setIsClaimLoading(false);
      if (res.status === 200) {
        return toast.success("Claim Successful");
      } else {
        return toast.error("Something went wrong");
      }
    } catch (error) {
      console.log(error);
      setIsClaimLoading(false);
      toast.error(error.response.data.message);
    }
  };

  if (isLoading) return <FullScreenLoader />;

  return (
    <section className="min-h-screen w-full  text-white">
      <div className="container relative mx-auto h-full pb-10  2xl:px-0 lg:px-6 px-3">
        <h1 className="font-bold md:text-[40px] text-2xl ml-2 pt-2  pb-3">
          Recent Transactions
        </h1>
        {/* <button
          onClick={claimKatle}
          disabled={isClaimLoading}
          className="rounded-full  lg:h-12 h-9 lg:px-16 px-12 border border-[#ff3503]"
        >
          {isClaimLoading ? "Processing..." : "Claim Katla Balance"}
        </button> */}
        <div className="w-full mt-6 bg-[rgba(66,66,66,0.12)] border border-[#ff5500] lg:rounded-3xl md:rounded-2xl rounded-xl pb-6 backdrop-blur-[38px] overflow-x-auto">
          <table className="w-full border-separate border-spacing-y-3">
            <tr className="lg:text-2xl  sm:text-xl text-[14px] text-center border-b border-[#ff5500] h-16">
              <th className="border-b border-[#ff5500]">Type</th>
              <th className="border-b  border-[#ff5500]">Chain</th>
              <th className="border-b border-[#ff5500]">Asset</th>
              <th className="border-b border-[#ff5500]">Module</th>
              <th className="border-b border-[#ff5500]">Date</th>
            </tr>
            {tableData.length === 0 ? (
              <tr>
                <td colSpan={5} className="h-100">
                  <div className="w-100 flex justify-center">
                    <img src={noTxn} className="h-80"></img>
                  </div>
                </td>
              </tr>
            ) : (
              tableData.map((transaction) => (
                <tr
                  key={transaction?._id}
                  className="lg:text-xl md:text-base text-xs"
                >
                  <td className="border-t border-b hover:border-[#ff5500] transition duration-300 ease-linear  border-[rgba(255,255,255,0.16)] text-center">
                    {transaction?.transfer === "in" ? "+" : "-"}
                  </td>
                  <td className="border-t border-b hover:border-[#ff5500] transition duration-300 ease-linear  border-[rgba(255,255,255,0.16)] text-center">
                    <img
                      src={transaction.chainImage}
                      className="h-9 w-9 rounded-full  mr-2 sm:inline-block hidden"
                      alt=""
                    />
                    {transaction.chainName}
                  </td>
                  <td className="border-t border-b hover:border-[#ff5500] transition duration-300 ease-linear  border-[rgba(255,255,255,0.16)] text-center lg:text-xl md:text-base text-[9px] ">
                    {transaction.tokens} BLZ
                  </td>
                  <td className="border-t border-b hover:border-[#ff5500] transition duration-300 ease-linear  border-[rgba(255,255,255,0.16)] text-center">
                    {transaction?.moduleName}
                  </td>
                  <td className="border-t border-b hover:border-[#ff5500] transition duration-300 ease-linear  border-[rgba(255,255,255,0.16)] text-center">
                    {dayjs(transaction?.createdAt).format("D MMM, h:mm A")}
                  </td>
                </tr>
              ))
            )}
          </table>
        </div>

        {/* Prev Page */}
        <section
          className={`absolute -bottom-3 ${
            page === 1 && "hidden"
          } sm:left-0 left-6 sm:text-xl text-base cursor-pointer`}
          onClick={onPrevPage}
        >
          <IoIosArrowBack className="inline sm:text-5xl text-3xl" />
          Prev Page
        </section>

        {/* Next Page */}
        <section
          className={`absolute -bottom-3 ${
            tableData.length < 8 && "hidden"
          } sm:right-0 right-6 sm:text-xl text-base cursor-pointer`}
          onClick={onNextPage}
        >
          Next Page
          <IoIosArrowForward className="inline sm:text-5xl text-3xl" />
        </section>
      </div>
    </section>
  );
};

export default MyTransactions;
