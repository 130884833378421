import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Overlay from "../Overlay";
import jackpotImg from "../../assets/jackpot.png";
import spinImg from "../../assets/spin.png";

const QuestionCard = ({ onClick }) => {
  return (
    <div onClick={onClick} className="cursor-pointer">
      <svg
        className="w-[110%] h-[108%]"
        width="176"
        height="132"
        viewBox="0 0 176 132"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M136.701 132C139.709 131.35 140.282 128.659 140.193 127.395V108.706C139.87 105.185 142.297 102.635 143.55 101.8C152.682 96.2697 171.402 84.9525 173.228 83.9233C175.055 82.894 175.511 81.4179 175.511 80.8084C175.712 66.7917 176.089 7.82376 175.981 4.08596C175.874 0.34816 172.758 -0.134857 171.214 0.0908508H5.46841C0.741394 -0.559204 -0.127014 2.43826 0.0296631 4.01825C0.00727844 33.406 -0.0240479 123.13 0.0296631 126.921C0.083374 130.713 3.4093 131.887 5.06555 132H136.701Z"
          fill="#FF3503"
        />
        <circle cx="134.5" cy="108.5" r="1.5" fill="black" />
      </svg>
      <h1 className="md:w-[10vw] absolute top-10 xl:text-base text-xs font-bold xl:ml-2 ml-4 text-black uppercase">
        Quiz Questions
      </h1>
    </div>
  );
};

const ReferCard = ({ onClick }) => {
  return (
    <Link to="/refer" className="cursor-pointer">
      <svg
        className="w-[110%] h-[108%]"
        width="171"
        height="133"
        viewBox="0 0 171 133"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M38.7358 1C35.8473 1.64726 35.2971 4.32622 35.3831 5.58479V81.202C35.6926 84.708 33.3629 87.2476 32.1594 88.0792C23.3908 93.5854 5.4153 104.854 3.66159 105.879C1.90788 106.904 1.46948 108.374 1.4695 108.98C1.27607 122.937 0.915017 124.21 1.01818 127.932C1.12134 131.653 4.11296 132.134 5.59587 131.91H164.749C169.288 132.557 170.122 129.572 169.972 127.999C169.993 98.7372 170.023 9.83246 169.972 6.05675C169.92 2.28104 166.726 1.11237 165.136 1H38.7358Z"
          stroke="#FF4D00"
        />
      </svg>
      <h1 className="absolute xl:bottom-3 bottom-6 xl:text-base text-xs left-1/2 transform xl:ml-5 ml-7 font-bold uppercase">
        Refer & Earn
      </h1>
    </Link>
  );
};

const GamesCard = () => {
  return (
    <div className="relative flex flex-col items-center justify-center w-full max-w-md mx-auto h-auto bg-black border border-orange-700 rounded-t-lg border-t border-l border-r">
      <div className="absolute top-0 flex items-center justify-center w-full p-2 bg-gradient-to-r from-[#FF3503] to-yellow-500 rounded-lg">
        <h1 className="text-lg font-bold">Choose game To Play</h1>
      </div>
      <div className="flex flex-col items-center justify-center w-full gap-4 mx-10 my-16">
        <Link to="/games/slot-machine">
          <img
            className="w-full transition-transform transform scale-100 hover:scale-105"
            src={jackpotImg}
            alt="jackpot"
          />
        </Link>
        <Link to="/games/spin">
          <img
            className="w-full transition-transform transform scale-100 hover:scale-105"
            src={spinImg}
            alt="spin"
          />
        </Link>
      </div>
    </div>
  );
};

const Games = () => {
  const [overlayContent, setOverlayContent] = useState(null);
  const navigate = useNavigate();

  const openOverlay = (content) => {
    setOverlayContent(content);
  };

  const closeOverlay = () => {
    setOverlayContent(null);
  };
  return (
    <div className="xl:h-[310px] h-[260px]  xl:w-[330px] w-[280px]  flex flex-col p-3 gap-y-3  border border-[#ff3503] rounded-2xl relative">
      <div className="h-full w-full flex justify-between relative">
        <section>
          <QuestionCard onClick={() => navigate("/questions")} />
        </section>
        <section className="mr-3">
          <ReferCard />
        </section>
      </div>
      <button
        className="active:text-xl transition ease-in-out duration-300 h-full w-full border border-[#ff3503] rounded-lg outline-none text-2xl font-semibold"
        onClick={() => navigate("/gaming-marketplace")}
      >
        Play Game
      </button>
      {overlayContent && (
        <Overlay onClose={closeOverlay}>{overlayContent}</Overlay>
      )}
    </div>
  );
};

export default Games;
