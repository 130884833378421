import React, { useEffect, useState } from "react";
import ReferPhones from "../components/ReferPhones";
import listBack from "../assets/Vector 266.svg";
import logo from "../assets/Group 124.png";
import { useAuth } from "../hooks/useAuth";
import axios from "axios";

const ReferralHistory = () => {
  const auth = useAuth();
  const [referredList, setReferredList] = useState([]);

  useEffect(() => {
    const getUsernames = async () => {
      if(auth.user.referredUsers){
        const usernamePromise = auth.user.referredUsers.map((e) => {
          return axios.get(`/api/user/username?_id=${e}`);
        });
        const res = await Promise.all(usernamePromise);
        if (res) {
          setReferredList(res);
        }
      }
      
    };

    getUsernames();
  }, []);

  return (
    <div className="w-full h-full flex">
      <div className="w-full flex-col relative md:w-1/2">
        <img
          src={listBack}
          className=" absolute top-16 left-10 md:left-16 w-full px-5 md:px-0 md:w-4/5"
        />
        <div className="absolute top-16 left-16 w-full">
          <div className="text-center w-full  /5 md:w-4/5 py-2 border-b border-[#FF3503] text-3xl font-semibold mb-4 ">
            History
          </div>
          <div className="w-4/5 text-lg font-normal text-center flex mr-16">
            {referredList.length === 0 ? (
              <div className="w-full">No referred users found</div>
            ) : (
              <div className="w-full flex-col relative md:w-1/2">
  <img
    src={listBack}
    className="absolute top-16 left-1/2 transform -translate-x-1/2 w-full md:w-4/5"
  />
  <div className="absolute top-16 left-1/2 transform -translate-x-1/2 w-full">
    <div className="text-center w-full md:w-4/5 py-2 border-b border-[#FF3503] text-3xl font-semibold mb-4 ">
      History
    </div>
    <div className="w-4/5 text-lg font-normal text-center flex mr-16 overflow-x-auto">
      {referredList.length === 0 ? (
        <div className="w-full">No referred users found</div>
      ) : (
        <table className="table-auto w-full text-center">
          <thead>
            <tr className="mb-4">
              <th>User Name</th>
              <th>Date</th>
              <th>Points/Earn</th>
            </tr>
          </thead>
          <tbody>
            {referredList.map((e) => (
              <tr>
                <td>{e}</td>
                <td>-</td>
                <td className="flex justify-center items-center">
                  <img
                    src={logo}
                    className="w-4 h-4 rounded-full border border-orange-700 mr-2"
                  />
                  50
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  </div>
</div>

            )}
          </div>
        </div>
      </div>
      <div className="hidden w-1/2 md:block">
        <ReferPhones />
      </div>
    </div>
  );
};

export default ReferralHistory;
