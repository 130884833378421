import { Link } from "react-router-dom";
import { BsCart4 } from "react-icons/bs";
import { useQuery } from "@tanstack/react-query";
import { useWallet } from "../../hooks/useWallet";
import { addToCart, getCart, getProducts } from "../../api/product";
import { useEffect, useState } from "react";

const ExpandArrow = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 14.3141L6.66556 8.64856M1 14.3141V10.0838M1 14.3141H5.23028"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6509 0.999878L8.93164 6.66544M14.6509 0.999878V5.27311M14.6509 0.999878H10.3776"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Storefront = () => {
  const wallet = useWallet();
  const [cartItemCount, setcartItemCount] = useState(0);

  const products = useQuery({
    queryKey: ["products", wallet.chainId],
    queryFn: () => getProducts({ chainId: wallet.chainId }),
    enabled: !!wallet.chainId,
  });

  const fetchCart = async (chainId) => {
    try {
      let cartData = await getCart({ chainId });
      cartData =  cartData.items.filter((item) => item.quantity >= 1),
      setcartItemCount(cartData.length);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchCart(wallet.chainId);
  }, [wallet]);

  useEffect(() => {
    fetchCart(wallet.chainId);
  }, []);

  return (
    <div
      className="xl:h-[310px] h-[260px] xl:w-[330px] w-[280px] border border-[#ff3503] rounded-2xl"
      style={{
        background: "rgba(0,0,0,0.61)",
        backdropFilter: "blur(28px)",
      }}
    >
      <div className="flex justify-between px-6 xl:pt-6 pt-4 pb-4 text-xl">
        <h1>StoreFront</h1>
        {/* Store front icons */}
        <div className="flex items-center gap-x-5 text-2xl">
          <div className="relative">
            <Link to="/cart">
              <BsCart4 className="cursor-pointer" />
            </Link>
            {cartItemCount > 0 && (
              <span className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full w-6 h-6 flex justify-center items-center text-sm">
                {cartItemCount}
              </span>
            )}
          </div>
          <Link to="/store">
            <ExpandArrow className="cursor-pointer" />
          </Link>
        </div>
      </div>

      {/* StoreFront Data */}
      <div className="flex justify-between w-full xl:px-5 px-3 xl:gap-x-4 gap-x-2 xl:mt-5 mt-3">
        {products?.data?.slice(0, 2).map((item) => {
          return (
            <div
              key={item._id}
              className="xl:h-[180px] h-[160px] w-full border-[2px] border-[#fa5501] rounded-xl"
            >
              <img
                src={item.img}
                alt="img"
                className="mx-auto mt-[6px] xl:w-fit xl:h-fit h-[70%] w-[90%]"
              />
              <div className="flex w-full justify-between mt-2 px-3 items-center">
                <p className="text-base text-ellipsis">
                  {item.title.slice(0, 7)}...
                </p>
                <button
                  onClick={async() => {
                    await addToCart({
                      productId: item._id,
                      quantity: 1,
                      chainId: wallet.chainId,
                      showToast: true,
                    });
                    fetchCart(wallet.chainId);
                  }
                  }
                  className="active:text-2xl transitin duration-300 ease-in-out outline-none h-5 w-5 rounded-[2px] bg-[#ff3503] text-3xl text-center"
                  style={{ lineHeight: "18px" }}
                >
                  +
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Storefront;
