import PropTypes from 'prop-types';
import Navbar from './Navbar';
import bgImage from '../../assets/home.svg';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import CreateProfile from '../CreateProfile';

export default function Layout() {
  const auth = useAuth();

  return (
    <div
      className="w-full min-h-screen "
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: 'white',
      }}
    >
      <Navbar />
      <div className="mt-5 mx-5">
        {auth.user.isProfileCreated ? <Outlet /> : <CreateProfile />}
      </div>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.element,
};
