import "../../assets/css/slotMachine.css";
import jackpotlogo from "../../assets/jackpotlogo.png";
import { ethers } from "ethers";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useWallet } from "../../hooks/useWallet";
import FullScreenLoader from "../../components/Loader/FullScreenLoader";
import { handleTransactionError, toastPromise } from "../../services/util";
import { parseEther } from "ethers/lib/utils";
import { useAuth } from "../../hooks/useAuth";
import { getSignature } from "../../api/games";
import { apiCall } from "../../services/ApiCall";
import configs from "../../config/configs.json";
import axios from "axios";

const SlotMachine = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRolling, setIsRolling] = useState(false);
  const [rollsLeft, setRollsLeft] = useState(10);
  const [originalrollsLeft, setOriginalrollsLeft] = useState(10);
  const [ring1, setRing1] = useState();
  const [ring2, setRing2] = useState();
  const [ring3, setRing3] = useState();
  const [spinningRow1, setSpinningRow1] = useState(false);
  const [spinningRow2, setSpinningRow2] = useState(false);
  const [spinningRow3, setSpinningRow3] = useState(false);
  const [num1] = useState([1, 2, 5, 6]);
  const [num2] = useState([3, 4, 5, 6]);
  const [num3] = useState([1, 2, 3, 4]);
  const [currentEarnings, setCurrentEarnings] = useState(0);
  const [totalRollsDone, setTotalRollsDone] = useState(0);
  const [totalRollRewards, setTotalRollsReward] = useState(0);

  const wallet = useWallet();
  const auth = useAuth();

  const handleSubmit = async () => {
    if (totalRollsDone <= 0) {
      toast.info("You haven't rolled yet!");
      return;
    }

    const config = wallet.getChainConfig();
    if (!config) return;

    setIsSubmitting(true);
    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      updateRewardCount();
    } else {
      const transactionOptions = {
        value: parseEther(
          String((config.features.spinValue * totalRollsDone).toFixed(8))
        ),
        ...(config.features.gasLimit && { gasLimit: "900000" }),
      };

      const message = "Slot Machine";
      const nonce = 168;

      const signature = await getSignature({
        to: wallet.address,
        amount: ethers.utils.parseEther(currentEarnings.toString()),
        message: message,
        nonce: nonce,
        chainId: wallet.chainId,
      });

      const [, tx] = await wallet.safeCallContract({
        name: "Game",
        method: "bulkRoll",
        args: [
          totalRollsDone,
          ethers.utils.parseEther(currentEarnings.toString()),
          1,
          message,
          nonce,
          signature,
          transactionOptions,
        ],
      });

      if (tx) {
        await toastPromise(tx.wait(), {
          pending: "Submitting...",
          success: () => {
            setCurrentEarnings(0);
            setTotalRollsDone(0);
            setTotalRollsReward(+totalRollRewards + currentEarnings);
            wallet.updatePoints({
              amount: currentEarnings,
              chainId: wallet.chainId,
              walletAddress: wallet.address,
              moduleName: "jackpot",
              txHash: tx.hash,
            });
            auth.updateTokens();
            return "Submitted successfully!";
          },
          error: (err) => {
            handleTransactionError(err);
          },
        });
      }

      setIsSubmitting(false);
    }
  };

  const roll = () => {
    if (rollsLeft <= 0) {
      toast.error("No rolls left");
      return;
    }

    setIsRolling(true);
    setSpinningRow1(true);
    setSpinningRow2(true);
    setSpinningRow3(true);

    const winnings = Math.floor(Math.random() * 100);

    const newRing1 = Math.floor(Math.random() * num1.length);
    const newRing2 = Math.floor(Math.random() * num2.length);
    const newRing3 = Math.floor(Math.random() * num3.length);

    setTimeout(() => {
      setSpinningRow1(false);
      setRing1(newRing1);
    }, 500 + Math.random() * 500);

    setTimeout(() => {
      setSpinningRow2(false);
      setRing2(newRing2);
    }, 1000 + Math.random() * 500);

    setTimeout(() => {
      setSpinningRow3(false);
      setIsRolling(false);
      setRing3(newRing3);
      setCurrentEarnings(currentEarnings + winnings);
      setTotalRollsDone(totalRollsDone + 1);
      setRollsLeft(rollsLeft - 1);
    }, 1500 + Math.random() * 500);
  };

  const RowComponent = ({ ring, num }) => {
    const index = ring ? ring % num.length : 0;

    return (
      <>
        <div className='text-black ringEnd  flex justify-center items-center'>
          {num[index]}
        </div>
        <div className='text-black ringEnd flex justify-center items-center'>
          {num[(index + 1) % num.length]}
        </div>
        <div className='text-black ringEnd flex justify-center items-center'>
          {num[(index + 2) % num.length]}
        </div>
        <div className='text-black ringEnd flex justify-center items-center'>
          {num[(index + 3) % num.length]}
        </div>
      </>
    );
  };
  const getActivityCount = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLink/getActivityCount`,
        {},
        {
          gameType: "JACKPOTROLL",
          date: new Date(),
        },
        "GET"
      );
      if (isSuccess) {
        const count = parseInt(data.data?.processCount.toString(), 10);
        setOriginalrollsLeft(10 - count);
        setTotalRollsDone(count);
        setRollsLeft(10 - count);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const mintReward = async (amount) => {
    setIsSubmitting(true);
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}mint/erc20`,
        {},
        {
          amount: String(amount),
        },
        "POST"
      );
      setIsSubmitting(false);

      if (isSuccess) {
        // updateRewardCount(amount);
        // updateActivityCount();
        getActivityCount();
        setCurrentEarnings(0);
        setTotalRollsDone(0);
        setTotalRollsReward(+totalRollRewards + amount);
        wallet.updatePoints({
          amount: amount,
          chainId: wallet.chainId,
          walletAddress: wallet.address,
          moduleName: "jackpot",
          txHash: "lightlink",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateRewardCount = async () => {
    const res = await axios.get("/api/nft/all-balance");
    let multiplier = 1;
    if (res && res.multiplier > 1) {
      multiplier = res.multiplier;
    }
    const amount = Number(currentEarnings) * multiplier;
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLinkReward/updateRewardCount`,
        {},
        {
          reward: amount,
          gameType: "JACKPOTROLL",
          date: new Date(),
          count: originalrollsLeft - rollsLeft,
        },
        "POST"
      );
      setIsSubmitting(false);
      if (isSuccess) {
        await mintReward(amount);
        getRewardCount();
        auth.updateTokens();
      }else{
        getActivityCount();
        setCurrentEarnings(0);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getRewardCount = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLinkReward/getRewardCount`,
        {},
        { gameType: "JACKPOTROLL" },
        "GET"
      );
      if (isSuccess) {
        setTotalRollsReward(data.data?.rewardCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateActivityCount = async () => {
   const currentCount = await getActivityCount();
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLink/updateActivityCount`,
        {},
        {
          gameType: "JACKPOTROLL",
          date: new Date(),
          count: currentCount+1,
        },
        "POST"
      );
      if (isSuccess) {
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      getActivityCount();
      getRewardCount();
    }
  }, []);
  useEffect(() => {
    if (!wallet.address) return;

    const init = async () => {
      setCurrentEarnings(0);
      if (wallet.chainId === 1890 || wallet.chainId === 1891) {
        getActivityCount();
      } else {
        const [, left] = await wallet.safeCallContract({
          name: "Game",
          method: "getRollsLeft",
          args: [wallet.address],
        });

        if (left) {
          setRollsLeft(left.toNumber());
        }

        const [, rewards] = await wallet.safeCallContract({
          name: "Game",
          method: "getRollRewards",
          args: [wallet.address],
        });

        if (rewards) {
          setTotalRollsReward(
            Number(
              ethers.utils.formatUnits(rewards.toString()).toString()
            ).toFixed(0)
          );
        }
      }

      setIsLoading(true);

      init().finally(() => setIsLoading(false));
    };
  }, [wallet.address, wallet.chainId]);

  if (isLoading) return <FullScreenLoader />;

  return (
    <div>
      <div className="flex flex-col md:flex-row gap-10">
        <div className="p-5 bg-gray-400 fullSlot">
          <h1 className="casinoName">BlazPay</h1>
        
          <img src={jackpotlogo} alt="jackpot" />

          <h1 className='price'>{"Jackpot: Upto " + 1000 + " tokens"}</h1>
          <div className='slot'>
            <div className={`row ${spinningRow1 ? "ringMoving" : "ringEnd"}`}>
              <div className='row'>
                <RowComponent ring={ring1} num={num1} />
              </div>
            </div>
            <div className={`row ${spinningRow2 ? "ringMoving" : "ringEnd"}`}>
              <div className='row'>
                <RowComponent ring={ring2} num={num2} />
              </div>
            </div>
            <div className={`row ${spinningRow3 ? "ringMoving" : "ringEnd"}`}>
              <div className='row'>
                <RowComponent ring={ring3} num={num3} />
              </div>
            </div>
          </div>
          <h1 className='price'>Rolls left: {rollsLeft}</h1>
          <div className='slotFoot'>
            {rollsLeft > 0 ? (
              <button
                className='spinButton bg-gradient-to-r from-[#FF3503] to-yellow-500 rounded-lg'
                onClick={roll}
                disabled={isRolling || rollsLeft <= 0} // Now also considering the jackpot's rolling state
              >
                {isRolling ? "Rolling..." : "Roll"}
              </button>
            ) : (
              <p>No more rolls left</p>
            )}
          </div>
        </div>
        <div className="w-full lg:w-1/2 flex flex-col self-center justify-center items-center p-5">
        <div className="bg-gray-800 text-white p-4 my-4 rounded-md shadow-md">
          <div className="text-sm text-center">
            Refresh every 24 hrs to claim points
          </div>
        </div>
          <div className="flex flex-row gap-10 justify-center items-center">
            
            <div className="flex flex-col">
              <div className="text-xl text-white mb-2 text-center">
                Current Roll Rewards
              </div>
              <div className='text-5xl font-bold bg-black bg-opacity-20 py-2 px-4 text-center mb-4 text-white'>
                {currentEarnings}
              </div>
            </div>
            <div className='flex flex-col'>
              <div className='text-xl text-white mb-2 text-center'>
                Total Roll Rewards
              </div>
              <div className='text-5xl font-bold bg-black bg-opacity-20 py-2 px-4 text-center mb-4 text-white'>
                {totalRollRewards}
              </div>
            </div>
          </div>

          <button
            onClick={handleSubmit}
            className={`bg-gradient-to-r from-[#FF3503] to-yellow-500 text-white font-bold py-2 px-10 rounded-lg transition duration-300 ease-in-out`}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SlotMachine;
