import { useEffect, useState } from "react";
import {
  EntrypassCard,
  EntrypassDetailsButton,
  EntrypassOwnButton,
  EntrypassSVGContainer,
  GetMoreNowEntrypassButton,
  NextItemStoreFrontArrow,
  TestnetEntrypassButton,
} from "../../components/SvgContainer";
import taikoImg from "../../assets/taikoEntryPass.png";
import mantaImg from "../../assets/mantaEntryPass.png";
import polygonImg from "../../assets/polygonEP.png";
import bnbImg from "../../assets/galxeEP.png";
import zkfairImg from "../../assets/zkfairEP.png";
import movementImg from "../../assets/MovementEP.png";
import axios from "axios";

const setOfEntrypasses = [
  {
    imgPath: mantaImg,
    name: "Blazpay x manta Network",
    chainId: 169,
    redirect: "manta",
  },
  {
    imgPath: bnbImg,
    name: "Binance Entry Pass",
    chainId: 56,
    redirect: "binance",
  },
  {
    imgPath: taikoImg,
    name: "Movement Entry Pass",
    chainId: 167008,
    redirect: "movement",
  },
  {
    imgPath: polygonImg,
    name: "Polygon Entry Pass",
    chainId: 137,
    redirect: "polygon",
  },

  // {
  //   imgPath: zkfairImg,
  //   name: "ZkFair Entry Pass",
  //   chainId: 42766,
  //   redirect: "zkfair",
  // },
];

const Entrypasses = () => {
  const [entrypasses, setEntrypasses] = useState(0);

  const [nfts, setNfts] = useState(null);

  const handleNextPage = () => {
    setEntrypasses((prevEntrypasses) => prevEntrypasses + 4);
  };

  const handlePrevPage = () => {
    setEntrypasses((nextEntrypasses) => Math.max(nextEntrypasses - 4, 0));
  };

  useEffect(() => {
    const getNfts = async () => {
      const res = await axios.get("/api/nft/all-balance");
      if (res) {
        setNfts(res);
        console.log(res, "sadfyutrewqwert");
      }
    };

    getNfts();
  }, []);

  function getBalance(chainId) {
    if (!nfts) {
      return 0;
    }

    const balance1 = nfts?.nftBalances[167008]?.balance || 0;
    const balance2 = nfts?.nftBalances[167009]?.balance || 0;
    const combinedBalance = balance1 + balance2;

    if (chainId === 167008 || chainId === 167009) {
      return combinedBalance;
    }

    return nfts?.nftBalances[chainId]?.balance || 0;
  }

  const handleGetMore = (redirect) => {
    window.location = `https://blazpay.com/entry-pass/${redirect}`;
  };

  return (
    <section className='flex justify-center items-center min-h-screen w-full entrypasses-container text-white pb-16 overflow-hidden'>
      <div className='relative 2xl:w-[1430px] xl:w-[1250px] lg:w-[960px] sm:w-[640px] w-[370px] 2xl:h-[700px]  xl:h-[600px] lg:h-[520px] sm:h-[1160px] h-[2040px] z-50'>
        <h1 className='sm:text-4xl text-2xl font-bold absolute -top-16  sm:left-24 left-20'>
          My Entry passes
        </h1>

        {/* Own Entrypass */}
        <div
          className='2xl:h-[700px] xl:h-[600px] lg:h-[520px] h-[400px] 2xl:w-[460px] xl:w-[410px] w-[340px] flex flex-col items-center
           justify-center xl:pl-10 sm:-pl-10  sm:relative absolute sm:left-0 left-1/2 top-0 transform sm:translate-x-0 translate-x-[-55%]'
          style={{ perspective: "4000px" }}
        >
          <img
            src={movementImg}
            alt='Movement'
            className='2xl:h-[550px] xl:h-[460px] h-[320px]  2xl:w-[380px] xl:w-[310px] sm:w-52 w-60 entrypass-img'
          />
          <section className='relative  -ml-10 xl:h-[70px] xl:w-[265px] sm:h-12 h-10 sm:w-48 w-40 hover:scale-90 transition duration-500 ease-in-out'>
            <p className='absolute top-1/2 left-1/2 transform translate-x-[-50%] translate-y-[-50%] xl:text-2xl text-base pointer-events-none'>
              You Own {getBalance(30732)}
            </p>
            <EntrypassOwnButton className='cursor-pointer absolute h-full w-full' />
          </section>
        </div>

        {/* List of Entrypasses */}
        <div
          className='2xl:w-[550px] xl:w-[500px] lg:w-[420px] w-[600px] grid sm:grid-cols-2 grid-cols-1  xl:gap-4 gap-3 xl:px-0 px-2 
         absolute 2xl:left-[500px] xl:left-[400px] lg:left-64 left-1/2 transform lg:translate-x-0 translate-x-[-50%] 
         xl:top-2 lg:top-6 sm:top-[460px] top-[680px] 2xl:-ml-12  xl:p-3'
        >
          {setOfEntrypasses.slice(entrypasses, entrypasses + 4).map((item) => {
            return (
              <section className='relative px-2 mx-auto'>
                <img
                  src={item.imgPath}
                  alt={item.name}
                className='absolute top-3 left-1/2 transform translate-x-[-50%] transition-all duration-500 ease-linear hover:scale-105 
               2xl:w-[100%] 2xl:max-w-[200px] xl:w-[100%] xl:max-w-[150px] lg:w-[100%] lg:max-w-[120px] w-[100%] max-w-[200px] h-[200px] object-cover'
                />
                <div className='flex flex-col absolute 2xl:bottom-6 xl:bottom-4 lg:bottom-16 bottom-6 2xl:left-5 lg:left-7 left-5'>
                  <h1 className='2xl:text-base xl:text-[13px] lg:text-xs text-base xl:font-bold lg:font-medium font-bold'>
                    {item.name}
                  </h1>
                  <h1 className='2xl:text-[13px] text-xs font-bold text-[#ff3503]'>
                    Owned Passes: {getBalance(item.chainId)}{" "}
                    {/* <TestnetEntrypassButton className="inline-block cursor-pointer h-6 xl:relative lg:absolute relative xl:top-0 lg:top-8  2xl:left-12 lg:left-0" /> */}
                  </h1>
                  <GetMoreNowEntrypassButton
                    className='duration-300 ease-in-out active:scale-95 cursor-pointer xl:relative lg:absolute relative xl:top-0 lg:top-[58px]'
                    onClick={() => handleGetMore(item.redirect)}
                  />
                </div>
                <EntrypassCard className='2xl:w-[260px] xl:w-[222px] lg:w-52 w-[260px]  2xl:h-[315px] xl:h-[270px] lg:h-[230px] h-[315px]' />
              </section>
            );
          })}
        </div>

        {/* Details */}
        <div
          className='flex flex-col absolute sm:right-0 right-1/2 sm:translate-x-0 translate-x-[56%]  sm:top-0 top-96 xl:w-[360px] lg:w-72 sm:w-[330px] w-[280px] 2xl:h-[660px] xl:h-[600px]
           lg:h-[520px] sm:h-[400px] h-72 2xl:pl-0 xl:pl-4
          items-start justify-start xl:text-2xl text-base '
        >
          <h1 className='font-bold lg:text-center sm:mt-20 mt-4 sm:ml-0 -ml-4 xl:text-2xl lg:text-xl sm:text-2xl text-xl text-[#ff3503]'>
            Details
          </h1>

          <div className='flex flex-col w-full xl:px-0 lg:px-10 pr-8'>
            <div className='border border-gray-300 rounded-lg p-4 shadow-md mt-4 w-full xl:w-72 '>
              <div className='flex justify-between items-center h-full'>
                <p>Number of Mainnet Entrypass</p>
                <p className='text-[#ff3503] text-base'>
                  {nfts?.mainnetBalance || 0}
                </p>
              </div>
            </div>
          </div>

          <div className='flex flex-col w-full xl:px-0 lg:px-10 pr-8'>
            <div className='border border-gray-300 rounded-lg p-4 shadow-md mt-4 w-full xl:w-72 '>
              <div className='flex justify-between items-center h-full'>
                <p>Number of Testnet Entrypass</p>
                <p className='text-[#ff3503] text-base'>
                  {nfts?.testnetBalance || 0}
                </p>
              </div>
            </div>
          </div>

          <div className='flex flex-col w-full xl:px-0 lg:px-10 pr-8'>
            <div className='border border-gray-300 rounded-lg p-4 shadow-md mt-4 w-full xl:w-72 '>
              <div className='flex justify-between items-center h-full'>
                <section className='relative'>
                  <h1 className='absolute xl:top-1 top-3 left-4'>
                    Multiplier as per calculation
                  </h1>
                  <EntrypassDetailsButton className='xl:w-fit w-40' />
                </section>
                <p className='text-[#ff3503] text-base'>
                  {nfts?.multiplier || 0}
                </p>
              </div>
            </div>
          </div>
        </div>

        <EntrypassSVGContainer
          className='absolute  sm:top-0 top-36 left-1/2 transform translate-x-[-50%]
              2xl:w-full 2xl:h-[688px] xl:w-[1250px] lg:w-[960px] sm:w-[640px] w-[640px] xl:h-[600px]
               lg:h-[520px] sm:h-[420px]  h-[360px]  z-[-999] xl:scale-y-100 lg:scale-y-110 sm:scale-y-[1.3] scale-y-[1.16]  sm:rotate-0 rotate-90'
        />

        {/* Next page button */}
        <section
          className={`absolute flex flex-row  items-center xl:right-10 lg:right-0 sm:right-16  right-3 2xl:bottom-5 bottom-2 cursor-pointer
           ${
             entrypasses + 4 >= setOfEntrypasses.length ? "invisible" : "flex"
           }`}
          onClick={handleNextPage}
        >
          <h1 className='2xl:text-xl sm:text-base text-xs font-bold'>
            Next Page
          </h1>
          <NextItemStoreFrontArrow className='relative transform scale-75 entrypass-arrowright-move ' />
        </section>

        {/* Prev page button */}
        <section
          className={`absolute flex flex-row  items-center 2xl:right-60 xl:right-52 lg:right-36  sm:right-[450px]  right-64 2xl:bottom-5 bottom-2 cursor-pointer
           ${entrypasses === 0 && "hidden"}`}
          onClick={handlePrevPage}
        >
          <NextItemStoreFrontArrow className='relative transform scale-75 rotate-180 entrypass-arrowleft-move' />
          <h1 className='2xl:text-xl sm:text-base text-xs font-bold'>
            Prev Page
          </h1>
        </section>
      </div>
    </section>
  );
};

export default Entrypasses;
