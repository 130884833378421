import React, { useMemo, useState } from "react";
import Logo from "../../assets/logo_blazpay.png";
import Fire from "../../assets/fire.png";
import { Link } from "react-router-dom";
import profile from "../../assets/profile.png";
import entrylogo from "../../assets/entrylogo.png";
import items from "../../assets/items.png";
import { FaTimes } from "react-icons/fa";
import loginSignupImage from "../../assets/login-animation.gif";
import { IoIosNotifications, IoMdLogOut } from "react-icons/io";
import { AiOutlineTransaction } from "react-icons/ai";
import Notification from "../../pages/Notification";
import { useAuth } from "../../hooks/useAuth";
import { chains } from "../../services/chains";
import { useWallet } from "../../hooks/useWallet";
import { useWeb3Modal } from "@web3modal/ethers5/react";

const Navbar = () => {
  const auth = useAuth();
  const [click, setClick] = useState(false);
  const [notif, setNotif] = useState(false);
  const wallet = useWallet();
  const { open } = useWeb3Modal();

  const handleLogout = (e) => {
    e.preventDefault();
    auth.logout();
  };

  const user = auth.user;
  const username = user.username;

  const chain = useMemo(
    () => chains.find((item) => item.id == wallet.chainId),
    [wallet.chainId]
  );

  const closeProfile = () => {
    setClick(!click);
  };

  const handleNotification = () => {
    setNotif(!notif);
  };

  return (
    <nav className="relative flex items-center justify-between w-full shadow-md p-3 md:p-3 mb-4 z-50">
      {/* Logo on the left */}
      <Link to="/" className="shrink-0">
        <img src={Logo} alt="Blazpay logo" className="w-16 md:w-24 lg:w-32" />
      </Link>
      {/* Right content */}
      <div className="flex items-center justify-end md:flex-grow md:order-3 gap-3 md:gap-6">
        {/* Adjusted Profile and dropdown */}
        <div className="relative flex items-center bg-white rounded-full px-2 sm:flex">
          <img src={Fire} alt="Fire" className="w-8 h-8 md:w-10 md:h-10" />
          <div className="text-black mx-2 text-xs md:text-sm md:mx-3">
            {auth.tokens}
          </div>
        </div>

        {/* Adjusted Dropdown */}
        <div className="relative">
          <button
            onClick={() =>
              open({ view: wallet.isConnected ? "Networks" : "Connect" })
            }
            className="bg-gradient-to-r from-[#ff3503] to-[#fba500] rounded-full py-2 px-4 max-sm:text-xs max-sm:px-1 max-sm:py-[.5px]"
          >
            {chain ? chain.name : "Wrong Network"}
          </button>
        </div>

        <div className="relative">
          <button
            onClick={closeProfile}
            className="bg-gradient-to-r from-[#ff3503] to-[#fba500] rounded-full px-2 flex items-center py-1 "
          >
            <img
              src={user?.img_url || loginSignupImage}
              className="w-8 h-8  rounded-full max-sm:h-6 max-sm:w-6"
              alt="Profile"
            />
            <span className="hidden md:block ml-1 md:ml-2 text-xs md:text-sm text-white">
              {username}
            </span>
          </button>
          {/* Dropdown menu */}
          {click && (
            <div className="absolute right-0 w-48 mt-2 bg-gray-700 rounded-lg shadow-lg z-[100]">
              <div className="flex flex-col text-white">
                {/* <Link
                  to="/user/profile"
                  onClick={closeProfile}
                  className="flex items-center p-2 hover:bg-gray-600"
                >
                  <img className="w-5 mr-2" src={profile} alt="Profile" />
                  Profile
                </Link> */}

                <Link to="/user/items">
                  <div
                    onClick={closeProfile}
                    className="flex items-center p-2 hover:bg-gray-600 cursor-pointer"
                  >
                    <img className="w-5 mr-2" src={items} alt="My Items" />
                    My Items
                  </div>
                </Link>

                <Link
                  to="/user/pass"
                  onClick={closeProfile}
                  className="flex items-center p-2 hover:bg-gray-600"
                >
                  <img
                    className="w-5 mr-2"
                    src={entrylogo}
                    alt="My Entry Passes"
                  />
                  My Entry Passes
                </Link>
                <Link
                  to="/user/transactions"
                  className="flex items-center p-2 hover:bg-gray-600"
                  onClick={closeProfile}
                >
                  <AiOutlineTransaction className="text-xl mr-2" />
                  Recent Transactions
                </Link>
                <hr className="border-gray-600" />
                <button
                  onClick={handleLogout}
                  className="flex items-center p-2 hover:bg-gray-600 w-full text-left"
                >
                  <IoMdLogOut className="text-xl mr-2" />
                  Logout
                </button>
                <div
                  onClick={closeProfile}
                  className="flex items-center justify-center p-2 hover:bg-gray-600 cursor-pointer"
                >
                  <FaTimes className="text-xl" />
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Notification icon */}
        <div
          onClick={handleNotification}
          className="ml-2 cursor-pointer text-3xl md:text-4xl text-white"
        >
          <IoIosNotifications />
        </div>
      </div>

      {/* Notification panel */}
      {notif && (
        <div className="absolute top-full right-0 mt-2 z-10">
          <Notification handleNotification={handleNotification} />
        </div>
      )}
    </nav>
  );
};

export default Navbar;
