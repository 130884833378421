import React, { useEffect } from "react";
import { useAuth } from "../hooks/useAuth";
import ReferPhones from "../components/ReferPhones";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const Referral = () => {
  const auth = useAuth();

  const referralCode = auth.user.referralCode;
  async function copyTextToClipboard() {
    if ("clipboard" in navigator) {
      toast.info("Copied referral code");
      return await navigator.clipboard.writeText(referralCode);
    } else {
      toast.info("Copied referral code");
      return document.execCommand("copy", true, referralCode);
    }
  }
  return (
    <div className="w-full h-full flex flex-col md:flex-row">
      <div className="w-full md:w-1/2 flex flex-col pt-16 pl-4 md:pl-16">
        <h1 className="text-4xl md:text-7xl font-semibold">REFER & EARN</h1>
        <br />
        <span className="text-lg md:text-2xl">
          Invite your friends and get access to
          <br /> special offers & reward points.
        </span>
        <br />
        <br />
        <br />
        <button
          className="bg-[#424242]/20 hover:bg-[#424242]/25 text-white text-lg md:text-2xl font-bold py-3 md:py-4 px-16 md:px-32 rounded-full transition duration-300 ease-in-out mb-4 border-2 border-orange-500"
          onClick={copyTextToClipboard}
        >
          {referralCode}
        </button>
        <div className="flex w-full flex-col md:flex-row">
          <div className="md:w-1/2 mb-4 md:mb-0 md:mr-4">
            <button
              className="flex justify-center items-center my-4 py-3 md:py-4 px-6 md:px-10 tracking-wider relative text-lg md:text-2xl mr-0 md:mr-4 w-full md:w-auto"
              disabled={true}
            >
              <div className="w-full h-full absolute top-0 left-0 border-2 border-slate-800"></div>
              <div className="border-t-2 border-l-2 border-orange-500 w-4 h-4 absolute top-0 left-0"></div>
              <div className="border-t-2 border-r-2 border-orange-500 w-4 h-4 absolute top-0 right-0"></div>
              <div className="border-b-2 border-l-2 border-orange-500 w-4 h-4 absolute bottom-0 left-0"></div>
              <div className="border-b-2 border-r-2 border-orange-500 w-4 h-4 absolute bottom-0 right-0"></div>
              Total Referred:{" "}
              {auth.user.referredUsers
                ? auth.user.referredUsers.length
                    .toFixed(0)
                    .toString()
                    .padStart(2, "0")
                : "00"}
            </button>
          </div>
          <div className="md:w-1/2">
            <Link
              className="flex justify-center items-center my-4 py-3 md:py-4 px-6 md:px-20 tracking-wider relative text-lg md:text-2xl w-full md:w-auto"
              to="referral-history"
            >
              <div className="w-full h-full absolute top-0 left-0 border-2 border-slate-800"></div>
              <div className="border-t-2 border-l-2 border-orange-500 w-4 h-4 absolute top-0 left-0"></div>
              <div className="border-t-2 border-r-2 border-orange-500 w-4 h-4 absolute top-0 right-0"></div>
              <div className="border-b-2 border-l-2 border-orange-500 w-4 h-4 absolute bottom-0 left-0"></div>
              <div className="border-b-2 border-r-2 border-orange-500 w-4 h-4 absolute bottom-0 right-0"></div>
              History
            </Link>
          </div>
        </div>
      </div>
      <div className="flex md:block md:w-1/2">
        <ReferPhones />
      </div>
    </div>
  );
};

export default Referral;
