import { useWallet } from "../hooks/useWallet";
import { addToCart, completePurchase, getCart } from "../api/product";
import FullScreenLoader from "../components/Loader/FullScreenLoader";
import { useEffect, useMemo, useState } from "react";
import { ethers } from "ethers";
import { handleTransactionError, toastPromise } from "../services/util";
import { toast } from "react-toastify";
import { useAuth } from "../hooks/useAuth";
import { apiCall } from "../services/ApiCall";
import configs from "../config/configs.json";

const Cart = () => {
  const [cart, setCart] = useState({
    isLoading: false,
    isError: false,
    data: null,
    error: null,
  });
  const [isPurchasing, setIsPurchasing] = useState(false);
  const wallet = useWallet();
  const auth = useAuth();

  const chain = wallet.chainId && wallet.getChainConfig();

  const totalPrice = useMemo(() => {
    return (
      cart.data?.items.reduce(
        (price, item) => (price += item.productPrice * item.quantity),
        0
      ) || 0
    );
  }, [cart.data]);

  const handleQuantityChange = async (productId, quantity) => {
    setCart((cart) => {
      const items = cart.data.items;
      const index = items.findIndex((e) => e.productId === productId);
      items[index].quantity += quantity;

      if (items[index].quantity <= 0) {
        delete items[index];
      }

      return {
        ...cart,
        data: { ...cart.data, items: items.filter(Boolean) },
      };
    });

    await addToCart({
      productId,
      quantity,
      chainId: wallet.chainId,
    });
  };

  const fetchCart = async (chainId) => {
    try {
      const cartData = await getCart({ chainId });
      setCart({
        isLoading: false,
        isError: false,
        data: {
          ...cartData,
          items: cartData.items.filter((item) => item.quantity >= 1),
        },
        error: null,
      });
    } catch (err) {
      console.error(err);
      setCart({
        isLoading: false,
        isError: true,
        error: err,
      });
    }
  };

  const purchaseNFT = async (totalPrize, nftItems) => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLinkReward/purchaseNFT`,
        {},
        {
          totalPrize,
          nftItems,
        },
        "POST"
      );
      if (isSuccess) {
        setCart((prev) => ({
          ...prev,
          data: {
            items: [],
          },
        }));
        completePurchase({
          chainId: wallet.chainId,
          walletAddress: wallet.address,
          txHash: "lightlinknftpurchase",
          amountRedeemed: totalPrice,
        });
        auth.updateTokens();
        setIsPurchasing(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const purchase = async () => {
    const chain = wallet.getChainConfig();
    if (!chain) return;

    if (cart.data.items.length === 0) {
      return toast.info("Please add some products to your cart");
    }

    if (+auth.tokens < totalPrice) {
      return toast.error("Insufficient tokens");
    }
    setIsPurchasing(true);
    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      purchaseNFT(totalPrice, cart.data.items);
    } else {
      const weiPrice = ethers.utils.parseEther(totalPrice.toString());
      const [err1, tokenAllowance] = await wallet.safeCallContract({
        name: "Token2",
        method: "allowance",
        args: [wallet.address, chain.contracts.Game.address],
      });

      if (err1) return setIsPurchasing(false);

      if (tokenAllowance && tokenAllowance.lt(weiPrice)) {
        const [err2, approveTx] = await wallet.safeCallContract({
          name: "Token2",
          method: "approve",
          args: [chain.contracts.Game.address, weiPrice],
        });

        if (err2) return setIsPurchasing(false);

        await toastPromise(approveTx.wait(), {
          pending: "Approving...",
          success: "Approved",
          error: "Approve Failed",
        });
      }

      const tokens = cart.data.items.map((item) => item.productMintUrl);

      const [, tx] = await wallet.safeCallContract({
        name: "Game",
        method: "batchRedeemTokens",
        args: [
          tokens,
          weiPrice,
          {
            gasLimit: 1000000,
          },
        ],
      });

      if (tx) {
        await toastPromise(tx.wait(), {
          pending: "Purchasing...",
          success: () => {
            setCart((prev) => ({
              ...prev,
              data: {
                ...prev.data,
                items: [],
              },
            }));
            completePurchase({
              chainId: wallet.chainId,
              walletAddress: wallet.address,
              txHash: "purchaselightlinknft",
              amountRedeemed: totalPrice,
            });
            auth.updateTokens();
            return "Purchase Successful";
          },
          error: (err) => {
            handleTransactionError(err);
          },
        });
      }

      setIsPurchasing(false);
    }
  };

  useEffect(() => {
    setCart({
      isLoading: true,
      isError: false,
      data: null,
      error: null,
    });

    fetchCart(wallet.chainId);
  }, [wallet.chainId]);

  if (cart.isLoading) return <FullScreenLoader />;
  if (cart.isError) return <div>Error: {cart.error?.message}</div>;

  return (
    <section className='min-h-screen w-full stacking-container text-white '>
      <div className='xl:container mx-auto pb-20 relative'>
        <section className='lg:h-[640px] md:h-[535px] sm:h-[750px] h-[600px] lg:w-[891px] md:w-[743px]  sm:w-[490px] w-[370px] relative top-10 left-1/2 transform translate-x-[-50%] z-50'>
          <UiCartSVGCard className='absolute md:block hidden lg:h-[640px] h-[535px] lg:w-[891px] w-[743px] top-0  z-[-999] scale-x-[1.005]' />
          <UICartSVGCardMobile className='absolute md:hidden sm:scale-y-90 scale-y-[.80] sm:scale-x-100 scale-x-75 block sm:-top-10 -top-20 left-1/2 translate-x-[-50%] h-[750px] w-[500px] z-[-999]' />
          <ul className='text-xl relative md:top-0 top-24 md:pt-12 pl-16 md:pb-6 py-5 flex justify-between items-center md:border-0 border-t border-[#ff3503]'>
            <li className='w-6/12'>Product</li>
            <li className='w-2/12'>Price</li>
            <li className='w-2/12'>QTY :</li>
            <li className='md:block hidden w-2/12'>Total</li>
          </ul>
          <div
            className={`flex flex-col lg:h-[380px] h-[300px] overflow-y-auto scrollbar-thumb-rounded-full  scrollbar-track-rounded-full scrollbar-track-transparent scrollbar-thumb-[#ff3503] scrollbar-thin md:border-t md:border-b border-[#ff3503]  py-3 relative md:top-0 top-24`}
          >
            {cart.data?.items && cart.data.items.length > 0 ? (
              cart.data.items.map((item) => {
                return (
                  <div
                    key={item.productId}
                    className='flex justify-between items-center border border-gray-300 rounded-lg p-4 shadow-md m-4 lg:text-2xl sm:text-xl text-xs'
                  >
                    <section className='flex w-6/12 items-center gap-x-5'>
                      <img
                        src={item.productImage}
                        alt=''
                        className='lg:w-[68px] w-12 lg:h-[62px] h-11'
                      />
                      <h1 className='font-light'>{item.productName}</h1>
                    </section>
                    <h1 className='w-2/12 flex justify-center items-center text-[#ff3503]'>
                      {item.productPrice} BLZ
                    </h1>
                    <div className='w-2/12 flex justify-center items-center'>
                      <button className='flex items-center px-5 py-[2px] gap-x-4 border border-[#ff3503] rounded-xl text-[#ff3503] lg:text-2xl text-base font-bold'>
                        <h1
                          onClick={() =>
                            handleQuantityChange(item.productId, -1)
                          }
                          className='cursor-pointer active:scale-110'
                        >
                          -
                        </h1>
                        {item.quantity}
                        <h1
                          onClick={() =>
                            handleQuantityChange(item.productId, 1)
                          }
                          className='cursor-pointer active:scale-110'
                        >
                          +
                        </h1>
                      </button>
                    </div>
                    <h1 className='w-2/12 flex justify-center items-center text-[#ff3503]'>
                      {item.productPrice * item.quantity}&nbsp;BLZ
                    </h1>
                  </div>
                );
              })
            ) : (
              <div className='text-center text-xl text-gray-500 py-10'>
                No items added to cart
              </div>
            )}
          </div>
          {/* Selected Chain */}
          <div className='relative md:top-0 top-24 flex flex-row justify-between items-center sm:px-16 pl-12 pr-5 pt-3'>
            <div className='flex flex-col'>
              <p className='sm:text-xl text-base'>Selected Chain</p>
              <div
                className='flex flex-row items-center gap-x-3 sm:text-base text-[14px]'
                // onClick={() => setChainSelect(1)}
              >
                <input
                  type='radio'
                  id='Binance'
                  name='selected-chain'
                  className={`cursor-pointer appearance-none h-3 w-3 rounded-full from-[#ff5501] to-[#ff3503] ${
                    true && "bg-gradient-to-b"
                  }`}
                />
                <label htmlFor='Binance' className='cursor-pointer'>
                  {chain?.name}
                </label>
              </div>
              {/* <div className="flex flex-row items-center gap-x-3 text-base">
                <input
                  type="radio"
                  id="Others"
                  name="selected-chain"
                  className={`cursor-pointer appearance-none h-3 w-3 rounded-full from-[#ff5501] to-[#ff3503] ${
                    '' === 2 && 'bg-gradient-to-b'
                  }`}
                />
                <label htmlFor="Others" className="cursor-pointer">
                  Others
                </label>
              </div> */}
            </div>
            <div className='flex flex-col items-center sm:text-xl text-base'>
              <p>Total Price</p>
              <p className={`text-[#ff3503] `}>{totalPrice} BLZ</p>
            </div>
          </div>
          <button
            disabled={isPurchasing}
            onClick={purchase}
            style={{ backdropFilter: "blur(28px)" }}
            className='active:scale-95  active:border-0 active:bg-gradient-to-b from-[#ff5501] to-[#ff3503]
                         transition duration-300 ease-linear relative left-1/2 lg:-top-2 md:-top-6 sm:top-32 top-[104px] transform translate-x-[-50%] border border-[#ff3503] 
                         rounded-full bg-[rgba(66,66,66,0.12)] lg:px-10 sm:px-7 px-5 sm:py-3 py-2  sm:text-base text-xs sm:font-bold'
          >
            {isPurchasing ? "Purchasing..." : "Purchase"}
          </button>
        </section>
      </div>
    </section>
  );
};

const UiCartSVGCard = (props) => {
  return (
    <svg
      width='890'
      height='642'
      viewBox='0 0 890 642'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M1 50.764L4.04678 640.823H838.72L889.5 584.966V1L49.7484 3.03118L1 50.764Z'
        fill='url(#paint0_linear_5740_103)'
        fillOpacity='0.22'
        stroke='#FF3503'
      />
      <defs>
        <linearGradient
          id='paint0_linear_5740_103'
          x1='205.25'
          y1='1'
          x2='205.25'
          y2='640.823'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#959595' />
          <stop offset='1' />
        </linearGradient>
      </defs>
    </svg>
  );
};

const ProductCardSVG = (props) => {
  return (
    <svg
      width='87'
      height='75'
      viewBox='0 0 87 75'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M1 7.57747L1.40541 74H79.2432L86 66.6171V1L7.48649 1.26847L1 7.57747Z'
        fill='url(#paint0_linear_5746_29)'
        fillOpacity='0.22'
        stroke='#FF3503'
      />
      <defs>
        <linearGradient
          id='paint0_linear_5746_29'
          x1='33.5'
          y1='1'
          x2='33.5'
          y2='85.5675'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#959595' />
          <stop offset='1' />
        </linearGradient>
      </defs>
    </svg>
  );
};

const UICartSVGCardMobile = (props) => {
  return (
    <svg
      className={props.className}
      width='497'
      height='755'
      viewBox='0 0 497 755'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_b_5982_78)'>
        <path
          d='M419.481 14.0108C408.843 6.7903 394.182 4.39281 389.291 4.75904C276.52 3.63773 46.5438 1.36285 28.8052 1.23387C11.0562 2.55127 0.797709 19.1284 0.617158 27.5649L0.54502 581.564C0.54358 592.599 3.60413 598.178 6.01035 602.43C6.61897 603.505 7.42917 604.455 8.4047 605.214L23.6196 617.045L40.656 629.974C40.7958 697.559 40.8191 699.743 40.6438 723.85C42.1887 747.487 59.2746 752.111 67.6244 751.469C188.028 752.143 434.415 753.654 456.727 754.298C479.04 754.942 493.662 739.92 495.504 732.5C496.516 473.5 495.681 92.0007 495.686 79.3632C495.691 65.8349 488.56 59.2215 485.472 57.5919C466.839 46.1055 422.427 16.0104 419.481 14.0108Z'
          fill='url(#paint0_linear_5982_78)'
          fill-opacity='0.08'
        />
        <path
          d='M419.481 14.0108C408.843 6.7903 394.182 4.39281 389.291 4.75904C276.52 3.63773 46.5438 1.36285 28.8052 1.23387C11.0562 2.55127 0.797709 19.1284 0.617158 27.5649L0.54502 581.564C0.54358 592.599 3.60413 598.178 6.01035 602.43C6.61897 603.505 7.42917 604.455 8.4047 605.214L23.6196 617.045L40.656 629.974C40.7958 697.559 40.8191 699.743 40.6438 723.85C42.1887 747.487 59.2746 752.111 67.6244 751.469C188.028 752.143 434.415 753.654 456.727 754.298C479.04 754.942 493.662 739.92 495.504 732.5C496.516 473.5 495.681 92.0007 495.686 79.3632C495.691 65.8349 488.56 59.2215 485.472 57.5919C466.839 46.1055 422.427 16.0104 419.481 14.0108Z'
          stroke='#FF3503'
        />
      </g>
      <defs>
        <filter
          id='filter0_b_5982_78'
          x='-10.1551'
          y='-9.46611'
          width='516.861'
          height='774.484'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='5.1' />
          <feComposite
            in2='SourceAlpha'
            operator='in'
            result='effect1_backgroundBlur_5982_78'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_backgroundBlur_5982_78'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_5982_78'
          x1='493.468'
          y1='449.74'
          x2='2.24906'
          y2='446.168'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#D9D9D9' />
          <stop offset='1' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Cart;
