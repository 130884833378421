import axios from "axios";

export const getLeaderboard = (params = {}) => {
  return axios.get("/api/user/leaderboard", {
    params,
  });
};

export const getQuestions = async (chainId) => {
  const response = await axios.get(`/api/questions?chainId=${chainId}`);

  return response.filter((question) => question).slice(0, 10);
};

export const getStakingInfo = () => {
  return axios.get("/api/nft/stakingInfo");
};

export const stakeNow = (tokenId) => {
  return axios.post(`/api/nft/${tokenId}/stakeNow`);
};

export const completeTransaction = (data) => {
  return axios.post("/api/game/done", data);
};
