import { useEffect, useMemo, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AiOutlineShoppingCart } from "react-icons/ai";
import {
  StoreFrontItemCard,
  StoreFrontLeftArrow,
  StoreFrontRightArrow,
} from "../components/SvgContainer";
import { addToCart, getCart, getProducts } from "../api/product";
import { useQuery } from "@tanstack/react-query";
import { useWallet } from "../hooks/useWallet";
import FullScreenLoader from "../components/Loader/FullScreenLoader";
import { Link } from "react-router-dom";

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  nextArrow: <StoreFrontRightArrow />,
  prevArrow: <StoreFrontLeftArrow />,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 580,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const chunkSize = settings.slidesToShow * 2;

const chunkProducts = (products, size) =>
  Array.from(
    { length: Math.min(2, Math.ceil(products.length / size)) },
    (v, i) => products.slice(i * size, i * size + size)
  );

const Store = () => {
  const wallet = useWallet();
  const [cartItemCount, setcartItemCount] = useState(0);

  const fetchCart = async (chainId) => {
    try {
      let cartData = await getCart({ chainId });
      cartData =  cartData.items.filter((item) => item.quantity >= 1),
      setcartItemCount(cartData.length);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchCart(wallet.chainId);
  }, [wallet]);

  useEffect(() => {
    fetchCart(wallet.chainId);
  }, []);

  const product = useQuery({
    queryKey: ["products", wallet.chainId],
    queryFn: () => getProducts({ chainId: wallet.chainId }),
    enabled: !!wallet.chainId,
  });

  const productChunks = useMemo(
    () => chunkProducts(product.data || [], chunkSize),
    [product.data]
  );

  if (product.isLoading) return <FullScreenLoader />;
  if (product.isError) return <div>Error loading products.</div>;

  return (
    <div className="section min-h-screen storefrontslider-container w-full text-white">
      <div className="container md:px-0 px-5 mx-auto pt-4 pb-5">
        <div className="flex justify-between w-full items-center px-10">
          <h1 className="sm:text-5xl text-3xl">Store Front</h1>
          <div className="relative">
            <Link
              to="/cart"
              className="w-16 h-10 flex justify-center items-center text-3xl rounded-full border border-[#ff3503] text-[#ff3503]"
            >
              <AiOutlineShoppingCart />
            </Link>
            {cartItemCount > 0 && (
              <span className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full w-6 h-6 flex justify-center items-center text-sm">
                {cartItemCount}
              </span>
            )}
          </div>
        </div>
        {productChunks.slice(0, 2).map((chunk, index) => (
          <div key={index} className="container w-full px-8 mt-8">
            <Slider {...settings}>
              {chunk.map((product) => (
                <div key={product._id} className="h-[320px]">
                  <div className="w-[280px] mx-auto relative">
                    <img
                      src={product.img}
                      alt={product.title}
                      className="absolute h-52 w-60 top-4 left-1/2 transform translate-x-[-50%]"
                    />
                    <div className="flex flex-col px-5 w-full absolute bottom-3">
                      <h1 className="text-base">{product.title}</h1>
                      <p className="text-[13px] text-[#ff3503]">{`${product.price} BLZP`}</p>
                      <button
                       onClick={async () => {
                        await addToCart({
                          productId: product._id,
                          chainId: wallet.chainId,
                          quantity: 1,
                          showToast: true,
                        });
                        fetchCart(wallet.chainId);
                      }}
                        className="absolute bg-black text-2xl rounded-[6px] px-2 outline-none border border-[#ff3503] bottom-5 right-8"
                      >
                        +
                      </button>
                    </div>
                    <StoreFrontItemCard className="w-full md:px-0 px-2" />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        ))}
        <div className="flex justify-end w-full">
          <Link to="/cart">
            <button className="bg-gradient-to-r from-[#ff3503] to-[#fba500] rounded-full lg:h-12 h-9 lg:px-16 px-12">
              Proceed To Purchase
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Store;
