import slidingPuzzle1 from "./slidingPuzzle1.png";
import p11 from "./p1 (1).png";
import p12 from "./p1 (2).png";
import p13 from "./p1 (3).png";
import p14 from "./p1 (4).png";
import p15 from "./p1 (5).png";
import p16 from "./p1 (6).png";
import p17 from "./p1 (7).png";
import p18 from "./p1 (8).png";
import p19 from "./p1 (9).png";

import slidingPuzzle2 from "./slidingPuzzle2.png";
import p21 from "./p2 (1).png";
import p22 from "./p2 (2).png";
import p23 from "./p2 (3).png";
import p24 from "./p2 (4).png";
import p25 from "./p2 (5).png";
import p26 from "./p2 (6).png";
import p27 from "./p2 (7).png";
import p28 from "./p2 (8).png";
import p29 from "./p2 (9).png";

import slidingPuzzle3 from "./slidingPuzzle3.png";
import p31 from "./p3 (1).png";
import p32 from "./p3 (2).png";
import p33 from "./p3 (3).png";
import p34 from "./p3 (4).png";
import p35 from "./p3 (5).png";
import p36 from "./p3 (6).png";
import p37 from "./p3 (7).png";
import p38 from "./p3 (8).png";
import p39 from "./p3 (9).png";

import slidingPuzzle4 from "./slidingPuzzle4.png";
import p41 from "./p4 (1).png";
import p42 from "./p4 (2).png";
import p43 from "./p4 (3).png";
import p44 from "./p4 (4).png";
import p45 from "./p4 (5).png";
import p46 from "./p4 (6).png";
import p47 from "./p4 (7).png";
import p48 from "./p4 (8).png";
import p49 from "./p4 (9).png";

import slidingPuzzle5 from "./slidingPuzzle5.png";
import p51 from "./p5 (1).png";
import p52 from "./p5 (2).png";
import p53 from "./p5 (3).png";
import p54 from "./p5 (4).png";
import p55 from "./p5 (5).png";
import p56 from "./p5 (6).png";
import p57 from "./p5 (7).png";
import p58 from "./p5 (8).png";
import p59 from "./p5 (9).png";

export default {
  puzzle1: [slidingPuzzle1, p11, p12, p13, p14, p15, p16, p17, p18, p19],
  puzzle2: [slidingPuzzle2, p21, p22, p23, p24, p25, p26, p27, p28, p29],
  puzzle3: [slidingPuzzle3, p31, p32, p33, p34, p35, p36, p37, p38, p39],
  puzzle4: [slidingPuzzle4, p41, p42, p43, p44, p45, p46, p47, p48, p49],
  puzzle5: [slidingPuzzle5, p51, p52, p53, p54, p55, p56, p57, p58, p59],
};
