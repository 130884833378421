import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from '@web3modal/ethers5/react';
import { createContext, useCallback, useMemo } from 'react';
import { providers } from 'ethers';
import propTypes from 'prop-types';
import { chains } from '../services/chains';
import { chainIdToHex, handleTransactionError } from '../services/util';
import { toast } from 'react-toastify';
import { completeTransaction } from '../api/misc';

export const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const { open } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const provider = useMemo(() => {
    if (!walletProvider) return null;
    return new providers.Web3Provider(walletProvider, 'any');
  }, [walletProvider]);

  const getChainConfig = useCallback(() => {
    const chain = chains.find((item) => item.id == chainId);
    if (!chain) {
      open({ view: 'Networks' });
      return null;
    }

    return chain;
  }, [chainId, open]);

  const switchChain = useCallback(
    async (id) => {
      if (!provider) return open();

      const chain = chains.find((item) => item.id == id);
      if (!chain) {
        throw new Error(`Invalid chain id '${id}`);
      }

      try {
        await provider.send('wallet_switchEthereumChain', [
          { chainId: chainIdToHex(+id) },
        ]);

        return id;
      } catch (switchError) {
        try {
          var decodedError = JSON.parse(switchError.message);
        } catch (err) {
          // ignore
        }
        if (
          switchError.code === 4902 ||
          decodedError?.data?.originalError?.code === 4902
        ) {
          try {
            await provider.send('wallet_addEthereumChain', [
              {
                chainId: chainIdToHex(+id),
                chainName: chain.name,
                rpcUrls: chain.rpcUrls,
                blockExplorerUrls: chain.blockExplorerUrls || null,
                nativeCurrency: chain.nativeCurrency,
              },
            ]);
            return id;
          } catch (addError) {
            console.error(addError);
            handleTransactionError(addError);
          }
        } else {
          console.error(switchError);
          toast.info('Error occured while switching chain');
        }
      }
    },
    [provider, open]
  );

  const safeCallContract = useCallback(
    async ({ name, method, args, chainId: reqChainId, showToast = true }) => {
      if (!provider || !isConnected) {
        open({ view: 'Connect' });
        toast.info('Please connect wallet');
        return [true, null];
      }

      const chain = chains.find((item) => item.id == chainId);
      if (!chain) {
        open({ view: 'Networks' });
        return [true, null];
      }

      const contractInstance = chain.contracts[name];
      if (!contractInstance) throw new Error(`Invalid contract name '${name}'`);

      const signer = provider.getSigner();
      const contract = contractInstance.connect(signer);

      if (reqChainId && reqChainId !== chainId) {
        const success = await switchChain(reqChainId);
        if (!success) return [true, null];
      }

      if (!method) return contract;

      try {
        const tx = await contract[method](...args);

        return [null, tx];
      } catch (err) {
        if (showToast) {
          handleTransactionError(err);
        }

        return [err, null];
      }
    },
    [isConnected, chainId, provider, open, switchChain]
  );

  const safeSendTransaction = useCallback(() => {}, []);

  const updatePoints = useCallback(async (data) => {
    return completeTransaction(data).catch(console.error);
  }, []);

  const value = useMemo(
    () => ({
      address,
      chainId,
      isConnected,
      provider,

      switchChain,
      updatePoints,
      getChainConfig,
      safeCallContract,
      safeSendTransaction,
    }),
    [
      address,
      chainId,
      isConnected,
      provider,
      switchChain,
      updatePoints,
      getChainConfig,
      safeCallContract,
      safeSendTransaction,
    ]
  );

  return (
    <WalletContext.Provider value={value}>{children}</WalletContext.Provider>
  );
};

WalletProvider.propTypes = {
  children: propTypes.node,
};
